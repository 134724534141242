/* *POPPINS* */
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  transition: all 0.17s ease !important;
  outline-color: #ebeff2;
}

code {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

html[lang="pt-BR"] {
  margin-top: 40px;
  overflow: hidden;
}
html {
  /* COLORS STYLES */

  --roxo-myne: #aa00ff;
  --roxo-myne-2: #faefff;
  --antd-wave-shadow-color: #aa00ff !important;
  --roxo-myne-36: rgba(170, 0, 255, 0.36);
  --azul-myne: #85d1f5;
  --border-myne: #ebeff2;

  --preto-87: rgba(0, 0, 0, 0.87);
  --preto-36: rgba(0, 0, 0, 0.36);
  --branco-87: rgba(255, 255, 255, 0.87);
  --preto-6: rgba(0, 0, 0, 0.06);
  --preto-60: rgba(0, 0, 0, 0.6);

  --red-myne: #fe2543;

  /* EFFECT STYLES */

  --shadow-button: 0px 4px 8px rgba(0, 0, 0, 0.12);
  --shadow-web: 0 0 24px 0 rgba(0, 0, 0, 0.12);

  /* BACKGROUNDS */

  --background: #fcfcfc;
  --background-gray-2: #fafafa;
  --background-gray: #f5f5f5;
  --background-second: rgba(0, 0, 0, 0.6);
  --background_hover: rgb(249, 249, 252);
}

/* * {
  cursor: url('../assets/icons/Pointer.svg'), pointer !important;
} */

strong {
  color: var(--roxo-myne);
}

#google_translate_element {
  position: fixed;
  bottom: -17px;
  right: 5px;
  z-index: 999;
}

#google_translate_element select {
  width: 200px;
  display: flex;
  align-items: center;
  border: none;
  outline: initial;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
  padding: 0px 10px;
  height: 35px;
  border-radius: 10px;
  background-color: #f1f1f1;
}

#google_translate_element select option {
  min-height: 50px;
  padding: 20px 0;
}

.goog-te-banner-frame {
  display: none;
}

#root {
  position: relative;
  margin-top: -40px;
}

/* switch */

.switch {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 11px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch > p {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.87);
  margin-right: 8px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.38);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  background: #fff;
  z-index: 1;
  bottom: -2px;
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.141));
  filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.122));
  -webkit-transition: 0.4s;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2));
  transition: 0.4s;
  -webkit-filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2));
}

.switch input:checked + .slider {
  background: #dcffc2;
}

.switch input:checked + .slider:before {
  background: #58cc02;
}

.switch input:focus + .slider {
  box-shadow: 0 0 1px #dcffc2;
}

/* color-primary */
.switch-primary input:checked + .slider {
  background: var(--roxo-myne-36);
}

.switch-primary input:checked + .slider:before {
  background: var(--roxo-myne);
}

.switch-primary input:focus + .slider {
  box-shadow: 0 0 1px var(--roxo-myne-36);
}

.switch input:checked + .slider:before {
  -webkit-transform: translateX(10px);
  -ms-transform: translateX(10px);
  transform: translateX(10px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.myne-w100fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.myne-w100fixed > div {
  width: 100%;
  height: 100%;
}

.myne-lock-plus {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.myne-lock-plus > article {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 4px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  gap: 4px;
}

.myne-lock-plus > article > p {
  font-size: 12px;
  font-weight: 700;
}