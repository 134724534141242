.myne-modal__modal-edit-cadastro-categorie-content {
  padding: 24px;
  padding-bottom: 28px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.myne-modal__modal-edit-cadastro-categorie-content
  .myne-modal__modal-new-sale-content__area-modal-search__itens-user {
  width: fit-content;
}

.myne-modal__modal-edit-cadastro-categorie-content-categories {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  height: 100%;
}

.myne-modal__modal-edit-cadastro-categorie-content-categories-without {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
