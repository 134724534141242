@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
/* *POPPINS* */

* {
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  transition: all 0.17s ease !important;
  outline-color: #ebeff2;
}

code {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

html[lang="pt-BR"] {
  margin-top: 40px;
  overflow: hidden;
}
html {
  /* COLORS STYLES */

  --roxo-myne: #aa00ff;
  --roxo-myne-2: #faefff;
  --antd-wave-shadow-color: #aa00ff !important;
  --roxo-myne-36: rgba(170, 0, 255, 0.36);
  --azul-myne: #85d1f5;
  --border-myne: #ebeff2;

  --preto-87: rgba(0, 0, 0, 0.87);
  --preto-36: rgba(0, 0, 0, 0.36);
  --branco-87: rgba(255, 255, 255, 0.87);
  --preto-6: rgba(0, 0, 0, 0.06);
  --preto-60: rgba(0, 0, 0, 0.6);

  --red-myne: #fe2543;

  /* EFFECT STYLES */

  --shadow-button: 0px 4px 8px rgba(0, 0, 0, 0.12);
  --shadow-web: 0 0 24px 0 rgba(0, 0, 0, 0.12);

  /* BACKGROUNDS */

  --background: #fcfcfc;
  --background-gray-2: #fafafa;
  --background-gray: #f5f5f5;
  --background-second: rgba(0, 0, 0, 0.6);
  --background_hover: rgb(249, 249, 252);
}

/* * {
  cursor: url('../assets/icons/Pointer.svg'), pointer !important;
} */

strong {
  color: #aa00ff;
  color: var(--roxo-myne);
}

#google_translate_element {
  position: fixed;
  bottom: -17px;
  right: 5px;
  z-index: 999;
}

#google_translate_element select {
  width: 200px;
  display: flex;
  align-items: center;
  border: none;
  outline: initial;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
  padding: 0px 10px;
  height: 35px;
  border-radius: 10px;
  background-color: #f1f1f1;
}

#google_translate_element select option {
  min-height: 50px;
  padding: 20px 0;
}

.goog-te-banner-frame {
  display: none;
}

#root {
  position: relative;
  margin-top: -40px;
}

/* switch */

.switch {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 11px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch > p {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.87);
  margin-right: 8px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.38);
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  background: #fff;
  z-index: 1;
  bottom: -2px;
  -webkit-filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.141));
          filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.141));
  -webkit-filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.122));
          filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.122));
  -webkit-transition: 0.4s;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2));
  transition: 0.4s;
  -webkit-filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2));
}

.switch input:checked + .slider {
  background: #dcffc2;
}

.switch input:checked + .slider:before {
  background: #58cc02;
}

.switch input:focus + .slider {
  box-shadow: 0 0 1px #dcffc2;
}

/* color-primary */
.switch-primary input:checked + .slider {
  background: rgba(170, 0, 255, 0.36);
  background: var(--roxo-myne-36);
}

.switch-primary input:checked + .slider:before {
  background: #aa00ff;
  background: var(--roxo-myne);
}

.switch-primary input:focus + .slider {
  box-shadow: 0 0 1px rgba(170, 0, 255, 0.36);
  box-shadow: 0 0 1px var(--roxo-myne-36);
}

.switch input:checked + .slider:before {
  -webkit-transform: translateX(10px);
  transform: translateX(10px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.myne-w100fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.myne-w100fixed > div {
  width: 100%;
  height: 100%;
}

.myne-lock-plus {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.myne-lock-plus > article {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 4px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  grid-gap: 4px;
  gap: 4px;
}

.myne-lock-plus > article > p {
  font-size: 12px;
  font-weight: 700;
}
.myne-sidebar {
  width: 255px;
  max-width: 255px;
  min-width: 255px;
  height: 100%;
  z-index: 1;
  background: #ffffff;
  display: flex;
  overflow: hidden;
  justify-content: space-around;
  flex-direction: column;
  transition: all 0.3s ease;
  box-shadow: 0 0 30px 10px rgba(133, 131, 131, 0.103);
}

/* .sidebar__overflow {
  display: flex;
  overflow-y: scroll;
  flex-direction: column;
  width: 100%;
}

.sidebar__overflow::-webkit-scrollbar {
  width: 9px;
}
.sidebar__overflow::-webkit-scrollbar-track {
  border-radius: 0px;
  background-color: rgb(255, 255, 255);
}
.sidebar__overflow::-webkit-scrollbar-thumb {
  background: var(--border-myne);
} */

.sidebar__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 62px;
  height: 240px;
  max-height: 240px;
  min-height: 240px;
  position: relative;
}

.sidebar__header-logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar__header-logo > img {
  cursor: pointer;
}

.myne-sidebar-icon-menu-open {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.myne-sidebar-icon-menu-open > img {
  width: 20px;
  height: 20px;
  position: fixed;
  top: 19.5px;
  transition: all 0.3s ease;
}

.myne-sidebar-icon-menu-open > img:hover {
  opacity: 0.7;
}

/**/
.sidebar__buttons {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.sidebar__bottom {
  background-color: rgb(255, 255, 255);
  height: 85px;
  max-height: 85px;
  min-height: 85px;
  display: flex;
  align-items: center;
  padding: 0 30px;
  justify-content: space-between;
}

.sidebar__bottom > p {
  color: #000000;
  font-size: 10px;
}

/* SUGESTOES */

.sidebar__buttons {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: scroll;
  height: 100%;

}
.sidebar__buttons::-webkit-scrollbar {
  width: 6px;
  border-radius: 5px;
}
.sidebar__buttons::-webkit-scrollbar-track {
  border-radius: 0px;
  background-color: rgb(255, 255, 255);
}
.sidebar__buttons::-webkit-scrollbar-thumb {
  background: linear-gradient(var(--roxo-myne), var(--roxo-myne));
}

/* .myne-sidebar {
  width: 280px;
  height: 100%;
  background: #ffffff;
  display: flex;
  overflow-y: scroll;
  flex-direction: column;
} 
 .myne-sidebar::-webkit-scrollbar {
  width: 9px;
}
.myne-sidebar::-webkit-scrollbar-track {
  border-radius: 0px;
  background-color: rgb(255, 255, 255);
}
.myne-sidebar::-webkit-scrollbar-thumb {
  background: var(--border-myne);
} */

.myne-btn {
  width: 100%;
  height: 45px;
  max-height: 45px;
  min-height: 45px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  outline: initial;
  border-radius: 10px;
  box-shadow: var(--shadow-button);
  border-radius: 50px;
  transition: all 0.1s ease;
}

.myne-btn:active,
.header__center-btn:active,
.myne-btn__seje-plus:active {
  -webkit-transform: scale(0.98);
          transform: scale(0.98);
  box-shadow: 3px 2px 25px 0px rgba(51, 51, 51, 0.123);
}

.btn-entrar {
  background-color: var(--roxo-myne);
  color: #fff;
  font-size: 15px;
  transition: opacity 0.3s ease;
}

.btn-entrar:hover {
  opacity: 0.7;
}

.btn-google {
  color: rgba(0, 0, 0, 0.87);
  position: relative;
  background-color: #fff;
  font-weight: 500;
  margin-top: 18px;
  font-size: 15px;
}

.btn-google > svg {
  position: absolute;
  font-size: 25px;
  left: 10px;
}

/**/

.myne-button__sidebar {
  flex: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 0;
  cursor: pointer;
  justify-content: center;
  text-decoration: none;
  height: 157px;
  transition: all 0.2s ease;
}

.myne-button__sidebar:hover {
  background: var(--background_hover);
}

.myne-button__sidebar > svg path {
  fill: var(--background-second);
  transition: all 0.2s ease;
  opacity: 1 !important;
}

.myne-button__sidebar > p {
  color: var(--background-second);
  font-size: 14px;
  margin-top: 14px;
  font-weight: 500;
  margin-bottom: 0;
  transition: all 0.2s ease;
}

.myne-button__sidebar-active > svg > path {
  fill: var(--roxo-myne);
  opacity: 5;
  transition: all 0.2s ease;
}

.myne-button__sidebar-active > p {
  color: rgba(0, 0, 0, 0.87);
  transition: all 0.2s ease;
}

/**/

.myne-btn-logout {
  display: flex;
  align-items: center;
  border: none;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0);
  outline: initial;
}

.myne-btn-logout > svg {
  width: 22px;
}

.myne-btn-logout > p {
  margin-left: 15px;
  color: var(--preto-60);
  font-weight: 500;
  font-size: 14px;
}

/**/

.myne-btn__seje-plus {
  /* margin-right: 20px; */
  border-radius: 50px;
  height: 42px;
  width: 170px;
  background-color: #58cc02;
  border: none;
  outline: initial;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  cursor: pointer;
}

.dashboard-content__grid-vendas__button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 20px 16px 16.5px;
  position: absolute;
  bottom: 24px;
  right: 20px;
  /* width: 178px; */
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: #fff;
  height: 48px;
  background: var(--roxo-myne);
  cursor: pointer;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 100px;
  transition: all 0.2s ease;
  z-index: 2;
}

.dashboard-content__grid-vendas__button:hover {
  opacity: 0.8;
}

.myne-btn__seje-plus:hover {
  opacity: 0.8;
}

.myne-btn__seje-plus > svg {
  position: absolute;
  left: 20px;
}

.myne-btn__seje-plus > p {
  font-size: 15.2px;
  color: #fff;
  font-weight: 600;
  margin-left: 30px;
}

.header {
  background-color: #fff;
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0 30px;
  /* justify-content: space-between; */
}

.header__left {
  display: flex;
  align-items: center;
}

.header__left > p {
  font-weight: 500;
  font-size: 15.5px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.87);
}

/**/

.header__center {
  margin-right: 0px;
  /* margin-left: 130px; */
  display: flex;
  z-index: 999;
  position: relative;
  align-items: center;
}

.header__center > div {
  position: absolute;
  top: 55px;
  width: 330px;
  left: 0px;
  height: auto;
  box-shadow: var(--shadow-web);
  background-color: #fff;
  border-radius: 8px;
  z-index: 999999999;
  padding: 12px;

  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(0);
          transform: scale(0);
}

.header__center > div > p {
  margin: 8px 6px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.87);
}

.header .ant-picker {
  padding: 11px 14px;
  background-color: rgb(248, 250, 252);
  margin: 0px;
  color: rgb(37, 37, 37);
  border: 0;
  border-radius: 12px;
}

.header .ant-picker input {
  color: rgb(37, 37, 37);
}

.header .nice-dates-day:before {
  background-color: var(--roxo-myne);
  z-index: 1;
}

.header .nice-dates-day:after {
  border: 2px solid var(--roxo-myne);
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
  transition-duration: 150ms;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  z-index: 2;
}

.header .nice-dates-week-header_day {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  margin-left: 0.5px;
  height: 18px;
  text-overflow: ellipsis;
}

.header__center-btn_filter {
  width: 72px;
  height: 43px;
  margin-top: 15px;
  border-radius: 5px 5px 0 0;
  color: rgba(0, 0, 0, 0.6);
  outline: initial;
  cursor: pointer;
  text-transform: uppercase;
  background: rgba(255, 255, 255, 0);
  border: none;
  font-size: 15px;
  font-weight: 500;
  transition: all 0.3s ease;
  border-radius: 5px 5px 0 0;
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  -webkit-border-radius: 5px 5px 0 0;
  -moz-border-radius: 5px 5px 0 0;
  -ms-border-radius: 5px 5px 0 0;
  -o-border-radius: 5px 5px 0 0;
}

.header__center-btn_filter:hover {
  background: var(--roxo-myne-2);
}

.header__center-btn {
  width: 82px;
  max-width: 82px;
  min-width: 82px;
  margin-left: 16px;
  height: 35px;
  width: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  font-size: 14.7px;
  border-radius: 35px;
  outline: initial;
  cursor: pointer;
  background: rgba(255, 255, 255, 0);
  border: none;
  border: 1px solid rgba(0, 0, 0, 0.06);
}

.header__center-btn:hover {
  border: 1px solid rgba(0, 0, 0, 0.171);
  background: rgb(252, 251, 253);
}

/**/

.header__right {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.header__right .MuiIconButton-root {
  margin-right: -12px;
  width: 42px;
  height: 42px;
}

/**/

@media (max-width: 952px) {
  .header {
    padding: 0 10px;
  }
  .header__center {
    margin-right: 0px;
    margin-left: 20px;
  }
  .header__left > p {
    font-size: 13px;
  }
  .header__center-btn {
    width: 67px;
    max-width: 67px;
    min-width: 67px;
    height: 32px;
    width: 67px;
    font-size: 13.5px;
    border-radius: 25px;
  }
  .myne-btn__seje-plus {
    height: 40px;
    width: 140px;
  }
  .myne-btn__seje-plus > p {
    font-size: 13px;
    margin-left: 30px;
  }
}

.myne-item__cadastro {
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  height: 90px;
  max-height: 90px;
  min-height: 90px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  padding: 20px 16px;
  margin-bottom: 12px;
  /* overflow-y: hidden;
  overflow-x: auto; */
}

.myne-item__cadastro:hover {
  background-color: rgb(250, 250, 250);
  /* background-color: rgba(250, 249, 249, 0.87); */
  border: 1px solid rgba(0, 0, 0, 0.36);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
}

.myne-item__cadastro-left {
  display: flex;
  align-items: center;
}

.myne-item__cadastro-left > img {
  width: 40px;
  height: 40px;
  max-width: 40px;
  max-height: 40px;
  min-width: 40px;
  min-height: 40px;
  margin-right: 16px;
  object-fit: cover;
  border-radius: 5px;
}

.myne-item__cadastro-left > section {
  display: flex;
  padding: 0;
  margin: 0;
  flex-direction: column;
}

.myne-item__cadastro-left > section > p {
  margin: 0;
  font-weight: 600;
  font-size: 15px;
  opacity: 0.87;
  color: rgba(0, 0, 0, 0.87);
}

.myne-item__cadastro-left > section > h5 {
  margin: 0;
  margin-top: 2px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 13px;
  opacity: 0.87;
  font-weight: 400;
  white-space: nowrap;
  order: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 500px;
}

.myne-item__cadastro-right {
  display: flex;
  align-items: center;
}

.myne-item__cadastro-right > section {
  display: flex;
  flex-direction: column;
  margin-left: 32px;
}

.myne-item__cadastro-right > section > p {
  margin: 0;
  font-size: 11px;
  color: rgba(0, 0, 0, 0.6);
  opacity: 0.87;
  white-space: nowrap;
  text-align: right;
}

.myne-item__cadastro-right > section > h5 {
  margin: 0;
  margin-top: 4px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 15px;
  font-weight: 600;
  opacity: 0.87;
  text-align: right;
  white-space: nowrap;
}

/* MODAL EDIT */

.myne-modal__modal-edit-cadastro {
  position: absolute;
  width: 611px;
  height: 479px;
  /* height: 547px; */
  /* height: 600px; */
  background: #ffffff;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  display: flex;
  transition: all 0.3s ease;
  flex-direction: column;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.myne-modal__modal-edit-header {
  width: 100%;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ebeff2;
}

.myne-modal__modal-edit-header__left {
  display: flex;
  align-items: center;
}

.myne-modal__modal-edit-header__left-icon-primary path {
  fill: var(--roxo-myne);
  fill-opacity: 1;
}

.myne-modal__modal-edit-header__left > h5 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  margin: auto 0;
  margin-left: 4px;
  color: rgba(0, 0, 0, 0.87);
}

.myne-modal__modal-edit-header__left > svg {
  margin-right: 15px;
}

.myne-modal__modal-edit-header__left > p {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.87);
  text-transform: uppercase;
  margin: 0;
}

.myne-modal__modal-edit-header__icons,
.myne-modal__modal-edit-header__icons .icon-area {
  display: flex;
  align-items: center;
}

.myne-modal__modal-edit-header__icons .myne-modal__modal-sucess-publicate_copy {
  width: -webkit-max-content !important;
  width: max-content !important;
}

.myne-modal__modal-edit-header__icons > svg,
.myne-modal__modal-edit-header__icons .icon-area > svg {
  margin-left: 24px;
  width: 24px;
  /* padding: 1px; */
  cursor: pointer;
  height: 24px;
  transition: all 0.3s ease;
}

.myne-modal__modal-edit-header__icons .icon-area > svg {
  margin-left: 0 !important;
}

.myne-modal__modal-edit-header__icons > svg path,
.myne-modal__modal-edit-header__icons .icon-area > svg path {
  fill: rgba(0, 0, 0, 0.6);
  fill-opacity: 1 !important;
  opacity: 1 !important;
}

.myne-modal__modal-edit-header__icons > svg:hover,
.myne-modal__modal-edit-header__icons .icon-area:hover {
  opacity: 0.7;
}

.myne-modal__modal-edit-cadastro-content {
  display: flex;
  flex: 1 1;
  height: 1px;
}

.myne-modal__modal-edit-cadastro-content__inputs {
  display: flex;
  flex-direction: column;
  padding: 24px;
  overflow-y: auto;
  overflow-x: hidden;
  flex: 0.6 1;
  height: 100%;
  -webkit-animation: initop 0.5s ease;
          animation: initop 0.5s ease;
}

.myne-modal__modal-edit-cadastro-content__inputs > article {
  margin-bottom: 14px;
}

/* PHOTO */

.myne-modal__modal-edit-cadastro-content__right-subimit {
  display: flex;
  flex-direction: column;
  padding: 24px;
  flex: 0.4 1;
  border-left: 1px solid #ebeff2;
  -webkit-animation: initop 0.7s ease;
          animation: initop 0.7s ease;
}

.myne-modal__modal-edit-cadastro-content__right-subimit__photos {
  display: flex;
  flex-direction: column;
  grid-gap: 6px;
  gap: 6px;
}

.myne-modal__modal-edit-cadastro-content__right-subimit__photos > p {
  margin-bottom: 6px;
  font-size: 14px;
}

.myne-modal__modal-edit-cadastro-content__right-subimit__photo {
  flex: 1 1;
  cursor: pointer;
  object-fit: cover;
  height: 96px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.06);
  border: 1px dashed rgba(0, 0, 0, 0.06);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.myne-modal__modal-edit-cadastro-content__right-subimit__photo > svg {
  position: absolute;
}

.myne-modal__modal-edit-cadastro-content__right-subimit__photo > img {
  width: 100%;
  object-fit: cover;
  height: 100%;
  border-radius: 8px;
}

.myne-modal__modal-edit-cadastro-content__right-subimit__photos > div {
  display: flex;
  align-items: center;
  grid-gap: 6px;
  gap: 6px;
}

.myne-modal-more-photos {
  top: initial;
  right: 0;
  left: initial;
  bottom: 65px;
  width: 264px !important;
  height: 317px !important;
  padding: 24px;
  grid-gap: 8px;
  gap: 8px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.myne-modal-more-photos > b {
  font-size: 16px;
  font-weight: 700;
}
.myne-modal-more-photos > p {
  font-size: 16px;
  font-size: 14px;
  line-height: 21px;
}
.myne-modal-more-photos > button {
  margin-top: 24px;
  margin-left: auto;
  width: 132px;
  font-size: 14px;
  height: 36px;
  min-height: 36px;
  max-height: 36px;
  padding: 6px 24px 6px 24px;
  color: #fff;
  background-color: var(--roxo-myne);
}

/* END PHOTO */

.myne-modal__modal-edit-cadastro-content__right-subimit__stock-min {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
}

.myne-modal__modal-edit-cadastro-content__right-subimit__stock-min > p {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 700;
  margin: 0;
  margin-bottom: 10px;
}

.myne-modal__modal-edit-cadastro-content__right-subimit__stock-min > div {
  display: flex;
  flex-wrap: wrap;
}

.myne-modal__modal-edit-cadastro-content__right-subimit__stock-min
  > div
  > button {
  border: hidden;
  outline: initial;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 12px;
  min-width: 34px;
  font-size: 14px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 54px;
  /* margin: 4px; */
  margin-right: 7px;
  margin-bottom: 7px;
  cursor: pointer;
}

.myne-modal__modal-edit-cadastro-content__right-subimit__stock-min
  > div
  > button:hover {
  border: 1px solid rgba(0, 0, 0, 0.205);
}

.myne-modal__modal-edit-cadastro-content__right-subimit__photo {
  display: flex;
  flex-direction: column;
}

.myne-modal__modal-edit-cadastro-content__right-subimit__photo > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.myne-modal__modal-edit-cadastro-content__right-subimit__photo > div > p {
  color: rgba(0, 0, 0, 0.6);
  font-size: 13px;
  margin: 0;
}

.myne-modal__modal-edit-cadastro-content__right-subimit__photo > div > svg {
  width: 24px;
  height: 24px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.myne-modal__modal-edit-cadastro-content__right-subimit__photo
  > div
  > svg:hover {
  opacity: 0.6;
}

.myne-modal__modal-edit-cadastro-content__right-subimit__photo
  > div
  > svg
  path {
  fill: rgba(0, 0, 0, 0.6);
  fill-opacity: 1 !important;
}

.myne-modal__modal-edit-cadastro-content__right-subimit > button {
  border: none;
  outline: initial;
  display: flex;
  position: absolute;
  bottom: 24px;
  right: 24px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 13px 18px 13px 18px;
  cursor: pointer;
  background: var(--roxo-myne);
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 100px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  transition: all 0.3s ease;
}

.myne-modal__modal-edit-cadastro-content__right-subimit > button:disabled {
  background: rgba(0, 0, 0, 0.06) !important;
  box-shadow: none !important;
}

.myne-modal__modal-edit-cadastro-content__right-subimit > button:disabled > p {
  color: rgba(0, 0, 0, 0.36) !important;
}

.myne-modal__modal-edit-cadastro-content__right-subimit > button:active,
.myne-modal-more-photos > button:active {
  -webkit-transform: scale(0.97);
          transform: scale(0.97);
}

.myne-modal__modal-edit-cadastro-content__right-subimit > button:hover,
.myne-modal-more-photos > button:hover {
  opacity: 0.8;
}

.myne-modal__modal-edit-cadastro-content__right-subimit > button > p {
  color: #ffffff;
  font-size: 13.5px;
  font-weight: 400;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 1.25px;
}

.myne-modal__modal-edit-cadastro-content__right-subimit > button > svg {
  width: 16px;
  height: 16px;
  margin-right: 14px;
}

.myne-modal__modal-edit-cadastro-content__right-subimit > button > svg path {
  fill: #fff;
}

/* MESSAGE */

.myne-modal_message {
  display: flex;
  align-items: center;
  padding: 16px;
  position: fixed;
  white-space: nowrap;
  height: 56px;
  cursor: auto;
  top: 70px;
  transition: all 0.5s ease;
  right: 21px;
  z-index: 999;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  font-weight: 600;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.87;
  z-index: 9999999;
}

.myne-modal_message > svg {
  margin-right: 10px;
  width: 24px;
  height: 24px;
  max-width: 24px;
  max-height: 24px;
  min-width: 24px;
  min-height: 24px;
}

.myne-modal_message-sucess {
  background: #dcffc2;
}

.myne-modal_message-erro {
  background: #ffdbe0;
}

/* myne-modal__modal-edit-cadastro-content__select */

.myne-modal__modal-edit-cadastro-content__select-p {
  font-family: "Poppins";
  font-weight: 700;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
}

.myne-modal__modal-edit-cadastro-content__select {
  display: flex;
  align-items: center;
  margin-bottom: 22px;
}

.myne-modal__modal-edit-cadastro-content__select > button {
  padding: 4px 10px;
  margin-right: 9px;
  transition: all 0.2s ease;
  border-radius: 35px;
  display: flex;
  align-items: center;
  z-index: 1;
  outline: initial;
  cursor: pointer;
  background: rgb(255, 255, 255, 0);
  border: none;
  -webkit-transform: scale(1);
          transform: scale(1);
  font-size: 13px;
  white-space: nowrap;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid rgba(0, 0, 0, 0.06);
}

.myne-modal__modal-edit-cadastro-content__select > button:hover {
  border: 1px solid rgba(0, 0, 0, 0.219);
}

.myne-modal__modal-edit-cadastro-content__select > button > svg {
  margin-right: 10px;
  color: black;
  opacity: 0.87;
}

.myne-modal__modal-edit-cadastro-content__select > button > svg path {
  fill: rgba(0, 0, 0, 0.87);
}

/*  */

.myne-area-add-products {
  /* position: relative; */
  display: flex;
  flex-direction: column;
}

.myne-modal-add-products {
  position: absolute;
  right: 20px;
  transition: all 0.3s ease !important;
  bottom: 80px;
  display: flex;
  flex-direction: column;
  z-index: 999;
  background: #ffffff;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  width: 319px;
  height: 78px;
  padding: 10px 16px 10px 16px;
}

.myne-modal-add-products > button {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 24px;
  padding: 0;
  border: 0;
  background-color: #ffffff;
  border-radius: 8px;
  cursor: pointer;
  margin-left: -5px;
  padding-left: 5px;
  border: 1px solid #ffffff;
}
.myne-modal-add-products > button:hover {
  background-color: var(--background_hover);
  border: 1px solid var(--border-myne);
}

.myne-modal-add-products > button:nth-child(1) {
  margin-bottom: 10px;
}

.myne-modal-add-products > button > p {
  height: 21px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.87);
  margin: 0;
  margin-left: 8px;
}

/* export btn */

.myne-area-add-products_export_btn {
  position: absolute;
  bottom: 24px;
  right: 225px;
  z-index: 2;
  display: flex;
  height: 44px;
  padding: 10px 32px;
  justify-content: center;
  align-items: center;
  border-radius: 124px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  background: rgba(255, 255, 255, 0.87);
  cursor: pointer;
}

.myne-area-add-products_export_btn:active {
  -webkit-transform: scale(0.98);
          transform: scale(0.98);
  border: 1px solid rgba(0, 0, 0, 0.25);
}
.myne-area-add-products_export_btn:hover {
  opacity: 0.7;
}

.myne-area-add-products_export_btn > p {
  color: rgba(0, 0, 0, 0.87);
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  margin-left: 10px;
  text-transform: uppercase;
}

.myne-modal__modal-edit-cadastro-categorie-content {
  padding: 24px;
  padding-bottom: 28px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.myne-modal__modal-edit-cadastro-categorie-content
  .myne-modal__modal-new-sale-content__area-modal-search__itens-user {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.myne-modal__modal-edit-cadastro-categorie-content-categories {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  height: 100%;
}

.myne-modal__modal-edit-cadastro-categorie-content-categories-without {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

/* Switch ON Catalog */

.myne-modal__modal-edit-cadastro-content__show-catalogo {
  width: 100%;
  display: flex;
  justify-content: center;
  /* margin-top: 4px; */
  margin-top: 32px;
  align-items: center;
}
.myne-modal__modal-edit-cadastro-content__show-catalogo-big {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 4px;
  /* margin-top: 32px; */
  align-items: center;
}

.myne-modal__modal-edit-cadastro-content__show-catalogo > label {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--roxo-myne-2);
  padding: 8px 16px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.myne-modal__modal-edit-cadastro-content__show-catalogo > label:active {
  transform: scale(0.98);
  -webkit-transform: scale(0.98);
  -moz-transform: scale(0.98);
  -ms-transform: scale(0.98);
  -o-transform: scale(0.98);
}

.myne-modal__modal-edit-cadastro-content__show-catalogo > label > p {
  font-size: 12px;
}
.myne-modal__modal-edit-cadastro-content__show-catalogo-big > label {
  padding: 16px;
}
.myne-modal__modal-edit-cadastro-content__show-catalogo-big > label > p {
  font-size: 14px;
}

.myne-modal__modal-edit-cadastro-content__show-catalogo > label .switch {
  margin-left: 16px;
  min-width: 24px;
}

/* SelectChangeLang */

.myne-modal__custom-select {
  position: relative;
}

.myne-modal__custom-select-active {
  width: 72px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 8px;
  gap: 8px;
  cursor: pointer;
  padding: 12px 8px;
  border-radius: 124px;
  margin-left: 24px;
  border: 1px solid var(--border-myne);
  -webkit-border-radius: 124px;
  -moz-border-radius: 124px;
  -ms-border-radius: 124px;
  -o-border-radius: 124px;
}

.myne-modal__custom-select-active:hover {
  border: 1px solid rgba(0, 0, 0, 0.171);
}

.myne-modal__custom-select-active > svg {
  width: 20px;
  height: 20px;
}

.myne-modal__custom-select-active > svg:nth-child(2) {
  padding: 5px;
}

.myne-modal__custom-select-modal {
  position: absolute;
  top: 1px;
  margin-top: 36px;
  padding: 10px 16px;
  grid-gap: 10px;
  gap: 10px;
  box-shadow: var(--shadow-web);
  width: 167px;
  height: 112px;
  border-radius: 8px;
  right: 0;
  background-color: #fff;
  z-index: 999;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.myne-modal__custom-select-modal > div {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  background-color: #fff;
  cursor: pointer;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.myne-modal__custom-select-modal > div:hover {
  transform: scale(0.97);
  -webkit-transform: scale(0.97);
  -moz-transform: scale(0.97);
  -ms-transform: scale(0.97);
  -o-transform: scale(0.97);
}

.myne-modal__custom-select-modal > div > p {
  font-size: 14px;
}

MODAL SALE .myne-modal__modal-new-sale {
  width: 884px;
  height: 479px;
}
/* header */
.myne-modal__modal-new-sale .myne-modal__modal-edit-header {
  justify-content: space-around;
}
.myne-modal__modal-new-sale .myne-modal__modal-edit-header__left {
  margin-right: auto;
}

.myne-modal__modal-new-sale__header-filter-area {
  position: absolute;
  margin: 0 auto;
  width: 225px;
  height: 45px;
}

/* footer */
.myne-modal__modal-new-sale_bottom {
  width: 100%;
  display: flex;
  align-items: center;
  border-top: 1px solid #ebeff2;
  height: 69px;
  padding: 12px;
  padding-left: 24px;
}

.myne-modal__modal-new-sale_bottom--left {
  width: 346px;
  margin-bottom: 4px;
}

.myne-modal__modal-new-sale_bottom--center {
  margin-left: 24px;
}

.myne-area__select-date {
  display: flex;
  align-items: center;
  padding: 10px 32px;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 124px;
  position: relative;
}

.myne-area__select-date > div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
}
.myne-area__select-date > div .ant-picker {
  width: 100%;
  padding: 0;
  height: 100%;
}
.myne-area__select-date > div .ant-picker-suffix {
  display: none;
}
.myne-area__select-date > div .ant-picker-clear {
  display: none;
}
.myne-area__select-date > div .ant-picker-input > input {
  cursor: pointer !important;
}

.myne-area__select-date:hover {
  background: var(--background_hover);
}

.myne-area__select-date > p {
  margin: 0;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  margin-left: 10px;
}

.myne-modal__modal-new-sale_bottom--right {
  margin-left: auto;
}
.myne-modal__modal-new-sale_bottom--right-row {
  display: flex;
  align-items: center;
  margin-top: auto;
}
.myne-modal__modal-new-sale_bottom--right-row button {
  margin-left: 12px;
}

.myne-modal__modal-new-sale_bottom--right > .text {
  background: rgba(0, 0, 0, 0) !important;
}

.myne-modal__modal-new-sale_bottom--right > .text-active {
  color: rgba(0, 0, 0, 0.87) !important;
  cursor: pointer;
}

.myne-modal__modal-new-sale_bottom--right > button {
  padding: 10px 32px;
  height: 44px;
  background: rgba(0, 0, 0, 0.06);
  border-radius: 124px;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  color: rgba(0, 0, 0, 0.36);
}

.myne-modal__modal-new-sale_bottom--right > button:hover {
  opacity: 0.8;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}
.myne-modal__modal-new-sale_bottom--right > button:active {
  -webkit-transform: scale(0.97);
          transform: scale(0.97);
}

.myne-modal__modal-new-sale_bottom--right .active {
  background: var(--roxo-myne);
  color: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  cursor: pointer;
}

/* Content modal */

.myne-modal__modal-new-sale-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.myne-modal__modal-new-sale-content > header {
  display: flex;
  align-items: center;
  z-index: 1;
  width: 100%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
}

.myne-modal__modal-new-sale-content > header > div {
  flex: 1 1;
  display: flex;
  padding: 12px 24px;
  align-items: center;
  justify-content: center;
}

.myne-modal__modal-new-sale-content > header > div > button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 32px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 124px;
  background-color: #fff;
  cursor: pointer;
  font-family: "Poppins";
  font-weight: 500;
  text-transform: uppercase;
  font-size: 14px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
}
.myne-modal__modal-new-sale-content > header > div > button > svg {
  margin-right: 10px;
}
.myne-modal__modal-new-sale-content > header > div > button:hover {
  background-color: var(--background_hover);
}

.myne-modal__modal-new-sale-content > header > div:nth-child(2) {
  border-right: 1px solid #ebeff2;
  border-left: 1px solid #ebeff2;
}

.myne-modal__modal-new-sale-content > div {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}

.myne-modal__modal-new-sale-content__area {
  flex: 1 1;
  width: 100%;
  display: flex;
  height: 100%;
  width: 296px;
  flex-direction: column;
  padding-bottom: 68px;
}

.myne-modal__modal-new-sale-content__area
  > .myne-modal__modal-new-sale-content__area-overflow {
  display: flex;
  height: 271px;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
}

.myne-modal__modal-new-sale-content__area:nth-child(2)
  > .myne-modal__modal-new-sale-content__area-overflow {
  border-right: 1px solid #ebeff2;
  border-left: 1px solid #ebeff2;
}

.myne-modal__modal-new-sale-content__area-user {
  margin: 24px;
  display: flex;
  align-items: center;
  padding: 0 12px;
  width: 246px;
  height: 52px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 100px;
}

.myne-modal__modal-new-sale-content__area-user > img {
  width: 36px;
  height: 36px;
  margin: 0 !important;
  margin-right: 8px !important;
}

.myne-modal__modal-new-sale-content__area-user > p {
  margin: 0;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 13.5px;
  margin-right: 10px;
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.87;
}

.myne-modal__modal-new-sale-content__area-user > svg {
  margin-left: auto;
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.myne-modal__modal-new-sale-content__area-user > svg:hover {
  opacity: 0.7;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.myne-modal__modal-new-sale-content__area-product-service {
  margin: 8px 12px;
  display: flex;
  flex-direction: column;
  padding: 12px;
  width: 270px;
  max-width: 270px;
  min-width: 270px;
  position: relative;
  height: 94px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 16px;
}

.myne-modal__modal-new-sale-content__area-product-service > div {
  display: flex;
  align-items: center;
}
.myne-modal__modal-new-sale-content__area-product-service > div > img {
  width: 36px;
  height: 36px;
  margin-right: 12px !important;
  border-radius: 5px;
}
.myne-modal__modal-new-sale-content__area-product-service > div > p {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  margin: 0;
  color: rgba(0, 0, 0, 0.87);
  max-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.myne-modal__modal-new-sale-content__area-product-service > footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  margin-top: 8px;
  padding-top: 8px;
}
.myne-modal__modal-new-sale-content__area-product-service > footer > p {
  margin: 0;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.87);
}
.myne-modal__modal-new-sale-content__area-product-service > footer > b {
  margin: 0;
  font-family: "Poppins";
  font-weight: 700;
  font-size: 12px;
  text-align: right;
  color: rgba(0, 0, 0, 0.87);
}

.myne-modal__modal-new-sale-content__area-product-service > section {
  position: absolute;
  right: 12px;
  top: 12px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.myne-modal__modal-new-sale-content__area-product-service > section > svg {
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.myne-modal__modal-new-sale-content__area-product-service
  > section
  > svg:hover {
  opacity: 0.7;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.myne-modal__modal-new-sale-content__area-product-service:nth-child(1) {
  margin-top: 24px;
}

.myne-modal__modal-new-sale-content__area-product-service:nth-last-child(1) {
  margin-bottom: 24px;
}

/*  */

.myne-modal__modal-new-sale-content__area-modal-search {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 364px;
  height: 355px;
  -webkit-filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.12));
          filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.12));
  background: #fff;
  z-index: 999;
  border-radius: 8px;
  padding-bottom: 0;
  /* overflow-y: auto; */
  overflow: hidden;
}

.myne-modal__modal-new-sale-content__area-modal-search__input {
  display: flex;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  padding-bottom: 11px;
  padding-top: 16px;
}

.myne-modal__modal-new-sale-content__area-modal-search-area__seach {
  display: flex;
  flex-direction: column;
  padding: 10px 26px;
}

.myne-modal__modal-new-sale-content__area-modal-search__input > input {
  width: 100%;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  background: none;
  outline: initial;
  border: none;
  margin-right: 8px;
  color: rgba(0, 0, 0, 0.6);
}

.myne-modal__modal-new-sale-content__area-modal-search__input > svg {
  width: 20px;
  height: 20px;
  max-width: 20px;
  max-height: 20px;
  min-width: 20px;
  min-height: 20px;
}

.myne-modal__modal-new-sale-content__area-modal-search__itens {
  overflow-y: auto;
  padding: 12px 0px 24px 24px;
  padding-bottom: 0;
}

.myne-modal__modal-new-sale-content__area-modal-search__itens-user {
  display: flex;
  align-items: center;
  margin: 20px 0;
  margin-top: 0;
  padding: 4px 0;
  border-radius: 8px;
  cursor: pointer;
  width: 312px;
  transition: all 0.3s ease;
}

.myne-modal__modal-new-sale-content__area-modal-search__itens-user:hover {
  /* background: linear-gradient(
    90deg,
    #fff,
    var(--background_hover),
    var(--background_hover)
  ); */
  -webkit-transform: scale(0.985);
          transform: scale(0.985);
  opacity: 0.8;
}

.myne-modal__modal-new-sale-content__area-modal-search__itens-user:active {
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
}

.myne-modal__modal-new-sale-content__area-modal-search__itens-user > p {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 13.5px;
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.87;
  margin: 0;
}

.myne-modal__modal-new-sale-content__area-modal-search__itens-user-photo {
  margin-right: 8px !important;
}

.myne-modal__modal-new-sale-content__area-modal-search__itens-user-photo-img {
  width: 36px;
  max-width: 36px;
  max-height: 36px;
  min-width: 36px;
  min-height: 36px;
  background: #f5f5f5;
}

.myne-modal__modal-new-sale-content__area-modal-search__itens-user-photo-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 36px;
  cursor: pointer;
  max-width: 36px;
  max-height: 36px;
  min-width: 36px;
  min-height: 36px;
  border: none;
  height: 36px;
  background: var(--roxo-myne);
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 100px;
}

.myne-modal__modal-new-sale-content__area-modal-search__itens-user
  .myne-item__cadastro {
  background-color: none;
  box-shadow: none;
  border: none;
  border-radius: 0;
  height: 0;
  max-height: 0;
  min-height: 0;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
  margin-bottom: 0px;
  margin: 24px 0;
  width: 100%;
  justify-content: space-between;
  overflow-y: initial;
  overflow-x: initial;
}

.myne-modal__modal-new-sale-content__area-modal-search__itens-user
  .myne-item__cadastro-left
  > section {
  padding: 5px 0;
}

.myne-modal__modal-new-sale-content__area-modal-search__itens-user
  .myne-item__cadastro-left
  > section
  > p {
  font-size: 13.5px;
}
.myne-modal__modal-new-sale-content__area-modal-search__itens-user
  .myne-item__cadastro-right
  > section
  > p {
  font-size: 10px;
}
.myne-modal__modal-new-sale-content__area-modal-search__itens-user
  .myne-item__cadastro-right
  > section
  > h5 {
  font-size: 13.5px;
}
.myne-modal__modal-new-sale-content__area-modal-search__itens-user
  .myne-item__cadastro-left {
  margin-right: auto !important;
  flex: 1 1 !important;
}
.myne-modal__modal-new-sale-content__area-modal-search__itens-user
  .myne-item__cadastro-left
  > section
  > h5 {
  margin-top: 3px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  max-width: 134px;
}

.myne-modal__modal-new-sale-content__area-modal-search__itens-user
  .myne-item__cadastro-right {
  margin-left: auto !important;
}

.myne-modal__modal-new-sale-content__area-modal-search__itens-user
  .myne-item__cadastro-right
  > section {
  margin-left: 12px;
}

/* Fechamento */

.myne-modal__modal-new-sale-fechamento {
  height: 100%;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  padding: 24px;
}

.myne-modal__modal-new-btn {
  text-transform: uppercase;
}

.myne-modal__modal-new-sale-fechamento:nth-child(1),
.myne-modal__modal-new-btn:nth-child(1) {
  -webkit-animation: animateflex 0.4s;
          animation: animateflex 0.4s;
}
.myne-modal__modal-new-sale-fechamento:nth-child(2),
.myne-modal__modal-new-btn:nth-child(2) {
  -webkit-animation: animateflex 0.6s;
          animation: animateflex 0.6s;
}
.myne-modal__modal-new-sale-fechamento:nth-child(3),
.myne-modal__modal-new-btn:nth-child(3) {
  -webkit-animation: animateflex 0.8s;
          animation: animateflex 0.8s;
}
@-webkit-keyframes animateflex {
  from {
    -webkit-transform: translateY(-40px);
            transform: translateY(-40px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes animateflex {
  from {
    -webkit-transform: translateY(-40px);
            transform: translateY(-40px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    opacity: 1;
  }
}

.myne-modal__modal-new-sale-fechamento > p {
  font-family: "Poppins";
  font-weight: 700;
  font-size: 13.5px;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 24px;
}

.myne-modal__modal-new-sale-fechamento-parcelamento {
  border-left: 1px solid #ebeff2;
  border-right: 1px solid #ebeff2;
  overflow-y: auto;
}
.css-1a5icme.Mui-checked,
.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
  color: var(--roxo-myne) !important;
}
.css-1a5icme,
.css-vqmohf-MuiButtonBase-root-MuiRadio-root {
  padding: 0 !important;
}

.myne-modal__modal-new-sale-fechamento-parcelas {
  overflow-y: auto;
}

/*  */
.myne-modal__modal-new-sale-fechamento-parcelamento-item {
  border-top: 1px dashed rgba(0, 0, 0, 0.06);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50.73px;
  max-height: 50.73px;
  min-height: 50.73px;
  cursor: pointer;
}
.myne-modal__modal-new-sale-fechamento-parcelamento-item:nth-last-child(1) {
  border-bottom: 1px dashed rgba(0, 0, 0, 0.06);
}
.myne-modal__modal-new-sale-fechamento-parcelamento-item:hover {
  opacity: 0.9;
}
.myne-modal__modal-new-sale-fechamento-parcelamento-item > p:nth-child(1) {
  width: 24px;
  margin-right: 4px;
}
.myne-modal__modal-new-sale-fechamento-parcelamento-item > p:nth-child(2) {
  margin-right: auto;
}
.myne-modal__modal-new-sale-fechamento-parcelamento-item > div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  /* width: 70px; */
  height: 30px;
  background: #dcffc2;
  border-radius: 4px;
  /* text */
  font-family: "Poppins";
  font-weight: 400;
  font-size: 13.5px;
  color: #58cc02;
  /*  */
  margin-left: auto;
  margin-right: 29px;
}
.myne-modal__modal-new-sale-fechamento-parcelamento-item > p {
  font-family: "Poppins";
  margin: 0;
  font-weight: 400;
  font-size: 13.5px;
  color: rgba(0, 0, 0, 0.87);
}

/*  */
.myne-modal__modal-new-sale-fechamento-parcelas
  > .myne-modal__modal-edit-cadastro-content__installments
  > div
  > p {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.87);
  margin-right: 35px;
  text-decoration: underline;
  cursor: pointer;
  margin-left: 0;
  width: 60px;
  height: 18px;
  white-space: nowrap;
}
.myne-modal__modal-new-sale-fechamento-parcelas
  > .myne-modal__modal-edit-cadastro-content__installments
  > div
  > section
  > p,
.myne-modal__modal-new-sale-fechamento-parcelas
  > .myne-modal__modal-edit-cadastro-content__installments
  > div
  > label
  > p {
  margin: 0;
  padding: 0;
  margin-left: 8px;
  margin-right: 8px;
  font-family: "Poppins";
  font-weight: 400;
  cursor: pointer;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.87);
}
.myne-modal__modal-new-sale-fechamento-parcelas
  > .myne-modal__modal-edit-cadastro-content__installments
  > div {
  /* height: 42.73px; */
  height: 59.72px;
  padding: initial;
}
.myne-modal__modal-new-sale-fechamento-parcelas
  > .myne-modal__modal-edit-cadastro-content__installments
  > div
  > div
  > p {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 12px;
  color: var(--roxo-myne);
  cursor: pointer;
  text-decoration: underline;
  height: 18px;
}

.myne-modal__modal-new-sale-fechamento-parcelas
  > .myne-modal__modal-edit-cadastro-content__installments
  > div
  > div {
  margin-right: 5px;
}
.myne-modal__modal-new-sale-fechamento-parcelas
  > .myne-modal__modal-edit-cadastro-content__installments
  > div
  > label {
  flex-direction: column;
}
.myne-modal__modal-new-sale-fechamento-parcelas
  > .myne-modal__modal-edit-cadastro-content__installments
  > div
  > label
  > p {
  margin-bottom: 4px;
  color: var(--preto-87);
}

.myne-modal__modal-new-sale-fechamento-parcelas
  > .myne-modal__modal-edit-cadastro-content__installments
  > div
  > div
  > svg,
.myne-modal__modal-new-sale-fechamento-parcelas
  > .myne-modal__modal-edit-cadastro-content__installments
  > div
  > p
  > svg {
  width: 13px;
  height: 13px;
  margin-left: 5px;
  position: relative;
  top: 0.8px;
}
.myne-modal__modal-new-sale-fechamento-parcelas
  > .myne-modal__modal-edit-cadastro-content__installments
  > div
  > p
  > svg {
  top: 3px;
}
.myne-modal__modal-edit-cadastro-content__installments > div > div {
  position: relative;
  cursor: pointer;
}
.myne-modal__modal-edit-cadastro-content__installments > div > div .ant-picker {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
/*  */

.myne-modal__modal-new-sale-fechamento-valorvenda > section {
  display: flex;
  flex-direction: column;
}

.myne-modal__modal-new-sale-fechamento-valorvenda > section > div {
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.myne-modal__modal-new-sale-fechamento-valorvenda > section > div > p {
  margin: 0;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 13.5px;
  color: rgba(0, 0, 0, 0.87);
}
.myne-modal__modal-new-sale-fechamento-valorvenda > section > div > h4 {
  margin: 0;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
}
.myne-modal__modal-new-sale-fechamento-valorvenda > section > div > h5 {
  margin: 0;
  font-family: "Poppins";
  font-weight: 700;
  font-size: 16px;
  text-align: right;
  color: rgba(0, 0, 0, 0.87);
}

.myne-modal__modal-new-sale-fechamento-valorvenda > section > div > button {
  margin-right: 12px;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  height: 24px;
  background: rgba(255, 255, 255, 0.87);
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 54px;
  font-family: "Poppins";
  text-transform: uppercase;
  font-weight: 400;
  cursor: pointer;
  font-size: 11px;
  color: rgba(0, 0, 0, 0.87);
}

.myne-modal__modal-new-sale-fechamento-valorvenda
  > section
  > div
  > button:hover {
  background-color: var(--background_hover);
}

.myne-modal__modal-new-sale-fechamento-valorvenda > section > section {
  width: 100%;
  margin-bottom: 24px;
  border-top: 1px dashed rgba(0, 0, 0, 0.12);
}

/*  */

.myne-modal__modal-add-product-sale-item {
  width: 390px;
  height: 383px;
}

.myne-modal__modal-add-product-sale-item__container {
  padding: 24px;
  display: flex;
  height: 100%;
  flex-direction: column;
}

.myne-modal__modal-add-product-sale-item__container-area-product {
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: 342px;
  height: 174px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 16px;
}

.myne-modal__modal-add-product-sale-item__container-area-product > article {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.myne-modal__modal-add-product-sale-item__container-area-product > article > p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  margin: 0;
  line-height: 15px;
  color: rgba(0, 0, 0, 0.87);
}

.myne-modal__modal-add-product-sale-item__container-area-product
  > article
  > p
  > span {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 11px;
  color: rgba(0, 0, 0, 0.6);
  margin-right: 8px;
}

.myne-modal__modal-add-product-sale-item__container-area-product
  > article
  > p
  > b {
  font-weight: 700;
}

.myne-modal__modal-add-product-sale-item__container-area-product > div {
  display: flex;
  align-items: center;
  padding: 16px 0;
  margin: 16px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.myne-modal__modal-add-product-sale-item__container-area-product > div > img {
  width: 40px;
  height: 40px;
  border-radius: 5px;
}

.myne-modal__modal-add-product-sale-item__container-area-product > div > p {
  margin: 0;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  max-width: 142px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgba(0, 0, 0, 0.87);
}

.myne-modal__modal-add-product-sale-item__container-area-product
  > div
  > section {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.myne-modal__modal-add-product-sale-item__container-area-product
  > div
  > section
  > input {
  border: 1px solid rgba(0, 0, 0, 0.06);
  width: 47px;
  height: 47px;
  border-radius: 100%;
  text-align: center;
  outline: initial;
  margin: 0 12px;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
}

.myne-modal__modal-add-product-sale-item__container-area-product
  > div
  > section
  > svg {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.myne-modal__modal-add-product-sale-item__container-area-product
  > div
  > section
  > svg:nth-child(1):active {
  -webkit-transform: scale(0.88);
          transform: scale(0.88);
}

.myne-modal__modal-add-product-sale-item__container-area-product
  > div
  > section
  > svg:nth-child(3):active {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.myne-modal__modal-add-product-sale-item__container-area-product > section {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
}

.myne-modal__modal-add-product-sale-item__container-area-product > section > b {
  margin-left: 4px;
}

.myne-modal__modal-add-product-sale-item__container > button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 32px;
  width: 142px;
  cursor: pointer;
  height: 44px;
  background: var(--roxo-myne);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  border-radius: 124px;
  margin-top: auto;
  margin-left: auto;
  border: none;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
}

.myne-modal__modal-add-product-sale-item__container > button:active {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}
.myne-modal__modal-add-product-sale-item__container > button:hover {
  opacity: 0.8;
}

/* EDIT INSTALLMENT */

.myne-modal__modal-edit-value-installment {
  width: 390px;
  height: 521px;
}

.myne-modal__modal-edit-value-installment
  .myne-modal__modal-add-product-sale-item__container {
  padding: 0;
}

.myne-modal__modal-section-edit-value-installment {
  width: 100%;
  padding: 24px;
  max-height: 355px;
  overflow-y: auto;
}

.myne-modal__modal-section-edit-value-installment
  .myne-modal__modal-edit-cadastro-content__inputs {
  width: 100%;
  height: auto;
  padding: 0;
}

.myne-modal__modal-edit-value-installment-area-btns {
  min-height: 92px;
  padding: 24px;
  border-top: 1px solid var(--border-myne);
}

.myne-modal__modal-section-edit-value-installment__input {
  display: flex;
  align-items: center;
  position: relative;
}

.myne-modal__modal-section-edit-value-installment__input > .myne-icon-button {
  position: absolute;
  right: 16px;
  cursor: pointer;
}

.myne-modal__modal-section-edit-value-installment__input
  > .myne-icon-button
  path {
  fill-opacity: 0.87;
}

.myne-modal__modal-section-edit-value-installment__value-total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 34px;
}

.myne-modal__modal-section-edit-value-installment__value-total > section {
  display: flex;
  flex-direction: column;
  height: 39px;
}

.myne-modal__modal-section-edit-value-installment__value-total > section > p {
  font-size: 14px;
  font-weight: 500;
  line-height: 12px;
  /* margin-bottom: 12px; */
}

.myne-modal__modal-section-edit-value-installment__value-total > section > h5 {
  font-weight: 700;
  font-size: 24px;
  margin-top: auto;
}

.myne-modal__modal-section-edit-value-installment__value-total-associar {
  align-items: end;
}

.myne-modal__modal-section-edit-value-installment__value-total-associar * {
  color: var(--red-myne) !important;
}

.myne-modal__modal-section-edit-value-installment__value-total-associar > h5 {
  font-size: 16px !important;
  line-height: 12px;
}

/* MODAL welcome PLUS */

.myne-modal__modal-welcome-plus {
  display: flex;
  width: 611px;
  height: 479px;
  background: #ffffff;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
}

.myne-modal__modal-welcome-plus > svg {
  width: 24px;
  cursor: pointer;
  height: 24px;
  top: 24px;
  right: 24px;
  position: absolute;
}

.myne-modal__modal-welcome-plus > svg:hover {
  opacity: 0.8;
}

.myne-modal__modal-welcome-plus__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  -webkit-animation: initcontent 0.7s ease;
          animation: initcontent 0.7s ease;
}

@-webkit-keyframes initcontent {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes initcontent {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.myne-modal__modal-welcome-plus__content > img {
  margin-top: 39px;
  margin-bottom: -20px;
}

.myne-modal__modal-welcome-plus__content > section {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.myne-modal__modal-welcome-plus__content > section > p {
  margin: 0;
  margin-top: 71px;
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
  height: 36px;
}

.myne-modal__modal-welcome-plus__content > section > h5 {
  margin: 0;
  margin-top: 10px;
  width: 287px;
  height: 42px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
}

.myne-modal__modal-welcome-plus__content > section > svg {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: -1;
}

.myne-modal__modal-welcome-plus__content
  .myne-modal__modal-plus__content-vantagens {
  margin-top: 32px;
}

.myne-modal__modal-welcome-plus__content
  .myne-modal__modal-plus__content-vantagens
  * {
  color: rgba(0, 0, 0, 0.87);
  fill: rgba(0, 0, 0, 0.87);
}

/* MODAL PLUS */

.myne-modal__modal-plus {
  display: flex;
  width: 611px;
  height: 479px;
  background: #fff;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
}
.border-plus {
  --border-size: 2px;
  --border-angle: 0turn;

  background-image: conic-gradient(
      from var(--border-angle),
      var(--azul-myne),
      #85d1f5 50%,
      var(--azul-myne)
    ),
    conic-gradient(
      from var(--border-angle),
      transparent 20%,
      var(--azul-myne),
      #fff
    );
  background-size: calc(100% - (var(--border-size) * 2))
      calc(100% - (var(--border-size) * 2)),
    cover;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-animation: bg-spin 4s linear infinite;
  animation: bg-spin 4s linear infinite;
}

@-webkit-keyframes bg-spin {
  to {
    --border-angle: 1turn;
  }
}
@keyframes bg-spin {
  to {
    --border-angle: 1turn;
  }
}

@property --border-angle {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0turn;
}

.myne-modal__modal-plus > svg {
  width: 24px;
  cursor: pointer;
  height: 24px;
  top: 24px;
  right: 24px;
  position: absolute;
}

.myne-modal__modal-plus > svg:hover {
  opacity: 0.8;
}

.myne-modal__modal-plus__content {
  padding: 0 24px;
  padding-top: 72px;
  width: 100%;
  display: flex;
  flex-direction: column;
  -webkit-animation: initop 0.8s ease;
          animation: initop 0.8s ease;
}

.myne-modal--animation {
  transition: all 0.4s ease;
  -webkit-animation: initopacity 0.8s ease;
          animation: initopacity 0.8s ease;
}
.myne-modal--animation-scale {
  transition: all 0.2s ease;
  -webkit-animation: initscale 0.4s ease;
          animation: initscale 0.4s ease;
}
.myne-modal--animation-top {
  transition: all 0.3s ease;
  -webkit-animation: initop 0.45s ease;
          animation: initop 0.45s ease;
}
.myne-modal--animation-left {
  transition: all 0.4s ease;
  -webkit-animation: inileft 0.5s ease;
          animation: inileft 0.5s ease;
}

@-webkit-keyframes initscale {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes initscale {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@-webkit-keyframes initopacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes initopacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes inileft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(8%);
            transform: translateX(8%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}
@keyframes inileft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(8%);
            transform: translateX(8%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}

@-webkit-keyframes initop {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-15%);
            transform: translateY(-15%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
}

@keyframes initop {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-15%);
            transform: translateY(-15%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
}
@-webkit-keyframes initopmore {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
}
@keyframes initopmore {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
}

.myne-modal__modal-plus__content-info {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
}

.myne-modal__modal-plus__content-info > article {
  z-index: -1;
  position: absolute;
  top: 24px;
}

.myne-modal__modal-plus__content-info > svg {
  margin-right: 24px;
  width: 172.4px;
}

.myne-modal__modal-plus__content-info > section {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
  width: 274px;
}

.myne-modal__modal-plus__content-info > section > h5 {
  font-weight: 700;
  font-size: 24px;
  color: #ffffff;
  margin: 0;
  text-align: center;
  font-weight: 700;
  line-height: 32px;
  width: 274px;
}

.myne-modal__modal-plus__content-info > section > div {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

.myne-modal__modal-plus__content-info > section > div > p {
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  margin: 0;
}

.myne-modal__modal-plus__content-info > section > div > p > b {
  font-weight: 700;
}

.myne-modal__modal-plus__content-info > section > p {
  margin: 0;
  width: 287px;
  margin-top: 5px;
  font-weight: 400;
  font-size: 11px;
  color: #ffffff;
}

.myne-modal__modal-plus__content-info > section > p > strong {
  font-weight: 600;
  color: #ffffff;
}

.myne-modal__modal-plus__content-vantagens {
  display: flex;
  align-items: center;
  grid-gap: 24px;
  gap: 24px;
  justify-content: center;
  width: 514px;
  margin: 24px auto;
  margin-bottom: 32px;
}

.myne-modal__modal-plus__content-vantagens > section {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
}

.myne-modal__modal-plus__content-vantagens > section:nth-child(1) {
  width: 146px;
}

.myne-modal__modal-plus__content-vantagens > section:nth-child(2) {
  width: 212px;
}

.myne-modal__modal-plus__content-vantagens > section > article {
  display: flex;
  align-items: flex-start;
}
.myne-modal__modal-plus__content-vantagens > section > article > svg {
  margin-right: 12px;
  min-width: 18px;
  min-height: 18px;
}
.myne-modal__modal-plus__content-vantagens > section > article > p {
  color: var(--preto-87);
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  margin: 0;
}

.myne-modal__modal-plus__content-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 12px;
  gap: 12px;
}

.myne-modal__modal-plus__content-buttons-plan {
  display: flex;
  padding: 20px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* gap: 16px; */
  height: 140px;
  flex: 1 0;
  border-radius: 16px;
  border: 1px solid var(--preto-6);
  background: #fff;
}

.myne-modal__modal-plus__content-buttons-plan > button {
  border: 1px solid var(--roxo-myne);
  box-shadow: none;
  background-color: #fff;
  color: var(--roxo-myne);
  font-weight: 500;
}

.myne-modal__modal-plus__content-buttons-plan > button:hover {
  background-color: var(--roxo-myne-2);
}

.myne-modal__modal-plus__content-buttons-plan > div {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.myne-modal__modal-plus__content-buttons-plan > div > h5 {
  margin: 0;
  color: var(--preto-87);
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
}

.myne-modal__modal-plus__content-buttons-plan > div > h3 {
  color: var(--preto-87);
  text-align: right;
  font-size: 20px;
  font-weight: 700;
  line-height: 18px;
  white-space: nowrap;
  display: flex;
  margin: 0;
}

.myne-modal__modal-plus__content-buttons-plan > div > h3 > p {
  font-size: 12px;
  margin: 0;
  font-weight: 400;
  position: relative;
  top: 2px;
}

.myne-modal__modal-plus__content-buttons-plan > p {
  margin: 0;
  color: var(--preto-87);
  font-size: 10px;
  align-self: stretch;
  font-weight: 500;
  line-height: 18px;
  margin-top: 4px;
  margin-bottom: 16px;
}

.myne-modal__modal-plus__content-buttons-plan-active {
  border: 1px solid var(--roxo-myne);
  background: var(--roxo-myne-2);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.16);
}

.myne-modal__modal-plus__content-buttons-plan-active > button {
  background: var(--roxo-myne);
  color: #fff;
}

.myne-modal__modal-plus__content-buttons-plan-active > button:hover {
  background: var(--roxo-myne);
  opacity: 0.8;
}

.myne-modal__modal-plus__content-buttons-plan-active:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

/* MODAL IMPORT PRODUCTS */

.myne-modal__modal-edit-cadastro-import-products {
  width: 611px;
  height: 589px;
}

.myne-modal__modal-edit-cadastro-import-products-content {
  padding: 24px;
  display: flex;
  flex-direction: column;
}

.myne-modal__modal-edit-cadastro-import-products-content
  > .myne-modal__modal-new-sale_bottom--right
  button {
  position: absolute;
  right: 24px;
  bottom: 24px;
}

.myne-modal-import-products-content--header {
  display: flex;
  margin-bottom: 12px;
  flex-direction: column;
}

.myne-modal-import-products-content--header_stages {
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  height: 55px;
  width: 100%;
  justify-content: center;
}

.myne-modal-import-products-content--header_stages > svg {
  margin: 0 12px;
}

.myne-modal-import-products-content--header_stages > div {
  width: 257.5px;
  height: 55px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.myne-modal-import-products-content--header_stages > div > article {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
  width: 26px;
  height: 26px;
  background: #fff;
  border: 1px solid #aa00ff;
  border-radius: 100px;
}

.myne-modal-import-products-content--header_stages > div > article > p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
}

.myne-modal-import-products-content--header_stages > div > p {
  margin: 0;
  margin-top: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
}

.myne-modal-import-products-content--header_info {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  width: 563px;
  height: 69px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 8px;
}

.myne-modal-import-products-content--header_info > p {
  font-weight: 400;
  font-size: 12px;
  margin: 0;
  width: 355px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.87);
}

.myne-modal-import-products-content--header_info > svg {
  width: 32px;
  height: 32px;
  max-width: 32px;
  max-height: 32px;
  min-width: 32px;
  min-height: 32px;
  margin-right: 8px;
}

.myne-modal-import-products-content--header_info > a {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  width: 120px;
  height: 37px;
  background: rgba(255, 255, 255, 0.87);
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 124px;
  margin-left: auto;
  outline: initial;
  cursor: pointer;
}

.myne-modal-import-products-content--header_info > a:hover {
  background-color: var(--background_hover);
  border: 1px solid rgba(0, 0, 0, 0.08);
}

.myne-modal-import-products-content--header_info > a:active {
  -webkit-transform: scale(0.97);
          transform: scale(0.97);
}

.myne-modal-import-products-content--header_info > a p {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
  margin: 0;
  margin-left: 8px;
}

.myne-modal-import-products-content--header_info--download {
}

.myne-modal-import-products-content__area-import-file {
  display: flex;
  flex-direction: column;
}

.myne-modal-import-products-content__area-import-file > section {
  width: 100%;
  height: 192px;
  background: #faefff;
  border: 1px dashed #aa00ff;
  border-radius: 8px;
  margin-bottom: 12px;
  position: relative;
}

.myne-modal-import-products-content__area-import-file > section > div {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.myne-modal-import-products-content__area-import-file > section > div > p {
  margin: 0;
  margin-top: 8px;
  width: 220px;
  height: 42px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
}

.myne-modal-import-products-content__area-import-file > section > div > p > b {
  color: var(--roxo-myne);
  font-weight: 700;
  margin-left: 4px;
  text-decoration: underline;
}

.myne-modal-import-products-content__area-import-file > section > input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.myne-modal-import-products-content__area-import-file > section.drop-file,
.myne-modal-import-products-content__area-import-file > section:active {
  opacity: 0.5;
  -webkit-transform: scale(0.99);
          transform: scale(0.99);
}

.myne-modal-import-products-content__area-import-file__form-info {
  display: flex;
  align-items: center;
  padding: 10px 16px;
  width: 100%;
  height: 36px;
  background: #f5f5f5;
  border-radius: 8px;
}

.myne-modal-import-products-content__area-import-file__form-info > p {
  height: 15px;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: rgba(0, 0, 0, 0.87);
  margin: 0;
  margin-left: 8px;
}

/*  */

.myne-modal-import-products-content__area-import-file___sucess {
  background: #dcffc2 !important;
  border: 1px dashed #58cc02 !important;
}

.myne-modal-import-products-content__area-import-file___sucess > div > p {
  font-weight: 700 !important;
  width: 238px !important;
  height: 21px !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: rgba(0, 0, 0, 0.87);
}

.myne-modal-import-products-content__area-import-file___sucess > div > section {
  display: flex;
  align-items: center;
  margin-top: 8px;
}
.myne-modal-import-products-content__area-import-file___sucess
  > div
  > section
  > p {
  margin: 0;
  margin-right: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: rgba(0, 0, 0, 0.87);
}

.myne-modal-import-products-content__area-import-file___sucess
  > div
  > section
  > .icon-area
  svg {
  cursor: pointer;
}

/*  */

.myne-modal-import-products-content__area-import-file___erro {
  background: #ffdbe0 !important;
  border: 1px dashed #fe2543 !important;
}

.myne-modal-import-products-content__area-import-file___erro > div > p {
  width: 229px !important;
  height: 63px !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  text-align: center !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.myne-modal-import-products-content__area-import-file___erro > div > p > b {
  color: rgba(0, 0, 0, 0.87) !important;
  font-weight: 700 !important;
}

.myne-modal-import-products-content__area-import-file___erro > div > section {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.myne-modal-import-products-content__area-import-file___erro
  > div
  > section
  > p {
  margin: 0;
  margin-right: 8px;
  width: 200px;
  height: 36px;
  font-weight: 400;
  text-align: center;
  font-size: 12px;
  line-height: 18px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: rgba(0, 0, 0, 0.87);
}

/*  */

.myne-modal-import-products-content__area-import-file__form-info-erro,
.myne-modal-import-products-content__area-import-file__form-info-alert {
  display: flex;
  align-items: center;
  padding: 16px;
  height: 56px;
  width: 100%;
  background: #ffdbe0;
  border-radius: 8px;
}

.myne-modal-import-products-content__area-import-file__form-info-alert {
  background: #fff1cb !important;
}

.myne-modal-import-products-content__area-import-file__form-info-erro > p,
.myne-modal-import-products-content__area-import-file__form-info-alert > p {
  width: 499px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.87);
  margin: 0;
  margin-left: 8px;
}

/*  */

.myne-modal-import-products-area-background-overflow {
  position: absolute;
  width: 100%;
  height: 114px;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    360deg,
    #ffffff 34.74%,
    rgba(255, 255, 255, 0) 122.84%
  );
  border-radius: 0 0 8px 8px;
}

.myne-modal-import-products-content__area-import-products-table {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  width: 100%;
  height: 300px;
  overflow-y: auto;
  padding-bottom: 50px;
}

/*  */

.myne-modal-import-products-content__area-import-products-table__titles {
  width: 100%;
  display: flex;
  padding: 12px 0px;
  border-width: 2px 0px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.06);
}

.myne-modal-import-products-content__area-import-products-table__titles
  > section {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.87);
  margin-right: 6px;
}

.myne-modal-import-products-read__area-big {
  width: 200px;
}

.myne-modal-import-products-read__area-small {
  width: 84.75px;
}

.myne-modal-import-products-read__area-small-2 {
  width: 120px;
  max-height: 50px;
  white-space: nowrap;
  align-items: center !important;
  overflow-x: hidden;
  position: relative;
}

.myne-modal-import-products-read__area-small-2 > p {
  position: absolute;
  right: 1px;
  margin: auto;
  background-color: #cbedfd;
  cursor: pointer;
  border-radius: 3px;
  color: var(--roxo-myne);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  font-size: 12px;
  width: 22px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.myne-modal-import-products-read__area-small-2::-webkit-scrollbar {
  width: 0px !important;
  height: 0px !important;
}

.myne-modal-import-products-read__area-small-3 {
  white-space: pre-wrap;
  width: 120px;
  word-break: break-all;
}
.myne-modal-import-products-read__area-small-4 {
  white-space: pre-wrap;
  width: 120px;
}

.myne-modal-import-products-content__area-import-products-table__values {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 20px;
}

.myne-modal-import-products-content__area-import-products-table__values > div {
  display: flex;
  align-items: center;
  width: 100%;
  height: 48px;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.06);
}

.myne-modal-import-products-content__area-import-products-table__values
  > div
  > section {
  display: flex;
  align-items: center;
  margin-right: 6px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.87);
}

.myne-modal-import-products-content__area-import-products-table__values
  > div
  > section
  > svg {
  margin-right: 6px;
}

/* MODAL CONFIGURE ACCOUNT */

.myne-modal__modal-configure-account {
  padding: 24px;
  display: flex;
  width: 100%;
  flex-direction: column;
}

.myne-text-modal {
  color: var(--preto-87);
  font-size: 12px;
  margin: 0;
  font-weight: 700;
  line-height: 24px;
}

.myne-text-info {
  color: var(--preto-87);
  font-size: 20px;
  margin: 0;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: auto !important;
}

.myne-modal__modal-configure-account__plus-true {
  background: var(--roxo-myne-2) !important;
}

.myne-modal__modal-configure-account__plus {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  margin: 12px 0;
  background: var(--background-gray);
}

.myne-modal__modal-configure-account__plus > svg {
  margin-right: 16px;
}

.myne-modal__modal-configure-account__plus > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.myne-modal__modal-configure-account__plus > div > p {
  color: var(--preto-87);
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}
.myne-modal__modal-configure-account__plus > div > h5 {
  margin: 0;
  color: var(--preto-87);
  font-size: 14px;
  font-weight: 700;
}

.myne-modal__modal-configure-account-btns {
  margin-top: 12px;
  display: flex;
  align-items: center;
}

.myne-modal__modal-configure-account-btns button:disabled {
  cursor: not-allowed !important;
  opacity: 0.8 !important;
}

.myne-modal__modal-configure-account-btns button {
  margin-right: 12px;
  border-radius: 124px;
  border: 1px solid var(--preto-6);
  background: var(--branco-87);
  height: 44px;
  padding: 10px 32px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
}

.myne-modal__modal-configure-account-btns button > svg {
  margin-right: 10px;
}
.myne-modal__modal-configure-account-btns > span {
  border-radius: 4px;
  background: var(--preto-6);
  display: flex;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  color: var(--preto-87);
  font-size: 12px;
  font-weight: 400;
}

.myne-modal__modal-configure-account-btns button:hover {
  background: var(--background-gray);
}

.myne-modal__modal-configure-account-btns button > p {
  color: var(--preto-87);
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  text-transform: uppercase;
}

.myne-modal__modal-configure-account-btns button:active {
  -webkit-transform: scale(0.98, 0.98);
          transform: scale(0.98, 0.98);
}

.myne-modal__modal-configure-account__plus--area {
  display: flex;
  width: 100%;
  padding: 12px 8px;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px;
  /* border: 1px solid var(--preto-6); */
  background-color: var(--azul-myne);
}

.myne-modal__modal-configure-account__plus--area > section {
  display: flex;
  align-items: center;
  padding: 6px;
  border-radius: 32px 8px 8px 32px;
  background: #fff;
}

.myne-modal__modal-configure-account__plus--area > section > button {
  min-width: 171px;
}

.myne-modal__modal-configure-account__plus--area > section > p {
  margin: 0;
  color: var(--preto-87);
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  margin-left: 12px;
}

.myne-modal__modal-configure-account__plus--area
  > .myne-modal__modal-plus__content-vantagens {
  margin: 0 auto;
  margin-top: 10px;
  width: 100%;
}

.myne-modal__modal-configure-account__plus--area
  > .myne-modal__modal-plus__content-vantagens
  > section
  > article
  > p {
  font-size: 11px;
  line-height: 17px;
  margin: 0;
}
.myne-modal__modal-configure-account__plus--area
  > .myne-modal__modal-plus__content-vantagens
  > section
  > article
  > svg {
  min-width: 16px;
  min-height: 16px;
  max-width: 16px;
  max-height: 16px;
}

.myne-modal__modal-edit-cadastro-content-relogin {
  display: flex;
  justify-content: center;
  align-items: center;
}

.myne-modal__modal-edit-cadastro-content-relogin .login__content {
  margin: 0 auto;
  height: 87% !important;
}

.myne-modal__modal-edit-cadastro-content-relogin
  .login__content-recuperar__senha {
  margin: 0 auto;
  height: 87% !important;
}

.myne-modal__modal-remove-account {
  width: 300px !important;
  height: 265px !important;
}

.myne-modal__modal-remove-account-confirm {
  width: 300px !important;
  height: 202px !important;
}

/* SALE DETAILS */

.myne-modal__modal-edit-cadastro-content__info-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 12px;
}

.myne-modal__modal-edit-cadastro-content__info-top
  > .myne-item__venda-status
  > div {
  padding: 3px 15px !important;
  font-family: "Poppins" !important;
  font-weight: 400 !important;
  font-size: 13.5px !important;
  border-radius: 20px !important;
}

.myne-modal__modal-edit-cadastro-content__info-top > p {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  opacity: 0.87;
}

.myne-modal__modal-edit-cadastro-content__installments {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: 1px dashed rgba(0, 0, 0, 0.06);
}

.myne-modal__modal-edit-cadastro-content__installments > div {
  display: flex;
  width: 100%;
  align-items: center;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.06);
  padding: 12px 0;
}

.myne-modal__modal-edit-cadastro-content__installments > div > span {
  font-family: "Poppins";
  font-weight: 400;
  width: 14px;
  height: 18px;
  font-size: 13.5px;
  color: rgba(0, 0, 0, 0.36);
}

.myne-modal__modal-edit-cadastro-content__installments > div > p {
  margin: 0;
  padding: 0;
  min-width: 64px;
  height: 18px;
  font-family: "Poppins";
  font-weight: 400;
  margin-right: 24px;
  font-size: 13.5px;
  color: rgba(0, 0, 0, 0.87);
  text-decoration: underline;
  cursor: pointer;
}

.myne-modal__modal-edit-cadastro-content__installments > div > div {
  display: flex;
  align-items: center;
}

.myne-modal__modal-edit-cadastro-content__installments > div > div > p {
  margin: 0;
  padding: 0;
  height: 18px;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 13.5px;
  color: var(--roxo-myne);
}

.myne-modal__modal-edit-cadastro-content__installments > div > p > svg {
  margin-left: 8px !important;
}

.myne-modal__modal-edit-cadastro-content__installments > div > p > svg,
.myne-modal__modal-edit-cadastro-content__installments > div > div > svg {
  width: 17px;
  height: 17px;
  margin-left: 12px;
  position: relative;
  top: 1px;
  color: var(--roxo-myne);
}

.myne-modal__modal-edit-cadastro-content__installments > div > section,
.myne-modal__modal-edit-cadastro-content__installments > div > label {
  display: flex;
  margin-left: auto;
  align-items: center;
}

.myne-modal__modal-edit-cadastro-content__installments > div > section > p,
.myne-modal__modal-edit-cadastro-content__installments > div > label > p {
  margin: 0;
  padding: 0;
  margin-right: 12px;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 13.5px;
  color: rgba(0, 0, 0, 0.87);
}

.myne-modal__modal-edit-cadastro-content__itens {
  display: flex;
  flex-direction: column;
}

.myne-modal__modal-edit-cadastro-content__itens > p {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 13.5px;
  margin-top: 24px;
  margin-bottom: 12px;
  color: rgba(0, 0, 0, 0.6);
}

.myne-modal__modal-edit-cadastro-content__itens-client {
  display: flex;
  align-items: center;
}

.myne-modal__modal-edit-cadastro-content__itens-client > p {
  margin-left: 8px;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 13.5px;
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.87;
}

.myne-modal__modal-edit-cadastro-content__itens-array {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.myne-modal__modal-edit-cadastro-content__itens-card {
  width: 100%;
  display: flex;
  align-items: center;
  width: 342px;
  height: 60px;
  border: 1px dashed rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  margin-bottom: 8px;
  padding: 12px;
}

.myne-modal__modal-edit-cadastro-content__itens-card:nth-last-child(1) {
  margin-bottom: 0px;
}

.myne-modal__modal-edit-cadastro-content__itens-card > div {
  width: 36px;
  height: 36px;
  max-width: 36px;
  max-height: 36px;
  min-width: 36px;
  min-height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f5f5;
  border-radius: 5px;
  margin-right: 8px;
}

.myne-modal__modal-edit-cadastro-content__itens-card > div > img {
  width: 100%;
  border-radius: 5px;
  object-fit: cover;
  height: 100%;
  object-fit: cover;
}

.myne-modal__modal-edit-cadastro-content__itens-card > section {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.myne-modal__modal-edit-cadastro-content__itens-card > section > p {
  margin: 0;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 13.5px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.6);
}

.myne-modal__modal-edit-cadastro-content__itens-card > section > section {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 13.5px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  align-items: center;
}

.myne-modal__modal-edit-cadastro-content__itens-card > section > section > b {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 13.5px;
  text-align: right;
  margin-left: auto;
  color: rgba(0, 0, 0, 0.87);
}

/*  */

.myne-modal__modal-edit-header__left > p strong {
  color: rgba(0, 0, 0, 0.87);
  font-weight: 700;
  text-transform: lowercase;
}

.myne-modal__modal-edit-cadastro-content__right-subimit__infos {
  display: flex;
  flex-direction: column;
}

.myne-modal__modal-edit-cadastro-content__right-subimit__infos-values {
  display: flex;
  flex-direction: column;
}

.myne-modal__modal-edit-cadastro-content__right-subimit__infos-values > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
}

.myne-modal__modal-edit-cadastro-content__right-subimit__infos-values > div p {
  margin: 0;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.87);
}
.myne-modal__modal-edit-cadastro-content__right-subimit__infos-values > div h5 {
  margin: 0;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: right;
  color: #fe2543;
}

.myne-modal__modal-edit-cadastro-content__right-subimit__infos-values
  > div:nth-child(2) {
  border-bottom: 1px dashed rgba(0, 0, 0, 0.06);
}

.myne-modal__modal-edit-cadastro-content__right-subimit__infos-values
  > section {
  display: flex;
  padding: 8px 0;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.myne-modal__modal-edit-cadastro-content__right-subimit__infos-values
  > section
  p {
  margin: 0;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.87);
}

.myne-modal__modal-edit-cadastro-content__right-subimit__infos-values
  > section
  h5 {
  margin: 0;
  font-family: "Poppins";
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  color: rgba(0, 0, 0, 0.87);
}

.myne-modal__modal-edit-cadastro-content__right-subimit__infos-description {
  display: flex;
  margin-top: 24px;
}

.myne-modal__modal-edit-cadastro-content__right-subimit__infos-description
  > svg {
  width: 18px;
  height: 18px;
  max-width: 18px;
  max-height: 18px;
  min-width: 18px;
  min-height: 18px;
}

.myne-modal__modal-edit-cadastro-content__right-subimit__infos-description > p {
  font-family: "Poppins";
  font-weight: 400;
  margin-left: 12px;
  font-size: 12px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.8);
}

/* RECIBO */

.myne-recibo {
  width: 100%;
  height: 100%;
  background: #ffffff;
  position: fixed;
  z-index: 99999999;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
}

.myne-recibo > svg {
  position: fixed;
  top: 15px;
  right: 17px;
  width: 28px;
  height: 28px;
  cursor: pointer;
}
.myne-recibo > svg:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  opacity: 0.7;
}
.myne-recibo-content__btns {
  position: fixed;
  right: 15px;
  top: 15px;
}

.myne-recibo-content__btns > div {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  font-family: "Poppins";
  font-weight: 400;
  cursor: pointer;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
}
.myne-recibo-content__btns > div:hover {
  opacity: 0.7;
}
.myne-recibo-content__btns > div:active {
  -webkit-transform: scale(0.98);
          transform: scale(0.98);
}
.myne-recibo-content__btns > div > b {
  margin-left: 4px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.87);
}
.myne-recibo-content__btns > div > svg {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}

.myne-recibo-content {
  width: 595px;
  min-height: 842px;
  margin: auto;
  display: flex;
  flex-direction: column;
  background: #ffffff;
}

.myne-recibo-content > footer {
  /* position: absolute;
  bottom: 0; */
  width: 595px;
  height: 57px;
  max-height: 57px;
  min-height: 57px;
  background: rgba(0, 0, 0, 0.06);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 57px;
  margin-top: auto;
}

.myne-recibo-content > footer > p {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 12px;
  margin: 0;
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.87;
}

.myne-recibo-content > footer > p > b {
  font-weight: 700;
}

.myne-recibo-content > footer > img {
  width: 106px;
  height: 21px;
}

/* Main recibo */

.myne-recibo-content__main {
  padding: 57px;
  display: flex;
  flex-direction: column;
}

.myne-recibo-content__main > header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.myne-recibo-content__main > header > div {
  display: flex;
  align-items: center;
}

.myne-recibo-content__main > header > div > svg {
  width: 24px;
  height: 24px;
  margin-right: 12px;
}

.myne-recibo-content__main > header > div > p {
  margin: 0;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
}

.myne-recibo-content__main > header > div > p > b {
  color: rgba(0, 0, 0, 0.87);
  font-weight: 700;
}

.myne-recibo-content__main > header > p {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.87);
}

.myne-recibo-content__main--info {
  display: flex;
  width: 100%;
  margin: 32px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
  grid-gap: 20px;
  gap: 20px;
  width: 481px;
  height: 82px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 16px;
}

.myne-recibo-content__main--info > section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  grid-gap: 8px;
  gap: 8px;
  width: 134px;
  height: 42px;
}

.myne-recibo-content__main--info > section > p {
  width: 134px;
  height: 16px;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.6);
  margin: 0;
}

.myne-recibo-content__main--info > section > h5 {
  width: 142px;
  height: 18px;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.87;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 142px;
}

.myne-recibo-content__main--itens {
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.myne-recibo-content__main--table {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 16px;
}

.myne-recibo-content__main--table-titles {
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 8px;
  width: 481px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.36);
}

.myne-recibo-content__main--table-titles > p {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 12px;
  margin: 0;
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.87;
}

.myne-recibo-content__main--table-titles > h5 {
  margin: 0;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.87;
}

.myne-recibo-content__main--table-itens {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.myne-recibo-content__main--table-itens-item {
  display: flex;
  align-items: center;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.myne-recibo-content__main--table-itens-item > p {
  height: 21px;
  /* display: flex; */
  /* align-items: center; */
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  margin: 0;
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.87;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.myne-recibo-content__main--table-itens-item > h5 {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 12px;
  margin: 0;
  color: rgba(0, 0, 0, 0.87);
  padding-top: 2px;
  opacity: 0.87;
}

.myne-recibo-content__main--table-itens-item > b {
  font-family: "Poppins";
  font-weight: 700;
  font-size: 14px;
  margin: 0;
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.87;
}

.myne-recibo-content__main--info_parcelamento {
  display: flex;
  flex-direction: column;
}

.myne-recibo-content__main--info_parcelamento > p {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  opacity: 0.87;
}

.myne-modal__modal-edit-cadastro-content__customize-receipt {
  display: flex;
  padding: 24px;
  width: 100%;
  grid-gap: 24px;
  gap: 24px;
}

.myne-modal__modal-content__customize-receipt--upload {
  display: flex;
  align-items: center;
  flex-direction: column;
  grid-gap: 16px;
  gap: 16px;
}

.myne-modal__modal-content__customize-receipt--upload__label {
  width: 216px;
  height: 122px;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  border-radius: 8px;
  background: var(--preto-6);
  cursor: pointer;
  overflow: hidden;
  border: 1px dashed var(--preto-6);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.myne-modal__modal-content__customize-receipt--upload__label:hover,
.myne-modal__modal-edit-cadastro-content__right-subimit__photo:hover {
  background-color: #fff;
  box-shadow: var(--shadow-button);
}

.myne-modal__modal-content__customize-receipt--upload__label:active,
.myne-modal__modal-edit-cadastro-content__right-subimit__photo:active {
  transform: scale(0.97);
  -webkit-transform: scale(0.97);
  -moz-transform: scale(0.97);
  -ms-transform: scale(0.97);
  -o-transform: scale(0.97);
}

.myne-modal__modal-content__customize-receipt--upload__label > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.myne-modal__modal-content__customize-receipt--upload__label > div {
  display: flex;
  width: 100%;
  height: 100%;
  grid-gap: 10px;
  gap: 10px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.myne-modal__modal-content__customize-receipt--upload > button {
  height: 37px;
  width: 134px;
  padding: 0 24px;
  margin: 0;
}

.myne-modal__modal-content__customize-receipt--upload__label > div > p {
  text-align: center;
}

.myne-modal__modal-edit-cadastro-content__customize-receipt
  .myne-modal__modal-edit-cadastro-content__inputs {
  padding: 0;
  width: 100%;
  flex: 1 1;
}

@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -800px 0;
  }
  100% {
    background-position: 800px 0;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -800px 0;
  }
  100% {
    background-position: 800px 0;
  }
}

.myne-loading-upload-image {
  position: absolute;
  width: 100%;
  bottom: 0;
  animation: placeHolderShimmer linear infinite forwards 2s;
  background-color: #85d1f5;
  background: linear-gradient(
    to right,
    #85d2f566 8%,
    #85d2f5a8 18%,
    #85d2f566 33%
  );
  background-size: 800px 104px;
  z-index: 2;
  -webkit-animation: placeHolderShimmer linear infinite forwards 2s;
}

/* RECEIPT */

.myne-recibo-custom-card--companie {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  grid-gap: 16px;
  gap: 16px;
  background-color: var(--background-gray-2);
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.myne-recibo-custom-card--companie > img {
  width: 156px !important;
  height: 88px !important;
}

.myne-recibo-custom-card--companie__image {
  border-radius: 8px;
  width: 126px;
  height: 72px;
  border: 1px solid var(--preto-6);
  object-fit: cover;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.myne-recibo-custom-card--companie > section {
  display: flex;
  flex-direction: column;
}

.myne-recibo-custom-card--companie > section > h2 {
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
}

.myne-recibo-custom-card--companie > section > p {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.myne-recibo-custom-card--companie > section > span {
  font-size: 10px;
  font-weight: 400;
  margin-top: 5px;
  line-height: 18px;
}

.myne-recibo--footer-companie {
  height: 104px !important;
  max-height: 104px !important;
  min-height: 104px !important;
  padding: 16px 56px !important;
  align-items: end !important;
}

.myne-recibo--footer-companie--info {
  display: flex;
  align-items: start;
  width: 70%;
  grid-gap: 16px;
  gap: 16px;
}

.myne-recibo--footer-companie--info > p {
  font-size: 14px;
  font-weight: 400;
}

.myne-recibo--button {
  width: 190px !important;
  padding: 0 20px !important;
  margin: 0 4px !important;
  font-weight: 500 !important;
  background-color: var(--roxo-myne) !important;
  text-transform: none;
  color: #fff !important;
}

.myne-recibo--button > svg {
  width: 16px;
  height: 16px;
  margin-right: 10px !important;
}

.myne-recibo--button > svg path {
  fill: #fff;
  fill-opacity: 1;
}

.myne-recibo--buttons {
  display: flex;
  right: 55px;
  top: 10px;
  position: fixed;
}

.myne-filter-content {
  position: relative;
  margin-left: 24px;
  width: 80%;
  right: 0;
}

@media (max-width: 1070px) {
  .myne-filter-content {
    width: 73%;
    right: 0;
    margin-left: 18px;
  }
}

.myne-filter-dates {
  display: flex;
  align-items: center;
  overflow-y: hidden;
  padding-left: 0;
  scroll-behavior: smooth;
  white-space: nowrap;
  overflow-x: scroll;
  transition: all 0.4s ease;
}

.myne-filter-dates::-webkit-scrollbar {
  width: 90px;
  height: 0px;
}

/**/
.myne-filter-dates-left {
  position: absolute;
  left: -5px;
  padding: 10px;
  top: -3px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: auto;
  z-index: 1;
  height: 40px;
  background: linear-gradient(
    268deg,
    rgba(255, 255, 255, 0),
    var(--background),
    var(--background)
  );
}

.myne-filter-dates-left > section {
  width: 80px;
  height: 35px;
}

.myne-filter-dates-left div > svg {
  width: 10px;
  margin-top: 2px;
  cursor: pointer;
  height: 18px;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
/**/

.myne-filter-dates-right {
  position: absolute;
  right: -5px;
  padding: 10px;
  top: -3px;
  display: flex;
  z-index: 1;
  align-items: center;
  width: auto;
  height: 40px;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    var(--background),
    var(--background),
    var(--background)
  );
}

.myne-filter-dates-right > section {
  width: 60px;
  height: 35px;
}

.myne-filter-dates-right div > svg {
  width: 10px;
  margin-top: 5px;
  cursor: pointer;
  height: 18px;
}

.myne-filter-dates-left:active,
.myne-filter-dates-right:active {
  transform: scale(0.97);
  -webkit-transform: scale(0.97);
  -moz-transform: scale(0.97);
  -ms-transform: scale(0.97);
  -o-transform: scale(0.97);
}

.myne-filter-dates > button {
  padding: 4px 10px;
  margin-right: 9px;
  /* height: 35px; */
  transition: all 0.2s ease;
  font-size: 15px;
  border-radius: 35px;
  display: flex;
  align-items: center;
  z-index: 1;
  outline: initial;
  cursor: pointer;
  background: rgb(255, 255, 255);
  /* background: rgb(255, 255, 255, 0); */
  border: none;
  -webkit-transform: scale(1);
          transform: scale(1);
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid rgba(0, 0, 0, 0.06);
}

.myne-filter-dates-btn-icon {
  width: 15px;
  height: 15px;
  max-width: 15px;
  max-height: 15px;
  min-width: 15px;
  min-height: 15px;
  margin-right: 8px;
}

.myne-filter-dates > button:hover {
  border: 1px solid rgba(0, 0, 0, 0.171);
  background: rgb(252, 251, 253);
}

.myne-filter-dates > button:active {
  -webkit-transform: scale(0.97);
          transform: scale(0.97);
}

.myne-filter-dates > button > svg:hover {
  opacity: 0.5;
}

.myne-dashboard {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  position: relative;
  overflow: hidden;
}

.dashboard__content {
  flex: 1 1;
  padding: 14px;
  display: flex;
  overflow-y: scroll;
  flex-direction: column;
}

.dashboard__content::-webkit-scrollbar {
  width: 6px;
  border-radius: 5px;
}
.dashboard__content::-webkit-scrollbar-track {
  border-radius: 0px;
  background-color: var(--background);
}
.dashboard__content::-webkit-scrollbar-thumb {
  background: linear-gradient(var(--roxo-myne), var(--roxo-myne));
}

.dashboard-content__filter {
  display: flex;
  margin: 10px 0;
}

.dashboard-content__filter > p {
  font-weight: bold;
  font-size: 24px;
  display: flex;
  margin-left: 24px;
  align-items: center;
  color: rgba(0, 0, 0, 0.87);
}

/**/

.dashboard-content__grid {
  display: flex;
  flex: 1 1;
  flex-wrap: wrap;
  margin-top: 10px;
  grid-gap: 10px;
  gap: 10px;
}

/**/

.dashboard-content__grid-cadastros-resume-clientes {
  background-color: #fff;
  width: 307px;
  height: 615px;
  z-index: 2;
  border-left: 1px solid rgba(0, 0, 0, 0.06);
  position: relative;
  padding: 24px 24px 0px;
  display: flex;
  flex-direction: column;
  border-radius: 0px 8px 8px 0px;
  overflow-y: auto;
}

.dashboard-content__grid-cadastros-resume-clientes > p {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 700;
  margin: 0;
}

.dashboard-content__grid-cadastros-resume-clientes_content {
  display: flex;
  flex-direction: column;
}

.dashboard-content__grid-cadastros-resume-clientes_content-user {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 52px;
  background: #faefff;
  border: 1px solid var(--roxo-myne);
  border-radius: 100px;
  margin: 24px 0;
}

.dashboard-content__grid-cadastros-resume-clientes_content-user > img {
  width: 36px;
  height: 36px;
  margin-right: 8px;
}

.dashboard-content__grid-cadastros-resume-clientes_content-user > p {
  margin: 0;
  font-weight: 600;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.87;
}

.dashboard-content__grid-cadastros-resume-clientes_content-item__info {
  display: flex;
  margin-bottom: 24px;
}

.dashboard-content__grid-cadastros-resume-clientes_content-item__info > svg {
  margin-right: 19px;
  max-width: 18px;
  max-height: 18px;
  min-width: 18px;
  min-height: 18px;
  width: 18px;
  height: 18px;
}

.dashboard-content__grid-cadastros-resume-clientes_content-item__info
  > section {
  display: flex;
  flex-direction: column;
  margin-top: -3px;
}

.dashboard-content__grid-cadastros-resume-clientes_content-item__info
  > section
  > p {
  margin: 0;
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
}

.dashboard-content__grid-cadastros-resume-clientes_content-item__info
  > section
  > h5, .dashboard-content__grid-cadastros-resume-clientes_content-item__info
  > section
  > h5 > span {
  margin: 0;
  margin-top: 6px;
  font-weight: 700;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.87);
}

.dashboard-content__grid-cadastros-resume-clientes_content > section {
  background-color: rgba(0, 0, 0, 0.06);
  height: 1px;
  /* width: 100%; */
  margin-bottom: 24px;
  width: 259px;
}

/* RESUME */

.dashboard-content__grid-cadastros-resume > div:nth-child(2) {
  margin-top: 26px;
}

.dashboard-content__grid-cadastros-resume > div span {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  margin-top: 6px;
  color: #ffbd06;
}

.dashboard-content__grid-cadastros-resume > div p {
  color: rgba(0, 0, 0, 0.7);
}

.dashboard-content__grid-cadastros-resume > div svg path {
  fill: #000000;
  fill-opacity: 0.7;
  opacity: 0.7;
}

/*  */

.dashboard-content__grid-cadastros-resume {
  background-color: #fff;
  width: 300px;
  width: 307px;
  height: 615px;
  border: 1px solid var(--border-myne);
  /* margin-left: 10px; */
  border-radius: 8px;
  position: relative;
  padding: 22px;
  border: 1px solid rgba(0, 0, 0, 0.06);
}

.dashboard-content__grid-cadastros-resume > p {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 700;
  margin: 0;
}

.dashboard-content__grid-cadastros-resume > h5 {
  margin: 54px auto;
  display: flex;
  width: 213px;
  justify-content: center;
  align-items: center;
  padding: 2px 6px;
  background: rgba(0, 0, 0, 0.87);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.161);
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.87);
}

.dashboard-content__grid-cadastros-resume > svg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

/**/

.dashboard-content__grid-vendas__vendas-categories {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 17px;
  padding-right: 17px;
  padding-bottom: 12px;
}

.dashboard-content__grid-vendas__clientes {
  display: flex;
}

.dashboard-content__grid-vendas__clientes
  .dashboard-content__grid-vendas__vendas-items {
  overflow-y: initial;
}

.dashboard-content__grid-vendas {
  background-color: #fff;
  box-shadow: var(--shadow-web);
  /* height: fit-content; */
  /* padding-bottom: 15px; */
  flex: 0.9 1;
  width: 839px;
  height: 615px;
  /* max-height: 495px; */
  padding-left: 5px;
  border-radius: 8px;
  position: relative;
}

/**/

.dashboard-content__grid-vendas__header {
  display: flex;
  align-items: center;
  padding: 8px 5px;
  padding-bottom: 25px;
}

.dashboard-content__grid-vendas__header > div {
  display: flex;
  align-items: center;
  height: 80px;
  flex: 1 1;
  margin: 0 4px;
  justify-content: center;
  border-radius: 10px;
  padding: 10px;
}

.dashboard-content__grid-vendas__header > section {
  display: flex;
  align-items: center;
  height: 80px;
  flex: 2 1;
  margin: 0 4px;
  justify-content: center;
  border-radius: 10px;
}

/**/
.myne-dashboard .tns-outer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  position: relative;
  flex-direction: column-reverse;
}
.myne-dashboard .tns-outer > button,
.myne-dashboard .tns-controls > button {
  display: none;
}

.tns-nav {
  position: absolute;
  bottom: -22px;
  right: 0;
  left: 0;
}

.nice-dates-day_month {
  color: #999;
  font-size: 10px;
  left: 0;
  line-height: 1;
  position: absolute;
  right: 0;
  text-transform: capitalize;
  top: 7%;
  z-index: 3;
}

.tns-nav > button {
  width: 8px;
  margin: 0 4px;
  height: 8px;
  border: none;
  outline: initial;
  background: rgba(170, 0, 255, 0.36);
  border-radius: 100%;
}

.tns-nav > button.tns-nav-active {
  background: var(--roxo-myne);
}
/**/

.dashboard-content__grid-vendas__header-item-banner {
  padding: 0;
}

.dashboard-content__grid-vendas__header-item-banner svg {
  width: 100%;
}

.dashboard-content__grid-vendas__header-item-2 {
  background-color: #ffbd06;
}

.dashboard-content__grid-vendas__header-item-2 > p {
  font-size: 11px;
  color: #000000;
  opacity: 0.87;
  font-weight: 500;
}

.dashboard-content__grid-vendas__header-item-2 > button {
  font-weight: 500;
  font-size: 10.5px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--roxo-myne);
  border-radius: 30px;
  border: none;
  outline: initial;
  width: 110px;
  height: 24px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.dashboard-content__grid-vendas__button svg {
  width: 15px;
  height: 14px;
  max-width: 15px;
  max-height: 14px;
  min-width: 15px;
  min-height: 14px;
}

.dashboard-content__grid-vendas__button > p {
  position: static;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #ffffff;
  white-space: nowrap;
  margin-left: 12px;
}

.dashboard-content__grid-vendas__not-vendas {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 420px;
  flex: 1 1;
}

.dashboard-content__grid-vendas__not-vendas > p {
  margin: 20px;
  width: 250px;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 21px;
  text-align: center;
  color: #000000;
  opacity: 0.87;
}

/**/

.dashboard-content__grid-vendas__vendas {
  display: flex;
  flex-direction: column;
  height: 605px;
  /* height: 605px; */
  flex: 1 1;
  overflow-y: auto;
  -webkit-animation: init 0.4s ease;
          animation: init 0.4s ease;
}

/* .dashboard-content__grid-vendas__vendas > * {
  animation: init 0.2s ease;
} */

@-webkit-keyframes init {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes init {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.dashboard-content__grid-vendas__vendas-header {
  display: flex;
  justify-content: space-between;
  padding: 17px 22px 10px 17px;
}

.dashboard-content__grid-vendas__vendas-header > p {
  color: rgba(0, 0, 0, 0.6);
  font-size: 13px;
}

.dashboard-content__grid-vendas__vendas-header > div {
  display: flex;
  align-items: center;
}

.dashboard-content__grid-vendas__vendas-header svg {
  cursor: pointer;
  margin-left: 15px;
  width: 18px;
  height: 18px;
  transition: all 0.3s ease;
}

.dashboard-content__grid-vendas__vendas-header svg path {
  fill: rgba(0, 0, 0, 0.6);
  fill-opacity: 1 !important;
}

.dashboard-content__grid-vendas__vendas-header svg:hover {
  opacity: 0.6;
}

.dashboard-content__grid-vendas__vendas-header--search {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 5px;
}

.dashboard-content__grid-vendas__vendas-header--search > svg {
  margin-left: 0;
  margin-right: 20px;
  width: 18px;
  height: 18px;
  max-width: 18px;
  min-width: 18px;
  max-height: 18px;
  min-height: 18px;
}

.dashboard-content__grid-vendas__vendas-header--search > svg path {
  fill: rgba(0, 0, 0, 0.6);
  opacity: 1 !important;
}

.dashboard-content__grid-vendas__vendas-header--search > input {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  padding-bottom: 8px;
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
  transition: all 0.1s ease-in-out;
  border: none;
  outline: none;
  border-bottom: 1px solid #c5c5c5;
}

.dashboard-content__grid-vendas__vendas-header--search > input:hover {
  border-bottom: 1px solid #8f8f8f;
}

.dashboard-content__grid-vendas__vendas-header--search > input:focus {
  border-bottom: 2px solid var(--roxo-myne);
}

.dashboard-content__grid-vendas__vendas-items {
  padding-left: 17px;
  padding-right: 17px;
  max-height: 605px;
  padding-top: 0px;
  padding-bottom: 80px;
  overflow-y: auto;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.06);
}
::-webkit-scrollbar-thumb {
  background-color: var(--roxo-myne-36);
  border-radius: 25px;
}

.dashboard-content__grid-vendas__vendas-bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 120px;
  z-index: 1;
  /*height: 80px;
  */ /*background: linear-gradient(rgba(255, 255, 255, 0), #fff);
  */
  background: linear-gradient(
    360deg,
    #ffffff 34.74%,
    rgba(255, 255, 255, 0) 122.84%
  );
  /* border-radius: 0 0 8px 8px; */
  /* -webkit-border-radius: 0 0 8px 8px;
  -moz-border-radius: 0 0 8px 8px;
  -ms-border-radius: 0 0 8px 8px;
  -o-border-radius: 0 0 8px 8px; */
}

/**/

.dashboard-content__grid-flex {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  /* margin-left: 10px; */
}

.dashboard-content__grid-flex__money {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}

.dashboard-content__grid-flex__money > section {
  flex: 2 1;
  background-color: #fff;
  border-radius: 8px;
  height: 130px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  border: 1px solid var(--border-myne);
}

.dashboard-content__grid-flex__money > section > .dashboard-hand-money {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 80px;
  height: 83px;
  border-radius: 8px;
}
.dashboard-content__grid-flex__money > section > div {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 95px;
  padding: 6px 0;
}

.dashboard-content__grid-flex__money > section > div > p {
  color: rgba(0, 0, 0, 0.6);
  font-size: 13px;
}

.dashboard-content__grid-flex__money > section > div > h5 {
  color: #000000;
  opacity: 0.87;
  font-weight: 500;
  font-size: 14.5px;
}

.dashboard-content__grid-flex__money > div {
  flex: 1.6 1;
  display: flex;
  /* margin-left: 10px; */
  background-color: #fff;
  border-radius: 8px;
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid var(--border-myne);
}

.dashboard-content__grid-flex__money > div > img {
  width: 80px;
  margin-left: 25px;
  margin-right: 10px;
  border-radius: 8px;
}

.dashboard-content__grid-flex__money > div > div {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 20px;
  padding: 6px 0;
}

.dashboard-content__grid-flex__money > div > div > p {
  color: rgba(0, 0, 0, 0.6);
  font-size: 13.2px;
}

.dashboard-content__grid-flex__money > div > div > h5 {
  color: #000000;
  opacity: 0.87;
  font-weight: 500;
  margin-top: 2px;
  font-size: 15.2px;
}

/**/

.dashboard-content__grid-flex__compradores {
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--border-myne);
  background-color: #fff;
  /* margin: 10px 0; */
  padding: 22px 18px;
  flex-direction: column;
  display: flex;
}

.dashboard-content__grid-flex__compradores > div {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.dashboard-content__grid-flex__compradores > p {
  color: rgba(0, 0, 0, 0.6);
  font-size: 13px;
}

.dashboard-content__grid-flex__compradores > h5 {
  text-align: center;
  color: #000000;
  opacity: 0.87;
  font-weight: 500;
  font-size: 14.5px;
  width: 50%;
  margin: 0 auto;
  margin-top: 10px;
}

/**/

.dashboard-content__grid-flex__vendas {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.dashboard-content__grid-flex__vendas > div > section {
  flex: 1 1;
  width: 100%;
  /* overflow: auto; */
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 25px;
}

.myne-chartdiv * {
  transition: none !important;
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
}
.myne-chartdiv {
  transition: none !important;
}

.dashboard-content__grid-flex__vendas > div > section > div {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  width: 95%;
}

.dashboard-content__grid-flex__vendas > div > section > div > section {
  margin-top: 2px;
}

.dashboard-content__grid-flex__vendas > div > section > div p {
  width: 82px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: 0 5px;
  font-size: 13px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
}
/**/
/* .myne-dashboard g[id="id-323"] {
  fill: #A4A4FF;
  stroke: #A4A4FF;
}

.myne-dashboard g[id="id-343"] {
  fill: #F969AE;
  stroke: #F969AE;
}

.myne-dashboard g[id="id-360"] {
  fill: #F9C478;
  stroke: #F9C478;
}

.myne-dashboard g[id="id-377"] {
  fill: #E0FFB0;
  stroke: #E0FFB0;
}

.myne-dashboard g[id="id-246"] {
  fill: #2C25FE;
  stroke: #2C25FE;
}

.myne-dashboard g[id="id-266"] {
  fill: #FF0672;
  stroke: #FF0672;
}

.myne-dashboard g[id="id-283"] {
  fill: #F5AB2D;
  stroke: #F5AB2D;
}

.myne-dashboard g[id="id-300"] {
  fill: #BDEE7C;
  stroke: #BDEE7C;
}

.myne-dashboard g[id="id-400"] {
  fill: #5D5AA7;
  stroke: #5D5AA7;
}

.myne-dashboard g[id="id-420"] {
  fill: #C96691;
  stroke: #C96691;
}

.myne-dashboard g[id="id-437"] {
  fill: #D6A553;
  stroke: #D6A553;
}

.myne-dashboard g[id="id-454"] {
  fill: #B3DF78;
  stroke: #B3DF78;
} */
/**/

.amcharts-AxisLabelCircular text {
  display: none;
}

.amcharts-PieTick {
  display: none;
}

.amcharts-AmChartsLogo-group,
g[aria-labelledby] {
  display: none;
}

.dashboard-content__grid-flex__vendas > div > section > div svg {
  width: 9px;
  height: 9px;
}

#chartdiv {
  width: 160px;
  height: 160px;
  margin: 0;
}

.dashboard-content__grid-flex__vendas > div > section > section {
  width: 100%;
  display: flex;
  padding: 8px;
  margin-top: auto;
  align-items: flex-end;
  justify-content: flex-end;
}

.dashboard-content__grid-flex__vendas > div > section > section > button {
  border: 1px solid rgba(0, 0, 0, 0.06);
  padding: 3px 14px;
  color: rgba(0, 0, 0, 0.87);
  background-color: #fff;
  border-radius: 20px;
  font-size: 12px;
  outline: initial;
  transition: all 0.3s ease;
  cursor: pointer;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.dashboard-content__grid-flex__vendas
  > div
  > section
  > section
  > button:active {
  background-color: var(--border-myne);
  -webkit-transform: scale(0.97);
          transform: scale(0.97);
}

.dashboard-content__grid-flex__vendas > div > section > section > button:hover {
  background-color: var(--border-myne);
}

.dashboard-content__grid-flex__vendas > div > section > div section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboard-content__grid-flex__vendas > div > section > div section > div {
  display: flex;
  align-items: center;
}

.dashboard-content__grid-flex__vendas > div > section h5 {
  color: rgba(0, 0, 0, 0.7);
  font-size: 13px;
  font-weight: 500;
  opacity: 0.6;
  margin-left: auto;
}

.dashboard-content__grid-flex__vendas > div {
  width: 50%;
  /* height: 355px; */
  height: 475px;
  display: flex;
  padding: 4px;
  background-color: #fff;
  border-radius: 8px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 1px solid var(--border-myne);
}

.dashboard-content__grid-flex__vendas > div > p {
  color: rgba(0, 0, 0, 0.6);
  font-size: 13px;
  text-align: left;
  position: absolute;
  top: 10.5px;
  left: 10.5px;
  width: 100%;
}

.dashboard-content__grid-flex__vendas > div > svg {
  margin-bottom: 16px;
  width: 23px;
  height: 24.05px;
}

.dashboard-content__grid-flex__vendas > div > svg path {
  fill-opacity: 0.36;
}

.dashboard-content__grid-flex__vendas > div > div {
  justify-content: center;
  align-items: center;
  display: flex;
}

.dashboard-content__grid-flex__vendas > div > div > p {
  margin: auto 24px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  opacity: 0.87;
}

/* INFO */

.dashboard-content__grid-cadastros__info {
  display: flex;
  margin-top: 24px;
}

.dashboard-content__grid-cadastros__info > svg {
  max-width: 20px;
  min-width: 20px;
  width: 20px;
  max-height: 20px;
  min-height: 20px;
  height: 20px;
}

.dashboard-content__grid-cadastros__info > p {
  font-weight: 400;
  margin-top: -2px;
  font-size: 12px;
  width: 200px;
  color: rgba(0, 0, 0, 0.6);
  margin-left: 16px;
}

.myne-item__cadastro-cliente {
  display: flex;
  flex-direction: column;
  padding: 16px;
  position: relative;
  width: 233px;
  height: 138px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 16px;
  margin: 6px;
  cursor: pointer;
}

.myne-item__cadastro-cliente:hover {
  background-color: rgb(250, 250, 250);
  /* background-color: rgba(250, 249, 249, 0.87); */
  border: 1px solid rgba(0, 0, 0, 0.36);
}

.myne-item__cadastro-cliente-active {
  background: #faefff !important;
  border: 1px solid var(--roxo-myne) !important;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12) !important;
}

.myne-item__cadastro-cliente-base {
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
}
/*  */
.myne-item__cadastro-cliente-base > .myne-modal__modal-sucess-publicate_copy {
  position: absolute;
  right: 13px;
  width: 13px;
  top: 23px;
  right: 23px;
  height: 13px;
  max-width: 13px;
  min-height: 13px;
  min-width: 13px;
  max-height: 13px;
  cursor: pointer;
  z-index: 1;
}

.myne-item__cadastro-cliente-base
  > .myne-modal__modal-sucess-publicate_copy:nth-child(1) {
  margin-right: 30px;
}

.myne-item__cadastro-cliente-base-icon:hover path {
  fill-opacity: 0.8;
}

/*  */

.dashboard-content__grid-vendas__clientes
  .dashboard-content__grid-vendas__vendas-items {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: -6px;
  margin-top: -6px;
  max-height: initial;
}

.myne-item__cadastro-cliente__user {
  display: flex;
  align-items: center;
}

.myne-item__cadastro-cliente__user > img {
  width: 35px;
  height: 35px;
  object-fit: cover;
}

.myne-item__cadastro-cliente__user > p {
  margin: 0;
  margin-left: 8px;
  font-weight: 600;
  font-size: 12.5px;
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.87;
  max-width: 115px;
}

.myne-item__cadastro-cliente__infos {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}

.myne-item__cadastro-cliente__infos > section {
  display: flex;
  align-items: center;
}

.myne-item__cadastro-cliente__infos > section:nth-child(2) {
  margin-top: 11px;
}

.myne-item__cadastro-cliente__infos > section > svg {
  width: 17px;
  height: 17px;
  max-width: 17px;
  min-height: 17px;
  min-width: 17px;
  max-height: 17px;
}

.myne-item__cadastro-cliente__infos > section > p {
  font-weight: 500;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.87);
  margin: 0;
  margin-left: 10px;
}

/*  */

.dashboard-content__grid-cadastros__not-itens {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dashboard-content__grid-cadastros__not-itens > svg {
  width: 25px;
  height: 25px;
}

.dashboard-content__grid-cadastros__not-itens > p {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 13px;
  margin: 9px 30px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
}

/**/

@media (max-width: 1314px) {
  .dashboard-content__grid-vendas__header > div {
    width: 150px;
  }
  .dashboard-content__grid-vendas__header-item-1 > div > p {
    font-size: 12px;
  }
  .dashboard-content__grid-vendas__header-item-1 > div > h5 {
    font-size: 14px;
    margin-top: 2px;
  }
  .dashboard-content__grid-vendas__header-item-2 > p {
    font-size: 10px;
  }
  .dashboard-content__grid-flex__vendas > div > p {
    color: rgba(0, 0, 0, 0.6);
    font-size: 11px;
    top: 10px;
    left: 10px;
  }
  .dashboard-content__grid-flex__money > div > div > p {
    font-size: 11px;
  }
  .dashboard-content__grid-flex__compradores > p {
    font-size: 10px;
  }
  .dashboard-content__grid-flex__money > section > div > p {
    font-size: 10px;
  }
  .dashboard-content__grid-flex__compradores > h5 {
    font-size: 13px;
  }
  .dashboard-content__grid-flex__vendas > div > div > p {
    font-size: 12px;
  }
  .dashboard-content__grid-flex__money > section > div > h5 {
    color: #000000;
    opacity: 0.87;
    font-weight: 500;
    font-size: 13.5px;
  }
  .myne-button__sidebar {
    padding: 25px 0;
  }
}

@media (max-width: 1314px) {
  .dashboard-content__grid-flex__vendas > div > section > div p {
    width: 70px;
    margin: 0 5px;
    font-size: 12px;
  }
  .dashboard-content__grid-flex__vendas > div > section h5 {
    color: rgba(0, 0, 0, 0.7);
    font-size: 12px;
    font-weight: 500;
    opacity: 0.6;
    margin-left: auto;
  }
}

/* @media (max-width: 1314px) { */
@media (max-width: 1336px) {
  .dashboard-content__grid-vendas {
    width: 100%;
  }
  .dashboard-content__grid {
    flex-direction: column;
  }
  .dashboard-content__grid-flex {
    margin-left: 0px;
    /* margin-top: 12px; */
  }
  .dashboard-content__grid-cadastros-resume {
    margin-left: 0px;
    /* margin-top: 12px; */
    width: 100%;
  }
}

@media (max-width: 952px) {
  .dashboard-content__grid-vendas__header > div {
    width: 160px;
  }
}

/* modal alert */

.myne-modal__modal-add-item__alert {
  width: 300px;
  height: 223px;
  display: flex;
  padding: 24px;
  background: #ffffff;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
}

.myne-modal__modal-add-item-content__alert {
  display: flex;
  flex-direction: column;
}

.myne-modal__modal-add-item-content__alert > section {
  display: flex;
}

.myne-modal__modal-add-item-content__alert > section > svg {
  max-width: 20px;
  max-height: 20px;
  min-width: 20px;
  min-height: 20px;
}

.myne-modal__modal-add-item-content__alert > section > div {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
}

.myne-modal__modal-add-item-content__alert > section > div > h5 {
  font-weight: 700;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 8px;
  margin-top: -2.2px;
}

.myne-modal__modal-add-item-content__alert > section > div > p {
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
}

.myne-modal__modal-add-item-content__alert > button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 32px;
  background: var(--roxo-myne);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  border-radius: 124px;
  margin: 0px 4px;
  width: 121px;
  height: 44px;
  margin-top: auto;
  margin-left: auto;
  cursor: pointer;
  transition: all 0.3s ease;
  border: none;
  outline: initial;
  font-size: 14px;
  color: #ffffff;
}

.myne-modal__modal-add-item-content__alert > button:hover {
  opacity: 0.7;
}

.myne-opcoes {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  position: relative;
  overflow: hidden;
}

.myne-opcoes-container {
  padding: 24px 36px;
  overflow-y: auto;
}

.myne-opcoes-container > p {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: rgba(0, 0, 0, 0.87);
}

/* ITENS */

.myne-opcoes__itens {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 8px;
}

.myne-opcoes__options {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.myne-opcoes__options > p {
  margin-bottom: 16px;
  line-height: normal;
}

/* MODAL */

.myne-modal__modal-moeda {
  -webkit-filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.12));
          filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.12));
}

.myne-modal__modal-moeda
  .myne-modal__modal-new-sale-content__area-modal-search__itens--item-moeda {
  margin-bottom: 24px;
  width: 312px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.myne-modal__modal-moeda
  .myne-modal__modal-new-sale-content__area-modal-search__itens--item-moeda
  > div {
  display: flex;
  cursor: pointer;
  flex-direction: column;
}

.myne-modal__modal-moeda
  .myne-modal__modal-new-sale-content__area-modal-search__itens--item-moeda
  > div
  > p {
  margin: 0;
  font-weight: 600;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.87;
}

.myne-modal__modal-moeda
  .myne-modal__modal-new-sale-content__area-modal-search__itens--item-moeda
  > div
  > span {
  margin-top: 3px;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  opacity: 0.87;
}

/* ITEM */

.myne-opcoes__component-item-config {
  transition: all 0.3s ease !important;
  -webkit-animation: initopmore 0.3s ease !important;
          animation: initopmore 0.3s ease !important;
}
.myne-opcoes__component-item-categories {
  transition: all 0.4s ease !important;
  -webkit-animation: initopmore 0.4s ease !important;
          animation: initopmore 0.4s ease !important;
}

.myne-opcoes__component-item {
  display: flex;
  transition: all 0.5s ease;
  -webkit-animation: initopmore 0.5s ease;
          animation: initopmore 0.5s ease;
  flex-direction: column;
  width: 259px;
  height: 90px;
  background: #ffffff;
  cursor: pointer;
  margin-bottom: 24px;
  padding: 16px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 16px;
  margin-right: 24px;
  padding-right: 10px;
  position: relative;
  justify-content: space-between;
}

.myne-opcoes__component-item > section {
  display: flex;
  align-items: center;
}

.myne-opcoes__component-item > svg {
  position: absolute;
  top: 16px;
  right: 16px;
}

.myne-opcoes__component-item > section > p {
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.87;
}

.myne-opcoes__component-item > section > svg {
  margin-right: 8px;
}

.myne-opcoes__component-item > div {
  display: flex;
  align-items: center;
  max-width: 100%;
}

.myne-opcoes__component-item > div > p {
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  min-width: 143px;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 12px;
  color: rgba(0, 0, 0, 0.87);
}

.myne-opcoes__component-item > div > span {
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.6);
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.myne-opcoes__component-item-plus {
  background: #58cc02;
  border: 1px solid rgba(0, 0, 0, 0.06);
  transition: all 0.9s ease;
  -webkit-animation: initopmore 0.9s ease;
          animation: initopmore 0.9s ease;
}
.myne-opcoes__component-item-plus > div > p {
  font-weight: 700;
  color: #ffffff;
}

.myne-opcoes__component-item-catalogo {
  background: #aa00ff;
  border: 1px solid rgba(0, 0, 0, 0.06);
  transition: all 1.3s ease;
  -webkit-animation: initopmore 1.3s ease;
          animation: initopmore 1.3s ease;
}
.myne-opcoes__component-item-catalogo > div > p {
  font-weight: 700;
  color: #ffffff;
}

.myne-opcoes__component-item:hover {
  border: 1px solid rgba(0, 0, 0, 0.36);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
}
/* INFO */

.myne-info-page {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.myne-info-page > p {
  font-weight: 400;
  font-size: 22px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.87;
}

.myne-info-page > svg {
  margin: 20px 0px;
}

/* Modal */

.myne-modal__modal-info-card {
  display: flex;
  align-items: flex;
  padding: 16px;
  width: 172px;
  height: 248px;
  background: #f5f5f5;
  border-radius: 8px;
}

.myne-modal__modal-info-card > svg {
  min-width: 20px;
  min-height: 20px;
}

.myne-modal__modal-info-card > p {
  width: 100px;
  height: 216px;
  margin: 0;
  margin-left: 16px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.87);
}

.input-form-whatsapp {
  display: flex;
  align-items: center;
  position: relative;
}

.input-form-whatsapp > p {
  border: none;
  z-index: 1;
  top: -10px;
}

.input-form-whatsapp__digit {
  display: flex;
  align-items: center;
  padding-top: 27px;
  padding-bottom: 25px;
  min-width: 92px;
  margin-right: 12px;
  height: 37px;
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}
.input-form-whatsapp__digit:hover {
  background-color: var(--background_hover);
}
.input-form-whatsapp__digit > div {
  display: flex;
  align-items: center;
}
.input-form-whatsapp__digit > div > img {
  width: 20px;
  height: 20px;
}
.input-form-whatsapp__digit > div > p {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  margin: 0;
  margin-left: 8px;
  color: #000000;
}

.input-form-whatsapp__digit > svg {
  margin-left: auto;
}

.myne-modal__modal-edit-cadastro-content__inputs > section,
.myne-modal__modal-accept-terms__info {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  width: 342px;
  height: 64px;
}

.myne-modal__modal-edit-cadastro-content__inputs > section > p,
.myne-modal__modal-accept-terms__info > p {
  margin: 0;
  margin-left: 15px;
  width: 191px;
  height: 48px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
}

.myne-modal__modal-accept-terms__info > p {
  width: 221px;
  margin-left: 24px;
  height: 48px;
}

/*  */
/*  */

.myne-modal__modal-new-sale-content__area-modal-search__itens--item-moeda
  > section {
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.myne-modal__modal-new-sale-content__area-modal-search__itens--item-moeda
  > section
  > img {
  margin-right: 8px;
}

.myne-modal__modal-new-sale-content__area-modal-search__itens--item-moeda
  > section
  > div {
  display: flex;
  flex-direction: column;
}

.myne-modal__modal-new-sale-content__area-modal-search__itens--item-moeda
  > section
  > div
  > p {
  margin: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.87;
}

.myne-modal__modal-new-sale-content__area-modal-search__itens--item-moeda
  > section
  > div
  > span {
  margin-top: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.6);
  opacity: 0.87;
}

/* TERMS MODAL */

.myne-modal__modal-accept-terms {
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: 100%;
}

.myne-modal__modal-accept-terms__info {
  width: 100%;
  margin: 0;
}

.myne-modal__modal-accept-terms_checks {
  margin-top: 32px;
  margin-bottom: 30px;
}

.myne-modal__modal-accept-terms_checks > section {
  display: flex;
  margin-bottom: 20px;
}

.myne-modal__modal-accept-terms_checks > section:nth-child(4) {
  margin-bottom: 0px;
}

.myne-modal__modal-accept-terms_checks > section > svg {
  margin-right: 12px;
  height: 24px;
}

.myne-modal__modal-accept-terms_checks > section > p {
  width: 527px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.87);
  margin: 0;
}

.myne-modal__modal-accept-terms_checks > section > p > a {
  text-decoration: underline;
  color: var(--azul-myne);
}

.myne-modal__modal-accept-terms_btns {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  height: 56px;
}

.myne-modal__modal-accept-terms_btns > div {
  display: flex;
  align-items: center;
  padding: 16px;
  width: 307px;
  height: 56px;
  background: #faefff;
  cursor: pointer;
  border-radius: 8px;
}

.myne-modal__modal-accept-terms_btns > div:hover {
  opacity: 0.8;
}

.myne-modal__modal-accept-terms_btns > div > p {
  margin: 0;
  margin-left: 16px;
  width: 235px;
  height: 18px;
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.87);
}

.myne-modal__modal-accept-terms_btns > button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 32px;
  width: 225px;
  height: 44px;
  background: #aa00ff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  border-radius: 124px;
  border: none;
  outline: initial;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
}

.myne-modal__modal-accept-terms_btns > button:disabled {
  background: rgba(0, 0, 0, 0.06);
  box-shadow: initial;
  color: rgba(0, 0, 0, 0.36);
  cursor: auto;
}

.myne-modal__modal-accept-terms_btns > button:hover {
  opacity: 0.8;
}
.myne-modal__modal-accept-terms_btns > button:active {
  -webkit-transform: scale(0.98);
          transform: scale(0.98);
}

/* MODAL SUCESS */

.myne-modal__modal-sucess-publicate {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  justify-content: center;
}

.myne-modal__modal-sucess-publicate > div button {
  padding: 10px 32px !important;
  height: initial !important;
  width: initial !important;
  margin-top: 24px !important;
  font-size: 14px !important;
}

.myne-modal__modal-sucess-publicate > img {
  width: 100px;
  height: 100px;
  border-radius: 134px;
  object-fit: cover;
  -webkit-transform: scale(1.77);
          transform: scale(1.77);
  z-index: -1;
}

.myne-modal__modal-sucess-publicate > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
}

.myne-modal__modal-sucess-publicate > div > p {
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.76);
  margin: 0;
}

.myne-modal__modal-sucess-publicate > div > span {
  margin: 0;
  margin-top: 12px;
  line-height: 20px;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
}

.myne-modal__modal-sucess-publicate > div > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  width: 328px;
  height: 56px;
  background: #faefff;
  border-radius: 8px;
  margin-top: 24px;
}

.myne-modal__modal-sucess-publicate > div > div > p {
  width: 256px;
  max-width: 256px;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 21px;
  font-weight: 700;
  font-size: 14px;
  margin: 0;
  color: rgba(0, 0, 0, 0.87);
}

.myne-btn-border {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 32px;
  width: 337px;
  height: 44px;
  margin-top: 46px;
  background: rgba(255, 255, 255, 0.87);
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 124px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.87);
  cursor: pointer;
}

.myne-btn-border > svg {
  margin-right: 13px;
}

.myne-btn-border:hover {
  background-color: var(--background_hover);
}

.myne-btn-border:active {
  -webkit-transform: scale(0.98);
          transform: scale(0.98);
}

.myne-modal__modal-sucess-publicate-sucess-stop {
  transform: scale(1.4) !important;
  transition: all 0.3s ease;
  -webkit-transform: scale(1.4) !important;
  -moz-transform: scale(1.4) !important;
  -ms-transform: scale(1.4) !important;
  -o-transform: scale(1.4) !important;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

/* COPY tooltip */

.myne-modal__modal-sucess-publicate_copy {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  position: relative;
}
.myne-modal__modal-sucess-publicate_copy > div svg {
  cursor: pointer;
}

.myne-modal__modal-sucess-publicate_copy-tooltip {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  padding: 16px;
  min-width: 124px;
  height: 53px;
  background: rgba(0, 0, 0, 0.87);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.161);
  border-radius: 16px;
  bottom: 35px;
  font-weight: 300;
  font-size: 13.5px;
  text-align: center;
  line-height: 21px;
  color: rgba(255, 255, 255, 0.87);
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
}

.myne-modal__modal-sucess-publicate_copy-tooltip svg {
  bottom: -6px;
  position: absolute;
}

/*  */

.myne-text {
  margin: 0;
  color: var(--preto-87);
}

.myne-icon-button {
}

.myne-icon-button > svg {
  width: 18px;
  height: 18px;
}

.myne-main {
  height: 100%;
  width: 100%;
  position: fixed;
  background-color: var(--background);
  display: flex;
}

/**/

.myne-bigsSales__item {
  display: flex;
  align-items: center;
  flex: 1 1;
}

.myne-bigsSales__item > img {
  width: 35px;
  height: 35px;
  border-radius: 100%;
  object-fit: cover;
  margin-right: 7px;
}

.myne-bigsSales__item > p {
  font-size: 15px;
  color: #000000;
  opacity: 0.87;
  width: 115px;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 500;
  text-overflow: ellipsis;
}

/* loading */

.myne-loading-in-modal {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.myne-modal-loading {
  position: absolute;
  z-index: 999;
  opacity: 0.7;
  border-radius: 8px;
  overflow: hidden;
}

.myne-loadingn {
  height: 100%;
  right: 0;
  left: 255px;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 999;
}

.myne-loading-sale {
  height: 100px;
  margin-top: -10px;
  margin-bottom: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 999;
}
.myne-loading-btn {
  height: 1.5px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 43px;
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
}

.myne-loadingn > .myne-circle-loading {
  top: 50%;
}

.myne-loading {
  height: 100%;
  width: 100%;
  position: fixed;
  background-color: var(--background);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.myne-loading > img {
  width: 250px;
  margin-top: 9%;
}

.myne-circle-loading {
  width: 90px;
  height: 90px;
  position: absolute;
  top: 75%;
  left: 50%;
  background: transparent;
  border: 7px solid rgba(255, 255, 255, 0.3);
  border-radius: 100%;
  border-top: 7px solid var(--roxo-myne);
  transition: all 0.3s ease;
  -webkit-animation: animate 0.9s infinite linear;
          animation: animate 0.9s infinite linear;
}

.myne-circle-loading-2 {
  width: 80px;
  height: 80px;
  position: absolute;
  top: 50%;
  left: 50%;
  background: transparent;
  border: 7px solid rgba(255, 255, 255, 0.3);
  border-radius: 100%;
  border-top: 7px solid var(--roxo-myne);
  transition: all 0.3s ease;
  -webkit-animation: animate 0.9s infinite linear;
          animation: animate 0.9s infinite linear;
}

.myne-modal__modal-new-sale-content-loading {
  display: flex;
  width: 100%;
  margin-top: 90px;
  align-items: center;
  position: relative;
  justify-content: center;
}

.myne-circle-loading-3 {
  width: 60px;
  height: 60px;
  top: 50%;
  left: 50%;
  position: absolute;
  background: transparent;
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 100%;
  border-top: 4px solid var(--roxo-myne);
  transition: all 0.3s ease;
  -webkit-animation: animate 0.9s infinite linear;
          animation: animate 0.9s infinite linear;
}

@-webkit-keyframes animate {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
            transform: translate(-50%, -50%) rotate(0deg);
  }
  50% {
    -webkit-transform: translate(-50%, -50%) rotate(180deg);
            transform: translate(-50%, -50%) rotate(180deg);
    border-top: 4px solid var(--azul-myne);
  }
  50% {
    -webkit-transform: translate(-50%, -50%) rotate(230deg);
            transform: translate(-50%, -50%) rotate(230deg);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
            transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes animate {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
            transform: translate(-50%, -50%) rotate(0deg);
  }
  50% {
    -webkit-transform: translate(-50%, -50%) rotate(180deg);
            transform: translate(-50%, -50%) rotate(180deg);
    border-top: 4px solid var(--azul-myne);
  }
  50% {
    -webkit-transform: translate(-50%, -50%) rotate(230deg);
            transform: translate(-50%, -50%) rotate(230deg);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
            transform: translate(-50%, -50%) rotate(360deg);
  }
}

.myne-circle-loading-4 {
  width: 60px;
  height: 60px;
  top: 50%;
  left: 50%;
  position: absolute;
  background: transparent;
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 100%;
  border-top: 4px solid #fff;
  transition: all 0.3s ease;
  -webkit-animation: animate2 0.9s infinite linear;
          animation: animate2 0.9s infinite linear;
}

@-webkit-keyframes animate2 {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
            transform: translate(-50%, -50%) rotate(0deg);
  }
  50% {
    -webkit-transform: translate(-50%, -50%) rotate(180deg);
            transform: translate(-50%, -50%) rotate(180deg);
    border-top: 4px solid #fff;
  }
  50% {
    -webkit-transform: translate(-50%, -50%) rotate(230deg);
            transform: translate(-50%, -50%) rotate(230deg);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
            transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes animate2 {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
            transform: translate(-50%, -50%) rotate(0deg);
  }
  50% {
    -webkit-transform: translate(-50%, -50%) rotate(180deg);
            transform: translate(-50%, -50%) rotate(180deg);
    border-top: 4px solid #fff;
  }
  50% {
    -webkit-transform: translate(-50%, -50%) rotate(230deg);
            transform: translate(-50%, -50%) rotate(230deg);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
            transform: translate(-50%, -50%) rotate(360deg);
  }
}

/**/

.myne-bottom-border {
  border-bottom: 1px solid var(--border-myne);
}

.myne-top-border {
  border-top: 1px solid var(--border-myne);
}

/**/

.myne-user-1 {
  display: flex;
  align-items: center;
  width: 85%;
  margin: 22px 20px;
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  border-radius: 100px;
  padding: 8px 11px;
}

.myne-user-1 img {
  width: 39px;
  height: 39px;
  object-fit: cover;
  border-radius: 100%;
  margin-right: 14px;
}

.myne-user-1 > div {
  display: flex;
  flex-direction: column;
  max-width: 145px;
}

.myne-user-1 > div > p {
  font-weight: 500;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.87);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.myne-user-1 > div > span {
  font-weight: 500;
  font-size: 11px;
  color: rgba(0, 0, 0, 0.6);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/**/

.myne-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.35);
  width: 100%;
  height: 100%;
  z-index: 999999;
  transition: all 0.3s ease;
}

.modal-message {
  display: flex;
  flex-direction: column;
  width: 456px;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  height: 456px;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 50px;
  background-color: #fff;
}

.modal-message > p {
  text-align: center;
  font-size: 18px;
  margin-top: 30px;
  font-weight: 400;
  color: var(--preto-87);
}

.modal-message > img {
  width: 185px;
}

.modal-message > button {
  width: 114px;
  height: 40px;
  max-height: 40px;
  min-height: 40px;
  margin-bottom: 20px;
  background-color: var(--roxo-myne);
  color: #fff;
  font-weight: 500;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  border-radius: 50px;
  font-size: 14px;
  border: none;
  transition: all 0.3s ease;
  outline: initial;
  text-transform: capitalize;
}

.modal-message > button:hover {
  opacity: 0.9;
}

/**/

.myne-modal__modal-sales {
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 430px;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  z-index: 1;
  overflow: hidden;
  transition: all 0.3s ease;
  background-color: #fff;
}

.modal-sales__header {
  display: flex;
  align-items: center;
  padding: 23px;
  justify-content: space-between;
  border-bottom: 1px solid #ebeff2;
}

.modal-sales__header > section {
  display: flex;
  align-items: center;
}

.modal-sales__header > section > p {
  font-size: 13.5px;
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  width: 88%;
  margin-left: 15px;
  align-items: center;
  color: rgba(0, 0, 0, 0.6);
}

.modal-sales__header > svg {
  cursor: pointer;
}

.modal-sales__items {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
}

.modal-sales__items > div {
  padding: 11px 15px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f2f2f2;
}

.modal-sales__items > div > section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.modal-sales__items > div > section > div {
  align-items: center;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.modal-sales__items > div > section > div > p {
  font-size: 15px;
  white-space: nowrap;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.87);
  width: 100%;
  text-overflow: ellipsis;
  max-width: 250px;
}

.modal-sales__items > div > section > div > h5 {
  font-size: 14px;
  display: flex;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  align-items: center;
  color: rgba(0, 0, 0, 0.61);
}

/**/
.modal-sales__items > div > section > section {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  justify-content: flex-end;
}

.modal-sales__items > div > section > section > p {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
  text-align: right;
}

.modal-sales__items > div > section > section > h5 {
  font-size: 14px;
  font-weight: 400;
  text-align: right;
  color: #58cc02;
}
/**/

.modal-sales__items > div > img {
  width: 39px;
  max-width: 39px;
  min-width: 39px;
  height: 39px;
  border-radius: 5px;
  margin-right: 15px;
  object-fit: cover;
}

/**/

.myne-item__venda_clinte-vendas {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  margin-bottom: 16px;
  width: 259px;
  max-width: 259px;
  min-width: 259px;
  height: 147px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 16px;
}

.myne-item__venda_clinte-vendas__infos {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.myne-item__venda_clinte-vendas__infos > div {
  /* margin-right: 13px; */
}

.myne-item__venda_clinte-vendas__infos > div > p {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 8px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  line-height: 12px;
  margin: 0;
  margin-bottom: 6px;

  color: rgba(0, 0, 0, 0.6);
}

.myne-item__venda_clinte-vendas__infos > div > p strong {
  color: rgba(0, 0, 0, 0.87);
  margin-left: 2px;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 8px;
}

.myne-item__venda_clinte-vendas__infos > div > h5 {
  font-family: "Poppins";
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.87);
}

.myne-item__venda_clinte-vendas__infos > section {
  flex-direction: column;
  align-items: flex-end;
}

.myne-item__venda_clinte-vendas__infos > section > div {
  margin-bottom: 6px;
  margin-right: 0;
}

.myne-item__venda_clinte-vendas > section {
  width: 100%;
  height: 1px;
  max-height: 1px;
  margin-top: 8px;
  min-height: 1px;
  background: rgba(0, 0, 0, 0.06);
}

.myne-item__venda_clinte-vendas__qty {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  width: 100%;
}

.myne-item__venda_clinte-vendas__qty > section {
  width: 1px;
  height: 24px;
  background: rgba(0, 0, 0, 0.06);
  margin: 0 17px;
}

.myne-item__venda_clinte-vendas__qty > div {
  display: flex;
  align-items: center;
}

.myne-item__venda_clinte-vendas__qty > div > p {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 12px;
  margin: 0;
  line-height: 18px;
  margin-left: 10px;
  color: rgba(0, 0, 0, 0.87);
}

/**/

.myne-item__venda {
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  height: 87px;
  max-height: 87px;
  min-height: 87px;
  display: flex;
  align-items: center;
  padding: 20px 13px;
  margin-bottom: 15px;
  min-width: 0;
  /* cursor: pointer; */
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
}

.myne-item__venda:hover {
  background-color: rgb(250, 250, 250);
  cursor: pointer;
  /* //background-color: rgba(250, 249, 249, 0.87);  */
  border: 1px solid rgba(0, 0, 0, 0.36);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
}

.myne-item__venda > p {
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
}

.myne-item__venda-user {
  display: flex;
  margin-right: 20px;
  width: auto;
  align-items: center;
}

.myne-item__venda-user > img {
  width: 38px;
  height: 38px;
  border-radius: 100%;
  margin-right: 8px;
  object-fit: cover;
}

.myne-item__venda-user > p {
  color: rgba(0, 0, 0, 0.87);
  font-size: 15px;
  font-weight: 500;
  white-space: nowrap;
  font-weight: 600;
  order: 1;
  flex: 0 1 auto;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100px;
}

.myne-item__venda-status {
  display: flex;
  align-items: center;
}

/*  */

.myne-modal__modal-edit-cadastro-content__info-sales {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.myne-modal__modal-edit-cadastro-content__info-sales > p {
  margin: 0;
  padding: 0;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  opacity: 0.87;
}

.myne-modal__modal-edit-cadastro-content__info-sales
  .myne-item__venda-status
  > div {
  height: 24px;
  padding: 3px 15px;
  font-size: 13.5px !important;
  border-radius: 30px;
}

/*  */

.myne-modal__modal-edit-cadastro-content__info-installments {
  display: flex;
  width: 100%;
  flex-direction: column;
  border-top: 1px dashed rgba(0, 0, 0, 0.06);
  margin-top: 12px;
}

.myne-modal__modal-edit-cadastro-content__info-installments-item {
  display: flex;
  align-items: center;
  padding: 5px 0;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.06);
}

.myne-modal__modal-edit-cadastro-content__info-installments-item > span {
  width: 14px;
  height: 18px;
  font-family: "Poppins";
  margin-right: 12px;
  font-weight: 400;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.36);
}

.myne-modal__modal-edit-cadastro-content__info-installments-item > p {
  width: 64px;
  height: 18px;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 13px;
  margin: 0;
  margin-right: 24px;
  color: rgba(0, 0, 0, 0.87);
}

.myne-modal__modal-edit-cadastro-content__info-installments-item > div {
  display: flex;
  align-items: center;
}
.myne-modal__modal-edit-cadastro-content__info-installments-item > div > p {
  margin: 0;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 13px;
  color: var(--roxo-myne);
  margin-right: 12px;
}
.myne-modal__modal-edit-cadastro-content__info-installments-item > div > svg {
  width: 14px;
  height: 14px;
}

.myne-modal__modal-edit-cadastro-content__info-installments-item > section {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 0;
}
.myne-modal__modal-edit-cadastro-content__info-installments-item
  .css-15va8ns-MuiSwitch-root {
  margin-right: -10px !important;
}
.myne-modal__modal-edit-cadastro-content__info-installments-item > section > p {
  margin: 0;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.87);
}

.myne-modal__modal-edit-cadastro-content__info-cadastros {
  display: flex;
  flex-direction: column;
}

.myne-modal__modal-edit-cadastro-content__info-cadastros > h5 {
  margin: 0;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 24px;
  margin-bottom: 12px;
}

.myne-modal__modal-edit-cadastro-content__info-cadastros-client {
  display: flex;
  align-items: center;
}

.myne-modal__modal-edit-cadastro-content__info-cadastros-client > img {
  width: 36px;
  height: 36px;
  object-fit: cover;
  margin-right: 8px;
}

.myne-modal__modal-edit-cadastro-content__info-cadastros-client > p {
  margin: 0;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.87;
}

.myne-modal__modal-edit-cadastro-content__info-cadastros-itens {
  display: flex;
  align-items: center;
  padding: 12px;
  width: 100%;
  height: 60px;
  border: 1px dashed rgba(0, 0, 0, 0.06);
  border-radius: 8px;
}

.myne-modal__modal-edit-cadastro-content__info-cadastros-itens > img {
  background: #f5f5f5;
  width: 36px;
  height: 36px;
  padding: 7px;
  margin-right: 8px;
  border-radius: 4px;
}

.myne-modal__modal-edit-cadastro-content__info-cadastros-itens > div {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.myne-modal__modal-edit-cadastro-content__info-cadastros-itens > div > p {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  margin: 0;
}

.myne-modal__modal-edit-cadastro-content__info-cadastros-itens > div > section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.myne-modal__modal-edit-cadastro-content__info-cadastros-itens
  > div
  > section
  > h5 {
  margin: 0;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.87);
}
.myne-modal__modal-edit-cadastro-content__info-cadastros-itens
  > div
  > section
  > p {
  margin: 0;
  margin-left: auto;
  font-family: "Poppins";
  font-weight: 700;
  font-size: 12px;
  text-align: right;
  color: rgba(0, 0, 0, 0.87);
}

.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked.Mui-disabled {
  color: #58cc02 !important;
}

/*  */

.myne-item__venda-status > div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  height: 26px;
  border-radius: 4px;
  color: #58cc02;
  margin-right: 8px;
  white-space: nowrap;
  background: #dcffc2;
  font-size: 12px;
  text-transform: uppercase;
}

.myne-item__venda-text {
  margin-left: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.myne-item__venda-text > p {
  text-align: right;
  font-size: 11px;
  color: rgba(0, 0, 0, 0.6);
  opacity: 0.87;
  white-space: nowrap;
  flex: auto;
  overflow: hidden;
  text-overflow: ellipsis;
}

.myne-item__venda-text > h5 {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.87;
  font-size: 15px;
  font-weight: 600;
  margin-top: 2px;
}
/**/

.myne-modal-drop {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 12px;
  right: 20px;
  width: 210px;
  transition: all 0.3s ease;
  background-color: #fff;
  z-index: 999;
  overflow: hidden;
  justify-content: center;
  border-radius: 8px;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.12);
}

.myne-modal-drop > div {
  width: 100%;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.87);
  padding: 0 18px;
  font-weight: 400;
  font-size: 15.5px;
  height: 35px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.myne-modal-drop > div > svg {
  margin-right: 10px;
}

.myne-modal-drop > div > b {
  margin-left: 4px;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 700;
}

.myne-modal-drop > div:hover {
  background-color: #f7f4fa;
}

.myne-modal__modal-add-item {
  border-radius: 8px;
  background-color: #fff;
  position: relative;
  display: flex;
  width: 264px;
  height: 215px;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.12);
  padding: 24px;
  flex-direction: column;
}

.myne-modal__modal-add-item > header {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.myne-modal__modal-add-item > header > svg {
  width: 24px;
  position: relative;
  top: -2px;
  height: 24px;
}

.myne-modal__modal-add-item > header > svg path {
  fill: var(--preto-87) !important;
  fill-opacity: 1 !important;
}

.myne-modal__modal-add-item > header > p {
  font-size: 16px;
  font-weight: 700;
  color: #000000;
  margin: 0;
  margin-left: 12px;
  opacity: 0.87;
}

.myne-modal__modal-add-item-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease;
  padding-top: 0;
  height: 100%;
}

.myne-modal__modal-add-item-content > p {
  font-size: 14px;
  margin-top: -5px;
  color: rgba(0, 0, 0, 0.87);
}

.myne-modal__modal-add-item-content > div {
  display: flex;
  align-items: center;
  margin-top: auto;
  width: 100%;
  justify-content: flex-end;
}

.myne-modal__modal-add-item-content > div > button {
  font-size: 14px;
  text-transform: uppercase;
  margin-left: 36px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  color: rgba(0, 0, 0, 0.87);
  border: none;
  outline: initial;
  padding: 0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 45px;
  max-height: 45px;
  min-height: 45px;
  background-color: rgba(0, 0, 0, 0);
}

.myne-modal__modal-add-item-content > div > button:hover {
  opacity: 0.8;
}
.myne-modal__modal-add-item-content > div > button:active {
  -webkit-transform: scale(0.98);
          transform: scale(0.98);
}
.myne-modal__modal-add-item-content > div > button:nth-child(2):active {
  box-shadow: 3px 2px 25px 0px rgba(51, 51, 51, 0.123);
}

.myne-modal__modal-add-item-content > div > button:nth-child(2) {
  padding: 10px 32px !important;
  background: var(--roxo-myne) !important;
  color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12) !important;
  border-radius: 124px !important;
}

.myne-modal__modal-add-item-content > div > button:nth-child(2):disabled {
  background: rgba(0, 0, 0, 0.06) !important;
  color: rgba(0, 0, 0, 0.36) !important;
  box-shadow: none !important;
  cursor: auto !important;
}
.myne-modal__modal-add-item-content .attentionNo > button:nth-child(1):active {
  box-shadow: 3px 2px 25px 0px rgba(51, 51, 51, 0.123);
}

.myne-modal__modal-add-item-content .attentionNo > button:nth-child(1) {
  padding: 10px 32px !important;
  background: var(--roxo-myne) !important;
  color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12) !important;
  border-radius: 124px !important;
}
.myne-modal__modal-add-item-content > div > button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.myne-modal__modal-add-item-content > div > button > svg {
  margin-right: 10px;
}
.myne-modal__modal-add-item-content .attentionNo > button:nth-child(2):active {
  box-shadow: initial;
}

.myne-modal__modal-add-item-content .attentionNo > button:nth-child(2) {
  padding: 10px 32px !important;
  margin: 0;
  background: #fff0 !important;
  color: rgba(0, 0, 0, 0.87) !important;
  box-shadow: initial !important;
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -ms-border-radius: 0 !important;
  -o-border-radius: 0 !important;
}

/*  */

/* .dashboard-content__grid-vendas__vendas-padding {
  padding-bottom: 100px;
} */

.myne-login {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 999;
  position: fixed;
  padding: 20px 10%;
  background-color: var(--background);
}

/**/
.myne-login .MuiInputBase-root,
.myne-login .MuiFormLabel-root {
  font-family: "Poppins", sans-serif;
}

.myne-login .MuiFormLabel-root {
  color: #000;
  opacity: 0.7;
  font-size: 15.3px;
  line-height: 0px;
}

.myne-login .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 2px solid rgba(119, 119, 119, 0.596);
}

.myne-login .MuiInput-underline:before {
  border-bottom: 1px solid #e3e3e3;
}
/**/

.login__titles {
  display: flex;
  flex-direction: column;
  width: 45%;
  height: 100%;
}

.login__titles > p {
  font-weight: 800;
  font-size: 48px;
  text-align: left;
  margin-left: 22px;
  width: 500px;
  margin-top: auto;
}

.login__titles > p > strong {
  color: var(--roxo-myne);
}

.login__titles > span {
  font-size: 18px;
  margin-top: 30px;
  width: 450px;
  margin-left: 22px;
  color: rgba(0, 0, 0, 0.64);
}

.login__titles > img {
  width: 340px;
  padding-top: 60px;
  margin-bottom: auto;
  margin-right: auto;
  margin-left: 60px;
  margin-top: auto;
}

/**/

.login__content,
.login__content-recuperar__senha {
  border-radius: 12px;
  box-shadow: var(--shadow-web);
  width: 456px;
  margin-left: 20px;
  padding: 40px 55px;
  display: flex;
  height: 435px;
  flex-direction: column;
  transition: all 0.4s ease !important;
  background: #fff;
}

.login__content > section {
  margin-bottom: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.login__content > section span {
  margin-left: auto;
  margin-top: 18px;
  cursor: pointer;
  color: #000;
}

.login__content > section p {
  margin-top: 18px;
  font-size: 12px;
  color: rgb(245, 69, 69);
}

.input-form {
  width: 100%;
  align-items: center;
  display: flex;
  position: relative;
}

.form__field-expand {
  position: absolute;
  width: 100%;
  height: 90%;
  background-color: transparent;
  z-index: 1;
  bottom: 0;
  cursor: pointer;
  border-radius: 12px;
}

/**/
.form__group {
  position: relative;
  padding: 20px 0 0;
  width: 100%;
  height: 100%;
}

.form__field {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  outline: 0;
  font-size: 15px;
  color: #000;
  padding-bottom: 4px;
  padding-top: 4px;
  z-index: 1;
  background: transparent;
  transition: border-color 0.2s;
}

.form__field:hover {
  border-bottom: 1px solid #8f8f8f;
}

.form__field::-webkit-input-placeholder {
  color: transparent;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.form__field::placeholder {
  color: transparent;
  transition: all 0.2s ease;
}

.form__field:placeholder-shown ~ .form__label {
  font-size: 14px;
  cursor: text;
  top: 17px;
}

.form__field:placeholder-shown ~ .form__label-senha {
  font-size: 14px;
  cursor: text;
  top: 32px;
}

.form__label {
  position: absolute;
  top: 22px;
  width: 100%;
  display: block;
  transition: 0.2s;
  font-size: 13.5px;
  color: rgba(0, 0, 0, 0.6);
}

.form__label-senha {
  position: absolute;
  top: 15px;
  cursor: auto;
  display: block;
  transition: 0.2s;
  font-size: 13.5px;
  color: rgba(0, 0, 0, 0.6);
}

.form__field:focus,
.form__label:focus,
.form__label-senha:focus {
  padding-bottom: 6px;
  border-bottom: 2px solid var(--roxo-myne);
}
.form__field:focus::-webkit-input-placeholder, .form__label-senha:focus::-webkit-input-placeholder {
  color: var(--preto-36) !important;
}
.form__field:focus::placeholder,
.form__label-senha:focus::placeholder {
  color: var(--preto-36) !important;
}

.form__field:focus + .input_label,
.form__field:valid + .input_label {
  color: #665856;
  -webkit-transform: translateY(-25px);
  transform: translateY(-25px);
}

.form__field:focus + .input_label,
.form__field:read-only ~ .input_label,
.form__field:valid + .input_label {
  color: #665856;
  -webkit-transform: translateY(-25px);
  transform: translateY(-25px);
}

.form__field:focus ~ .form__label,
.form__field:read-only ~ .form__label,
.form__field:valid ~ .form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 13.5px;
}
.form__field:focus ~ .form__label-senha,
.form__field:valid ~ .form__label-senha {
  position: absolute;
  top: 17px;
  display: block;
  transition: 0.2s;
  font-size: 13.5px;
}

.form__field:focus ~ .form__label {
  color: var(--roxo-myne);
}

.form__field:focus
  ~ .form__label-senha
  .form__field:valid
  ~ .form__label-senha {
  position: absolute;
  top: 15px;
  display: block;
  transition: 0.2s;
  font-size: 13.5px;
}

.form__field:focus ~ .form__label-senha {
  color: var(--roxo-myne);
}

/* reset input */
.form__field:required,
.form__field:invalid {
  box-shadow: none;
}
/**/

.myne-login .MuiFormControl-root {
  width: 100%;
}

.form__label > svg {
  right: 0;
  position: absolute;
  cursor: pointer;
  top: 7px;
}

.myne-eye-password {
  top: 35px !important;
}

.input-form > svg {
  position: absolute;
  right: 0;
  z-index: 2;
  top: 25px;
  cursor: pointer;
}

.login__content a {
  text-decoration: none;
  color: var(--roxo-myne);
  font-size: 16px;
  margin-top: 22px;
  margin-left: auto;
  font-weight: 500;
  margin-right: auto;
  cursor: pointer;
}

.login__content a:hover {
  text-decoration: underline;
}

/**/

.login__back {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.myne-login .MuiIconButton-root {
  margin-right: 8px;
  margin-left: -11px;
  cursor: pointer;
}

.login__back > p {
  font-weight: bold;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.87);
}

.login__content-recuperar__senha > section {
  margin-top: auto;
}

.login__content-recuperar__senha > section > button {
  margin-top: 30px;
}

/* RESPONSIVE */

@media (max-width: 940px) {
  .myne-login {
    flex-direction: column;
    align-items: center;
  }
  .login__titles {
    width: 90%;
  }
  .login__titles > img {
    margin: 35px auto;
  }
  .login__content,
  .login__content-recuperar__senha {
    width: 480px;
    margin-left: 0px;
    padding: 30px 45px;
    display: flex;
    margin-top: 100px;
  }

  .login__titles > p {
    margin-left: 0px;
    width: 100%;
  }

  .login__titles > span {
    margin-left: 0px;
    width: 100%;
  }
}

@media (max-width: 541px) {
  .login__titles {
    width: 100%;
  }
  .login__titles > img {
    margin: 30px 0;
    width: 300px;
  }
  .login__titles > p {
    font-size: 42px;
  }
  .login__titles > span {
    font-size: 17px;
  }
  .login__content,
  .login__content-recuperar__senha {
    width: 100%;
    padding: 25px 22px;
    margin-top: 250px;
  }
}

@media (max-width: 444px) {
  .myne-login {
    padding: 50px 6%;
  }
}

