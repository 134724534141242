.myne-btn {
  width: 100%;
  height: 45px;
  max-height: 45px;
  min-height: 45px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  outline: initial;
  border-radius: 10px;
  box-shadow: var(--shadow-button);
  border-radius: 50px;
  transition: all 0.1s ease;
}

.myne-btn:active,
.header__center-btn:active,
.myne-btn__seje-plus:active {
  transform: scale(0.98);
  box-shadow: 3px 2px 25px 0px rgba(51, 51, 51, 0.123);
}

.btn-entrar {
  background-color: var(--roxo-myne);
  color: #fff;
  font-size: 15px;
  transition: opacity 0.3s ease;
}

.btn-entrar:hover {
  opacity: 0.7;
}

.btn-google {
  color: rgba(0, 0, 0, 0.87);
  position: relative;
  background-color: #fff;
  font-weight: 500;
  margin-top: 18px;
  font-size: 15px;
}

.btn-google > svg {
  position: absolute;
  font-size: 25px;
  left: 10px;
}

/**/

.myne-button__sidebar {
  flex: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 0;
  cursor: pointer;
  justify-content: center;
  text-decoration: none;
  height: 157px;
  transition: all 0.2s ease;
}

.myne-button__sidebar:hover {
  background: var(--background_hover);
}

.myne-button__sidebar > svg path {
  fill: var(--background-second);
  transition: all 0.2s ease;
  opacity: 1 !important;
}

.myne-button__sidebar > p {
  color: var(--background-second);
  font-size: 14px;
  margin-top: 14px;
  font-weight: 500;
  margin-bottom: 0;
  transition: all 0.2s ease;
}

.myne-button__sidebar-active > svg > path {
  fill: var(--roxo-myne);
  opacity: 5;
  transition: all 0.2s ease;
}

.myne-button__sidebar-active > p {
  color: rgba(0, 0, 0, 0.87);
  transition: all 0.2s ease;
}

/**/

.myne-btn-logout {
  display: flex;
  align-items: center;
  border: none;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0);
  outline: initial;
}

.myne-btn-logout > svg {
  width: 22px;
}

.myne-btn-logout > p {
  margin-left: 15px;
  color: var(--preto-60);
  font-weight: 500;
  font-size: 14px;
}

/**/

.myne-btn__seje-plus {
  /* margin-right: 20px; */
  border-radius: 50px;
  height: 42px;
  width: 170px;
  background-color: #58cc02;
  border: none;
  outline: initial;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  cursor: pointer;
}

.dashboard-content__grid-vendas__button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 20px 16px 16.5px;
  position: absolute;
  bottom: 24px;
  right: 20px;
  /* width: 178px; */
  width: fit-content;
  color: #fff;
  height: 48px;
  background: var(--roxo-myne);
  cursor: pointer;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 100px;
  transition: all 0.2s ease;
  z-index: 2;
}

.dashboard-content__grid-vendas__button:hover {
  opacity: 0.8;
}

.myne-btn__seje-plus:hover {
  opacity: 0.8;
}

.myne-btn__seje-plus > svg {
  position: absolute;
  left: 20px;
}

.myne-btn__seje-plus > p {
  font-size: 15.2px;
  color: #fff;
  font-weight: 600;
  margin-left: 30px;
}
