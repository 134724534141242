.myne-dashboard {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  overflow: hidden;
}

.dashboard__content {
  flex: 1;
  padding: 14px;
  display: flex;
  overflow-y: scroll;
  flex-direction: column;
}

.dashboard__content::-webkit-scrollbar {
  width: 6px;
  border-radius: 5px;
}
.dashboard__content::-webkit-scrollbar-track {
  border-radius: 0px;
  background-color: var(--background);
}
.dashboard__content::-webkit-scrollbar-thumb {
  background: linear-gradient(var(--roxo-myne), var(--roxo-myne));
}

.dashboard-content__filter {
  display: flex;
  margin: 10px 0;
}

.dashboard-content__filter > p {
  font-weight: bold;
  font-size: 24px;
  display: flex;
  margin-left: 24px;
  align-items: center;
  color: rgba(0, 0, 0, 0.87);
}

/**/

.dashboard-content__grid {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  margin-top: 10px;
  gap: 10px;
}

/**/

.dashboard-content__grid-cadastros-resume-clientes {
  background-color: #fff;
  width: 307px;
  height: 615px;
  z-index: 2;
  border-left: 1px solid rgba(0, 0, 0, 0.06);
  position: relative;
  padding: 24px 24px 0px;
  display: flex;
  flex-direction: column;
  border-radius: 0px 8px 8px 0px;
  overflow-y: auto;
}

.dashboard-content__grid-cadastros-resume-clientes > p {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 700;
  margin: 0;
}

.dashboard-content__grid-cadastros-resume-clientes_content {
  display: flex;
  flex-direction: column;
}

.dashboard-content__grid-cadastros-resume-clientes_content-user {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  width: fit-content;
  height: 52px;
  background: #faefff;
  border: 1px solid var(--roxo-myne);
  border-radius: 100px;
  margin: 24px 0;
}

.dashboard-content__grid-cadastros-resume-clientes_content-user > img {
  width: 36px;
  height: 36px;
  margin-right: 8px;
}

.dashboard-content__grid-cadastros-resume-clientes_content-user > p {
  margin: 0;
  font-weight: 600;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.87;
}

.dashboard-content__grid-cadastros-resume-clientes_content-item__info {
  display: flex;
  margin-bottom: 24px;
}

.dashboard-content__grid-cadastros-resume-clientes_content-item__info > svg {
  margin-right: 19px;
  max-width: 18px;
  max-height: 18px;
  min-width: 18px;
  min-height: 18px;
  width: 18px;
  height: 18px;
}

.dashboard-content__grid-cadastros-resume-clientes_content-item__info
  > section {
  display: flex;
  flex-direction: column;
  margin-top: -3px;
}

.dashboard-content__grid-cadastros-resume-clientes_content-item__info
  > section
  > p {
  margin: 0;
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
}

.dashboard-content__grid-cadastros-resume-clientes_content-item__info
  > section
  > h5, .dashboard-content__grid-cadastros-resume-clientes_content-item__info
  > section
  > h5 > span {
  margin: 0;
  margin-top: 6px;
  font-weight: 700;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.87);
}

.dashboard-content__grid-cadastros-resume-clientes_content > section {
  background-color: rgba(0, 0, 0, 0.06);
  height: 1px;
  /* width: 100%; */
  margin-bottom: 24px;
  width: 259px;
}

/* RESUME */

.dashboard-content__grid-cadastros-resume > div:nth-child(2) {
  margin-top: 26px;
}

.dashboard-content__grid-cadastros-resume > div span {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  margin-top: 6px;
  color: #ffbd06;
}

.dashboard-content__grid-cadastros-resume > div p {
  color: rgba(0, 0, 0, 0.7);
}

.dashboard-content__grid-cadastros-resume > div svg path {
  fill: #000000;
  fill-opacity: 0.7;
  opacity: 0.7;
}

/*  */

.dashboard-content__grid-cadastros-resume {
  background-color: #fff;
  width: 300px;
  width: 307px;
  height: 615px;
  border: 1px solid var(--border-myne);
  /* margin-left: 10px; */
  border-radius: 8px;
  position: relative;
  padding: 22px;
  border: 1px solid rgba(0, 0, 0, 0.06);
}

.dashboard-content__grid-cadastros-resume > p {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 700;
  margin: 0;
}

.dashboard-content__grid-cadastros-resume > h5 {
  margin: 54px auto;
  display: flex;
  width: 213px;
  justify-content: center;
  align-items: center;
  padding: 2px 6px;
  background: rgba(0, 0, 0, 0.87);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.161);
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.87);
}

.dashboard-content__grid-cadastros-resume > svg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

/**/

.dashboard-content__grid-vendas__vendas-categories {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 17px;
  padding-right: 17px;
  padding-bottom: 12px;
}

.dashboard-content__grid-vendas__clientes {
  display: flex;
}

.dashboard-content__grid-vendas__clientes
  .dashboard-content__grid-vendas__vendas-items {
  overflow-y: initial;
}

.dashboard-content__grid-vendas {
  background-color: #fff;
  box-shadow: var(--shadow-web);
  /* height: fit-content; */
  /* padding-bottom: 15px; */
  flex: 0.9;
  width: 839px;
  height: 615px;
  /* max-height: 495px; */
  padding-left: 5px;
  border-radius: 8px;
  position: relative;
}

/**/

.dashboard-content__grid-vendas__header {
  display: flex;
  align-items: center;
  padding: 8px 5px;
  padding-bottom: 25px;
}

.dashboard-content__grid-vendas__header > div {
  display: flex;
  align-items: center;
  height: 80px;
  flex: 1;
  margin: 0 4px;
  justify-content: center;
  border-radius: 10px;
  padding: 10px;
}

.dashboard-content__grid-vendas__header > section {
  display: flex;
  align-items: center;
  height: 80px;
  flex: 2;
  margin: 0 4px;
  justify-content: center;
  border-radius: 10px;
}

/**/
.myne-dashboard .tns-outer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  position: relative;
  flex-direction: column-reverse;
}
.myne-dashboard .tns-outer > button,
.myne-dashboard .tns-controls > button {
  display: none;
}

.tns-nav {
  position: absolute;
  bottom: -22px;
  right: 0;
  left: 0;
}

.nice-dates-day_month {
  color: #999;
  font-size: 10px;
  left: 0;
  line-height: 1;
  position: absolute;
  right: 0;
  text-transform: capitalize;
  top: 7%;
  z-index: 3;
}

.tns-nav > button {
  width: 8px;
  margin: 0 4px;
  height: 8px;
  border: none;
  outline: initial;
  background: rgba(170, 0, 255, 0.36);
  border-radius: 100%;
}

.tns-nav > button.tns-nav-active {
  background: var(--roxo-myne);
}
/**/

.dashboard-content__grid-vendas__header-item-banner {
  padding: 0;
}

.dashboard-content__grid-vendas__header-item-banner svg {
  width: 100%;
}

.dashboard-content__grid-vendas__header-item-2 {
  background-color: #ffbd06;
}

.dashboard-content__grid-vendas__header-item-2 > p {
  font-size: 11px;
  color: #000000;
  opacity: 0.87;
  font-weight: 500;
}

.dashboard-content__grid-vendas__header-item-2 > button {
  font-weight: 500;
  font-size: 10.5px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--roxo-myne);
  border-radius: 30px;
  border: none;
  outline: initial;
  width: 110px;
  height: 24px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.dashboard-content__grid-vendas__button svg {
  width: 15px;
  height: 14px;
  max-width: 15px;
  max-height: 14px;
  min-width: 15px;
  min-height: 14px;
}

.dashboard-content__grid-vendas__button > p {
  position: static;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #ffffff;
  white-space: nowrap;
  margin-left: 12px;
}

.dashboard-content__grid-vendas__not-vendas {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 420px;
  flex: 1;
}

.dashboard-content__grid-vendas__not-vendas > p {
  margin: 20px;
  width: 250px;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 21px;
  text-align: center;
  color: #000000;
  opacity: 0.87;
}

/**/

.dashboard-content__grid-vendas__vendas {
  display: flex;
  flex-direction: column;
  height: 605px;
  /* height: 605px; */
  flex: 1;
  overflow-y: auto;
  animation: init 0.4s ease;
}

/* .dashboard-content__grid-vendas__vendas > * {
  animation: init 0.2s ease;
} */

@keyframes init {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.dashboard-content__grid-vendas__vendas-header {
  display: flex;
  justify-content: space-between;
  padding: 17px 22px 10px 17px;
}

.dashboard-content__grid-vendas__vendas-header > p {
  color: rgba(0, 0, 0, 0.6);
  font-size: 13px;
}

.dashboard-content__grid-vendas__vendas-header > div {
  display: flex;
  align-items: center;
}

.dashboard-content__grid-vendas__vendas-header svg {
  cursor: pointer;
  margin-left: 15px;
  width: 18px;
  height: 18px;
  transition: all 0.3s ease;
}

.dashboard-content__grid-vendas__vendas-header svg path {
  fill: rgba(0, 0, 0, 0.6);
  fill-opacity: 1 !important;
}

.dashboard-content__grid-vendas__vendas-header svg:hover {
  opacity: 0.6;
}

.dashboard-content__grid-vendas__vendas-header--search {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 5px;
}

.dashboard-content__grid-vendas__vendas-header--search > svg {
  margin-left: 0;
  margin-right: 20px;
  width: 18px;
  height: 18px;
  max-width: 18px;
  min-width: 18px;
  max-height: 18px;
  min-height: 18px;
}

.dashboard-content__grid-vendas__vendas-header--search > svg path {
  fill: rgba(0, 0, 0, 0.6);
  opacity: 1 !important;
}

.dashboard-content__grid-vendas__vendas-header--search > input {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  padding-bottom: 8px;
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
  transition: all 0.1s ease-in-out;
  border: none;
  outline: none;
  border-bottom: 1px solid #c5c5c5;
}

.dashboard-content__grid-vendas__vendas-header--search > input:hover {
  border-bottom: 1px solid #8f8f8f;
}

.dashboard-content__grid-vendas__vendas-header--search > input:focus {
  border-bottom: 2px solid var(--roxo-myne);
}

.dashboard-content__grid-vendas__vendas-items {
  padding-left: 17px;
  padding-right: 17px;
  max-height: 605px;
  padding-top: 0px;
  padding-bottom: 80px;
  overflow-y: auto;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.06);
}
::-webkit-scrollbar-thumb {
  background-color: var(--roxo-myne-36);
  border-radius: 25px;
}

.dashboard-content__grid-vendas__vendas-bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 120px;
  z-index: 1;
  /*height: 80px;
  */ /*background: linear-gradient(rgba(255, 255, 255, 0), #fff);
  */
  background: linear-gradient(
    360deg,
    #ffffff 34.74%,
    rgba(255, 255, 255, 0) 122.84%
  );
  /* border-radius: 0 0 8px 8px; */
  /* -webkit-border-radius: 0 0 8px 8px;
  -moz-border-radius: 0 0 8px 8px;
  -ms-border-radius: 0 0 8px 8px;
  -o-border-radius: 0 0 8px 8px; */
}

/**/

.dashboard-content__grid-flex {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* margin-left: 10px; */
}

.dashboard-content__grid-flex__money {
  display: flex;
  gap: 10px;
}

.dashboard-content__grid-flex__money > section {
  flex: 2;
  background-color: #fff;
  border-radius: 8px;
  height: 130px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  border: 1px solid var(--border-myne);
}

.dashboard-content__grid-flex__money > section > .dashboard-hand-money {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 80px;
  height: 83px;
  border-radius: 8px;
}
.dashboard-content__grid-flex__money > section > div {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 95px;
  padding: 6px 0;
}

.dashboard-content__grid-flex__money > section > div > p {
  color: rgba(0, 0, 0, 0.6);
  font-size: 13px;
}

.dashboard-content__grid-flex__money > section > div > h5 {
  color: #000000;
  opacity: 0.87;
  font-weight: 500;
  font-size: 14.5px;
}

.dashboard-content__grid-flex__money > div {
  flex: 1.6;
  display: flex;
  /* margin-left: 10px; */
  background-color: #fff;
  border-radius: 8px;
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid var(--border-myne);
}

.dashboard-content__grid-flex__money > div > img {
  width: 80px;
  margin-left: 25px;
  margin-right: 10px;
  border-radius: 8px;
}

.dashboard-content__grid-flex__money > div > div {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 20px;
  padding: 6px 0;
}

.dashboard-content__grid-flex__money > div > div > p {
  color: rgba(0, 0, 0, 0.6);
  font-size: 13.2px;
}

.dashboard-content__grid-flex__money > div > div > h5 {
  color: #000000;
  opacity: 0.87;
  font-weight: 500;
  margin-top: 2px;
  font-size: 15.2px;
}

/**/

.dashboard-content__grid-flex__compradores {
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--border-myne);
  background-color: #fff;
  /* margin: 10px 0; */
  padding: 22px 18px;
  flex-direction: column;
  display: flex;
}

.dashboard-content__grid-flex__compradores > div {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.dashboard-content__grid-flex__compradores > p {
  color: rgba(0, 0, 0, 0.6);
  font-size: 13px;
}

.dashboard-content__grid-flex__compradores > h5 {
  text-align: center;
  color: #000000;
  opacity: 0.87;
  font-weight: 500;
  font-size: 14.5px;
  width: 50%;
  margin: 0 auto;
  margin-top: 10px;
}

/**/

.dashboard-content__grid-flex__vendas {
  display: flex;
  align-items: center;
  gap: 10px;
}

.dashboard-content__grid-flex__vendas > div > section {
  flex: 1;
  width: 100%;
  /* overflow: auto; */
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 25px;
}

.myne-chartdiv * {
  transition: none !important;
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
}
.myne-chartdiv {
  transition: none !important;
}

.dashboard-content__grid-flex__vendas > div > section > div {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  width: 95%;
}

.dashboard-content__grid-flex__vendas > div > section > div > section {
  margin-top: 2px;
}

.dashboard-content__grid-flex__vendas > div > section > div p {
  width: 82px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: 0 5px;
  font-size: 13px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
}
/**/
/* .myne-dashboard g[id="id-323"] {
  fill: #A4A4FF;
  stroke: #A4A4FF;
}

.myne-dashboard g[id="id-343"] {
  fill: #F969AE;
  stroke: #F969AE;
}

.myne-dashboard g[id="id-360"] {
  fill: #F9C478;
  stroke: #F9C478;
}

.myne-dashboard g[id="id-377"] {
  fill: #E0FFB0;
  stroke: #E0FFB0;
}

.myne-dashboard g[id="id-246"] {
  fill: #2C25FE;
  stroke: #2C25FE;
}

.myne-dashboard g[id="id-266"] {
  fill: #FF0672;
  stroke: #FF0672;
}

.myne-dashboard g[id="id-283"] {
  fill: #F5AB2D;
  stroke: #F5AB2D;
}

.myne-dashboard g[id="id-300"] {
  fill: #BDEE7C;
  stroke: #BDEE7C;
}

.myne-dashboard g[id="id-400"] {
  fill: #5D5AA7;
  stroke: #5D5AA7;
}

.myne-dashboard g[id="id-420"] {
  fill: #C96691;
  stroke: #C96691;
}

.myne-dashboard g[id="id-437"] {
  fill: #D6A553;
  stroke: #D6A553;
}

.myne-dashboard g[id="id-454"] {
  fill: #B3DF78;
  stroke: #B3DF78;
} */
/**/

.amcharts-AxisLabelCircular text {
  display: none;
}

.amcharts-PieTick {
  display: none;
}

.amcharts-AmChartsLogo-group,
g[aria-labelledby] {
  display: none;
}

.dashboard-content__grid-flex__vendas > div > section > div svg {
  width: 9px;
  height: 9px;
}

#chartdiv {
  width: 160px;
  height: 160px;
  margin: 0;
}

.dashboard-content__grid-flex__vendas > div > section > section {
  width: 100%;
  display: flex;
  padding: 8px;
  margin-top: auto;
  align-items: flex-end;
  justify-content: flex-end;
}

.dashboard-content__grid-flex__vendas > div > section > section > button {
  border: 1px solid rgba(0, 0, 0, 0.06);
  padding: 3px 14px;
  color: rgba(0, 0, 0, 0.87);
  background-color: #fff;
  border-radius: 20px;
  font-size: 12px;
  outline: initial;
  transition: all 0.3s ease;
  cursor: pointer;
  transform: scale(1);
}

.dashboard-content__grid-flex__vendas
  > div
  > section
  > section
  > button:active {
  background-color: var(--border-myne);
  transform: scale(0.97);
}

.dashboard-content__grid-flex__vendas > div > section > section > button:hover {
  background-color: var(--border-myne);
}

.dashboard-content__grid-flex__vendas > div > section > div section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboard-content__grid-flex__vendas > div > section > div section > div {
  display: flex;
  align-items: center;
}

.dashboard-content__grid-flex__vendas > div > section h5 {
  color: rgba(0, 0, 0, 0.7);
  font-size: 13px;
  font-weight: 500;
  opacity: 0.6;
  margin-left: auto;
}

.dashboard-content__grid-flex__vendas > div {
  width: 50%;
  /* height: 355px; */
  height: 475px;
  display: flex;
  padding: 4px;
  background-color: #fff;
  border-radius: 8px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 1px solid var(--border-myne);
}

.dashboard-content__grid-flex__vendas > div > p {
  color: rgba(0, 0, 0, 0.6);
  font-size: 13px;
  text-align: left;
  position: absolute;
  top: 10.5px;
  left: 10.5px;
  width: 100%;
}

.dashboard-content__grid-flex__vendas > div > svg {
  margin-bottom: 16px;
  width: 23px;
  height: 24.05px;
}

.dashboard-content__grid-flex__vendas > div > svg path {
  fill-opacity: 0.36;
}

.dashboard-content__grid-flex__vendas > div > div {
  justify-content: center;
  align-items: center;
  display: flex;
}

.dashboard-content__grid-flex__vendas > div > div > p {
  margin: auto 24px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  opacity: 0.87;
}

/* INFO */

.dashboard-content__grid-cadastros__info {
  display: flex;
  margin-top: 24px;
}

.dashboard-content__grid-cadastros__info > svg {
  max-width: 20px;
  min-width: 20px;
  width: 20px;
  max-height: 20px;
  min-height: 20px;
  height: 20px;
}

.dashboard-content__grid-cadastros__info > p {
  font-weight: 400;
  margin-top: -2px;
  font-size: 12px;
  width: 200px;
  color: rgba(0, 0, 0, 0.6);
  margin-left: 16px;
}

.myne-item__cadastro-cliente {
  display: flex;
  flex-direction: column;
  padding: 16px;
  position: relative;
  width: 233px;
  height: 138px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 16px;
  margin: 6px;
  cursor: pointer;
}

.myne-item__cadastro-cliente:hover {
  background-color: rgb(250, 250, 250);
  /* background-color: rgba(250, 249, 249, 0.87); */
  border: 1px solid rgba(0, 0, 0, 0.36);
}

.myne-item__cadastro-cliente-active {
  background: #faefff !important;
  border: 1px solid var(--roxo-myne) !important;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12) !important;
}

.myne-item__cadastro-cliente-base {
  position: relative;
  width: fit-content;
  height: fit-content;
  display: flex;
}
/*  */
.myne-item__cadastro-cliente-base > .myne-modal__modal-sucess-publicate_copy {
  position: absolute;
  right: 13px;
  width: 13px;
  top: 23px;
  right: 23px;
  height: 13px;
  max-width: 13px;
  min-height: 13px;
  min-width: 13px;
  max-height: 13px;
  cursor: pointer;
  z-index: 1;
}

.myne-item__cadastro-cliente-base
  > .myne-modal__modal-sucess-publicate_copy:nth-child(1) {
  margin-right: 30px;
}

.myne-item__cadastro-cliente-base-icon:hover path {
  fill-opacity: 0.8;
}

/*  */

.dashboard-content__grid-vendas__clientes
  .dashboard-content__grid-vendas__vendas-items {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: -6px;
  margin-top: -6px;
  max-height: initial;
}

.myne-item__cadastro-cliente__user {
  display: flex;
  align-items: center;
}

.myne-item__cadastro-cliente__user > img {
  width: 35px;
  height: 35px;
  object-fit: cover;
}

.myne-item__cadastro-cliente__user > p {
  margin: 0;
  margin-left: 8px;
  font-weight: 600;
  font-size: 12.5px;
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.87;
  max-width: 115px;
}

.myne-item__cadastro-cliente__infos {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}

.myne-item__cadastro-cliente__infos > section {
  display: flex;
  align-items: center;
}

.myne-item__cadastro-cliente__infos > section:nth-child(2) {
  margin-top: 11px;
}

.myne-item__cadastro-cliente__infos > section > svg {
  width: 17px;
  height: 17px;
  max-width: 17px;
  min-height: 17px;
  min-width: 17px;
  max-height: 17px;
}

.myne-item__cadastro-cliente__infos > section > p {
  font-weight: 500;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.87);
  margin: 0;
  margin-left: 10px;
}

/*  */

.dashboard-content__grid-cadastros__not-itens {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dashboard-content__grid-cadastros__not-itens > svg {
  width: 25px;
  height: 25px;
}

.dashboard-content__grid-cadastros__not-itens > p {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 13px;
  margin: 9px 30px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
}

/**/

@media (max-width: 1314px) {
  .dashboard-content__grid-vendas__header > div {
    width: 150px;
  }
  .dashboard-content__grid-vendas__header-item-1 > div > p {
    font-size: 12px;
  }
  .dashboard-content__grid-vendas__header-item-1 > div > h5 {
    font-size: 14px;
    margin-top: 2px;
  }
  .dashboard-content__grid-vendas__header-item-2 > p {
    font-size: 10px;
  }
  .dashboard-content__grid-flex__vendas > div > p {
    color: rgba(0, 0, 0, 0.6);
    font-size: 11px;
    top: 10px;
    left: 10px;
  }
  .dashboard-content__grid-flex__money > div > div > p {
    font-size: 11px;
  }
  .dashboard-content__grid-flex__compradores > p {
    font-size: 10px;
  }
  .dashboard-content__grid-flex__money > section > div > p {
    font-size: 10px;
  }
  .dashboard-content__grid-flex__compradores > h5 {
    font-size: 13px;
  }
  .dashboard-content__grid-flex__vendas > div > div > p {
    font-size: 12px;
  }
  .dashboard-content__grid-flex__money > section > div > h5 {
    color: #000000;
    opacity: 0.87;
    font-weight: 500;
    font-size: 13.5px;
  }
  .myne-button__sidebar {
    padding: 25px 0;
  }
}

@media (max-width: 1314px) {
  .dashboard-content__grid-flex__vendas > div > section > div p {
    width: 70px;
    margin: 0 5px;
    font-size: 12px;
  }
  .dashboard-content__grid-flex__vendas > div > section h5 {
    color: rgba(0, 0, 0, 0.7);
    font-size: 12px;
    font-weight: 500;
    opacity: 0.6;
    margin-left: auto;
  }
}

/* @media (max-width: 1314px) { */
@media (max-width: 1336px) {
  .dashboard-content__grid-vendas {
    width: 100%;
  }
  .dashboard-content__grid {
    flex-direction: column;
  }
  .dashboard-content__grid-flex {
    margin-left: 0px;
    /* margin-top: 12px; */
  }
  .dashboard-content__grid-cadastros-resume {
    margin-left: 0px;
    /* margin-top: 12px; */
    width: 100%;
  }
}

@media (max-width: 952px) {
  .dashboard-content__grid-vendas__header > div {
    width: 160px;
  }
}

/* modal alert */

.myne-modal__modal-add-item__alert {
  width: 300px;
  height: 223px;
  display: flex;
  padding: 24px;
  background: #ffffff;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
}

.myne-modal__modal-add-item-content__alert {
  display: flex;
  flex-direction: column;
}

.myne-modal__modal-add-item-content__alert > section {
  display: flex;
}

.myne-modal__modal-add-item-content__alert > section > svg {
  max-width: 20px;
  max-height: 20px;
  min-width: 20px;
  min-height: 20px;
}

.myne-modal__modal-add-item-content__alert > section > div {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
}

.myne-modal__modal-add-item-content__alert > section > div > h5 {
  font-weight: 700;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 8px;
  margin-top: -2.2px;
}

.myne-modal__modal-add-item-content__alert > section > div > p {
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
}

.myne-modal__modal-add-item-content__alert > button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 32px;
  background: var(--roxo-myne);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  border-radius: 124px;
  margin: 0px 4px;
  width: 121px;
  height: 44px;
  margin-top: auto;
  margin-left: auto;
  cursor: pointer;
  transition: all 0.3s ease;
  border: none;
  outline: initial;
  font-size: 14px;
  color: #ffffff;
}

.myne-modal__modal-add-item-content__alert > button:hover {
  opacity: 0.7;
}
