.myne-filter-content {
  position: relative;
  margin-left: 24px;
  width: 80%;
  right: 0;
}

@media (max-width: 1070px) {
  .myne-filter-content {
    width: 73%;
    right: 0;
    margin-left: 18px;
  }
}

.myne-filter-dates {
  display: flex;
  align-items: center;
  overflow-y: hidden;
  padding-left: 0;
  scroll-behavior: smooth;
  white-space: nowrap;
  overflow-x: scroll;
  transition: all 0.4s ease;
}

.myne-filter-dates::-webkit-scrollbar {
  width: 90px;
  height: 0px;
}

/**/
.myne-filter-dates-left {
  position: absolute;
  left: -5px;
  padding: 10px;
  top: -3px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: auto;
  z-index: 1;
  height: 40px;
  background: linear-gradient(
    268deg,
    rgba(255, 255, 255, 0),
    var(--background),
    var(--background)
  );
}

.myne-filter-dates-left > section {
  width: 80px;
  height: 35px;
}

.myne-filter-dates-left div > svg {
  width: 10px;
  margin-top: 2px;
  cursor: pointer;
  height: 18px;
  transform: rotate(180deg);
}
/**/

.myne-filter-dates-right {
  position: absolute;
  right: -5px;
  padding: 10px;
  top: -3px;
  display: flex;
  z-index: 1;
  align-items: center;
  width: auto;
  height: 40px;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    var(--background),
    var(--background),
    var(--background)
  );
}

.myne-filter-dates-right > section {
  width: 60px;
  height: 35px;
}

.myne-filter-dates-right div > svg {
  width: 10px;
  margin-top: 5px;
  cursor: pointer;
  height: 18px;
}

.myne-filter-dates-left:active,
.myne-filter-dates-right:active {
  transform: scale(0.97);
  -webkit-transform: scale(0.97);
  -moz-transform: scale(0.97);
  -ms-transform: scale(0.97);
  -o-transform: scale(0.97);
}

.myne-filter-dates > button {
  padding: 4px 10px;
  margin-right: 9px;
  /* height: 35px; */
  transition: all 0.2s ease;
  font-size: 15px;
  border-radius: 35px;
  display: flex;
  align-items: center;
  z-index: 1;
  outline: initial;
  cursor: pointer;
  background: rgb(255, 255, 255);
  /* background: rgb(255, 255, 255, 0); */
  border: none;
  transform: scale(1);
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid rgba(0, 0, 0, 0.06);
}

.myne-filter-dates-btn-icon {
  width: 15px;
  height: 15px;
  max-width: 15px;
  max-height: 15px;
  min-width: 15px;
  min-height: 15px;
  margin-right: 8px;
}

.myne-filter-dates > button:hover {
  border: 1px solid rgba(0, 0, 0, 0.171);
  background: rgb(252, 251, 253);
}

.myne-filter-dates > button:active {
  transform: scale(0.97);
}

.myne-filter-dates > button > svg:hover {
  opacity: 0.5;
}
