.myne-text {
  margin: 0;
  color: var(--preto-87);
}

.myne-icon-button {
}

.myne-icon-button > svg {
  width: 18px;
  height: 18px;
}

.myne-main {
  height: 100%;
  width: 100%;
  position: fixed;
  background-color: var(--background);
  display: flex;
}

/**/

.myne-bigsSales__item {
  display: flex;
  align-items: center;
  flex: 1;
}

.myne-bigsSales__item > img {
  width: 35px;
  height: 35px;
  border-radius: 100%;
  object-fit: cover;
  margin-right: 7px;
}

.myne-bigsSales__item > p {
  font-size: 15px;
  color: #000000;
  opacity: 0.87;
  width: 115px;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 500;
  text-overflow: ellipsis;
}

/* loading */

.myne-loading-in-modal {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.myne-modal-loading {
  position: absolute;
  z-index: 999;
  opacity: 0.7;
  border-radius: 8px;
  overflow: hidden;
}

.myne-loadingn {
  height: 100%;
  right: 0;
  left: 255px;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 999;
}

.myne-loading-sale {
  height: 100px;
  margin-top: -10px;
  margin-bottom: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 999;
}
.myne-loading-btn {
  height: 1.5px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 43px;
  transform: scale(0.5);
}

.myne-loadingn > .myne-circle-loading {
  top: 50%;
}

.myne-loading {
  height: 100%;
  width: 100%;
  position: fixed;
  background-color: var(--background);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.myne-loading > img {
  width: 250px;
  margin-top: 9%;
}

.myne-circle-loading {
  width: 90px;
  height: 90px;
  position: absolute;
  top: 75%;
  left: 50%;
  background: transparent;
  border: 7px solid rgba(255, 255, 255, 0.3);
  border-radius: 100%;
  border-top: 7px solid var(--roxo-myne);
  transition: all 0.3s ease;
  animation: animate 0.9s infinite linear;
}

.myne-circle-loading-2 {
  width: 80px;
  height: 80px;
  position: absolute;
  top: 50%;
  left: 50%;
  background: transparent;
  border: 7px solid rgba(255, 255, 255, 0.3);
  border-radius: 100%;
  border-top: 7px solid var(--roxo-myne);
  transition: all 0.3s ease;
  animation: animate 0.9s infinite linear;
}

.myne-modal__modal-new-sale-content-loading {
  display: flex;
  width: 100%;
  margin-top: 90px;
  align-items: center;
  position: relative;
  justify-content: center;
}

.myne-circle-loading-3 {
  width: 60px;
  height: 60px;
  top: 50%;
  left: 50%;
  position: absolute;
  background: transparent;
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 100%;
  border-top: 4px solid var(--roxo-myne);
  transition: all 0.3s ease;
  animation: animate 0.9s infinite linear;
}

@keyframes animate {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  50% {
    transform: translate(-50%, -50%) rotate(180deg);
    border-top: 4px solid var(--azul-myne);
  }
  50% {
    transform: translate(-50%, -50%) rotate(230deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.myne-circle-loading-4 {
  width: 60px;
  height: 60px;
  top: 50%;
  left: 50%;
  position: absolute;
  background: transparent;
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 100%;
  border-top: 4px solid #fff;
  transition: all 0.3s ease;
  animation: animate2 0.9s infinite linear;
}

@keyframes animate2 {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  50% {
    transform: translate(-50%, -50%) rotate(180deg);
    border-top: 4px solid #fff;
  }
  50% {
    transform: translate(-50%, -50%) rotate(230deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

/**/

.myne-bottom-border {
  border-bottom: 1px solid var(--border-myne);
}

.myne-top-border {
  border-top: 1px solid var(--border-myne);
}

/**/

.myne-user-1 {
  display: flex;
  align-items: center;
  width: 85%;
  margin: 22px 20px;
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  border-radius: 100px;
  padding: 8px 11px;
}

.myne-user-1 img {
  width: 39px;
  height: 39px;
  object-fit: cover;
  border-radius: 100%;
  margin-right: 14px;
}

.myne-user-1 > div {
  display: flex;
  flex-direction: column;
  max-width: 145px;
}

.myne-user-1 > div > p {
  font-weight: 500;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.87);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.myne-user-1 > div > span {
  font-weight: 500;
  font-size: 11px;
  color: rgba(0, 0, 0, 0.6);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/**/

.myne-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.35);
  width: 100%;
  height: 100%;
  z-index: 999999;
  transition: all 0.3s ease;
}

.modal-message {
  display: flex;
  flex-direction: column;
  width: 456px;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  height: 456px;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 50px;
  background-color: #fff;
}

.modal-message > p {
  text-align: center;
  font-size: 18px;
  margin-top: 30px;
  font-weight: 400;
  color: var(--preto-87);
}

.modal-message > img {
  width: 185px;
}

.modal-message > button {
  width: 114px;
  height: 40px;
  max-height: 40px;
  min-height: 40px;
  margin-bottom: 20px;
  background-color: var(--roxo-myne);
  color: #fff;
  font-weight: 500;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  border-radius: 50px;
  font-size: 14px;
  border: none;
  transition: all 0.3s ease;
  outline: initial;
  text-transform: capitalize;
}

.modal-message > button:hover {
  opacity: 0.9;
}

/**/

.myne-modal__modal-sales {
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 430px;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  z-index: 1;
  overflow: hidden;
  transition: all 0.3s ease;
  background-color: #fff;
}

.modal-sales__header {
  display: flex;
  align-items: center;
  padding: 23px;
  justify-content: space-between;
  border-bottom: 1px solid #ebeff2;
}

.modal-sales__header > section {
  display: flex;
  align-items: center;
}

.modal-sales__header > section > p {
  font-size: 13.5px;
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  width: 88%;
  margin-left: 15px;
  align-items: center;
  color: rgba(0, 0, 0, 0.6);
}

.modal-sales__header > svg {
  cursor: pointer;
}

.modal-sales__items {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
}

.modal-sales__items > div {
  padding: 11px 15px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f2f2f2;
}

.modal-sales__items > div > section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.modal-sales__items > div > section > div {
  align-items: center;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.modal-sales__items > div > section > div > p {
  font-size: 15px;
  white-space: nowrap;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.87);
  width: 100%;
  text-overflow: ellipsis;
  max-width: 250px;
}

.modal-sales__items > div > section > div > h5 {
  font-size: 14px;
  display: flex;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  align-items: center;
  color: rgba(0, 0, 0, 0.61);
}

/**/
.modal-sales__items > div > section > section {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  justify-content: flex-end;
}

.modal-sales__items > div > section > section > p {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
  text-align: right;
}

.modal-sales__items > div > section > section > h5 {
  font-size: 14px;
  font-weight: 400;
  text-align: right;
  color: #58cc02;
}
/**/

.modal-sales__items > div > img {
  width: 39px;
  max-width: 39px;
  min-width: 39px;
  height: 39px;
  border-radius: 5px;
  margin-right: 15px;
  object-fit: cover;
}

/**/

.myne-item__venda_clinte-vendas {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  margin-bottom: 16px;
  width: 259px;
  max-width: 259px;
  min-width: 259px;
  height: 147px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 16px;
}

.myne-item__venda_clinte-vendas__infos {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.myne-item__venda_clinte-vendas__infos > div {
  /* margin-right: 13px; */
}

.myne-item__venda_clinte-vendas__infos > div > p {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 8px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  line-height: 12px;
  margin: 0;
  margin-bottom: 6px;

  color: rgba(0, 0, 0, 0.6);
}

.myne-item__venda_clinte-vendas__infos > div > p strong {
  color: rgba(0, 0, 0, 0.87);
  margin-left: 2px;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 8px;
}

.myne-item__venda_clinte-vendas__infos > div > h5 {
  font-family: "Poppins";
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.87);
}

.myne-item__venda_clinte-vendas__infos > section {
  flex-direction: column;
  align-items: flex-end;
}

.myne-item__venda_clinte-vendas__infos > section > div {
  margin-bottom: 6px;
  margin-right: 0;
}

.myne-item__venda_clinte-vendas > section {
  width: 100%;
  height: 1px;
  max-height: 1px;
  margin-top: 8px;
  min-height: 1px;
  background: rgba(0, 0, 0, 0.06);
}

.myne-item__venda_clinte-vendas__qty {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  width: 100%;
}

.myne-item__venda_clinte-vendas__qty > section {
  width: 1px;
  height: 24px;
  background: rgba(0, 0, 0, 0.06);
  margin: 0 17px;
}

.myne-item__venda_clinte-vendas__qty > div {
  display: flex;
  align-items: center;
}

.myne-item__venda_clinte-vendas__qty > div > p {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 12px;
  margin: 0;
  line-height: 18px;
  margin-left: 10px;
  color: rgba(0, 0, 0, 0.87);
}

/**/

.myne-item__venda {
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  height: 87px;
  max-height: 87px;
  min-height: 87px;
  display: flex;
  align-items: center;
  padding: 20px 13px;
  margin-bottom: 15px;
  min-width: 0;
  /* cursor: pointer; */
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
}

.myne-item__venda:hover {
  background-color: rgb(250, 250, 250);
  cursor: pointer;
  /* //background-color: rgba(250, 249, 249, 0.87);  */
  border: 1px solid rgba(0, 0, 0, 0.36);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
}

.myne-item__venda > p {
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
}

.myne-item__venda-user {
  display: flex;
  margin-right: 20px;
  width: auto;
  align-items: center;
}

.myne-item__venda-user > img {
  width: 38px;
  height: 38px;
  border-radius: 100%;
  margin-right: 8px;
  object-fit: cover;
}

.myne-item__venda-user > p {
  color: rgba(0, 0, 0, 0.87);
  font-size: 15px;
  font-weight: 500;
  white-space: nowrap;
  font-weight: 600;
  order: 1;
  flex: 0 1 auto;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100px;
}

.myne-item__venda-status {
  display: flex;
  align-items: center;
}

/*  */

.myne-modal__modal-edit-cadastro-content__info-sales {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.myne-modal__modal-edit-cadastro-content__info-sales > p {
  margin: 0;
  padding: 0;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  opacity: 0.87;
}

.myne-modal__modal-edit-cadastro-content__info-sales
  .myne-item__venda-status
  > div {
  height: 24px;
  padding: 3px 15px;
  font-size: 13.5px !important;
  border-radius: 30px;
}

/*  */

.myne-modal__modal-edit-cadastro-content__info-installments {
  display: flex;
  width: 100%;
  flex-direction: column;
  border-top: 1px dashed rgba(0, 0, 0, 0.06);
  margin-top: 12px;
}

.myne-modal__modal-edit-cadastro-content__info-installments-item {
  display: flex;
  align-items: center;
  padding: 5px 0;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.06);
}

.myne-modal__modal-edit-cadastro-content__info-installments-item > span {
  width: 14px;
  height: 18px;
  font-family: "Poppins";
  margin-right: 12px;
  font-weight: 400;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.36);
}

.myne-modal__modal-edit-cadastro-content__info-installments-item > p {
  width: 64px;
  height: 18px;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 13px;
  margin: 0;
  margin-right: 24px;
  color: rgba(0, 0, 0, 0.87);
}

.myne-modal__modal-edit-cadastro-content__info-installments-item > div {
  display: flex;
  align-items: center;
}
.myne-modal__modal-edit-cadastro-content__info-installments-item > div > p {
  margin: 0;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 13px;
  color: var(--roxo-myne);
  margin-right: 12px;
}
.myne-modal__modal-edit-cadastro-content__info-installments-item > div > svg {
  width: 14px;
  height: 14px;
}

.myne-modal__modal-edit-cadastro-content__info-installments-item > section {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 0;
}
.myne-modal__modal-edit-cadastro-content__info-installments-item
  .css-15va8ns-MuiSwitch-root {
  margin-right: -10px !important;
}
.myne-modal__modal-edit-cadastro-content__info-installments-item > section > p {
  margin: 0;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.87);
}

.myne-modal__modal-edit-cadastro-content__info-cadastros {
  display: flex;
  flex-direction: column;
}

.myne-modal__modal-edit-cadastro-content__info-cadastros > h5 {
  margin: 0;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 24px;
  margin-bottom: 12px;
}

.myne-modal__modal-edit-cadastro-content__info-cadastros-client {
  display: flex;
  align-items: center;
}

.myne-modal__modal-edit-cadastro-content__info-cadastros-client > img {
  width: 36px;
  height: 36px;
  object-fit: cover;
  margin-right: 8px;
}

.myne-modal__modal-edit-cadastro-content__info-cadastros-client > p {
  margin: 0;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.87;
}

.myne-modal__modal-edit-cadastro-content__info-cadastros-itens {
  display: flex;
  align-items: center;
  padding: 12px;
  width: 100%;
  height: 60px;
  border: 1px dashed rgba(0, 0, 0, 0.06);
  border-radius: 8px;
}

.myne-modal__modal-edit-cadastro-content__info-cadastros-itens > img {
  background: #f5f5f5;
  width: 36px;
  height: 36px;
  padding: 7px;
  margin-right: 8px;
  border-radius: 4px;
}

.myne-modal__modal-edit-cadastro-content__info-cadastros-itens > div {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.myne-modal__modal-edit-cadastro-content__info-cadastros-itens > div > p {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  margin: 0;
}

.myne-modal__modal-edit-cadastro-content__info-cadastros-itens > div > section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.myne-modal__modal-edit-cadastro-content__info-cadastros-itens
  > div
  > section
  > h5 {
  margin: 0;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.87);
}
.myne-modal__modal-edit-cadastro-content__info-cadastros-itens
  > div
  > section
  > p {
  margin: 0;
  margin-left: auto;
  font-family: "Poppins";
  font-weight: 700;
  font-size: 12px;
  text-align: right;
  color: rgba(0, 0, 0, 0.87);
}

.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked.Mui-disabled {
  color: #58cc02 !important;
}

/*  */

.myne-item__venda-status > div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  height: 26px;
  border-radius: 4px;
  color: #58cc02;
  margin-right: 8px;
  white-space: nowrap;
  background: #dcffc2;
  font-size: 12px;
  text-transform: uppercase;
}

.myne-item__venda-text {
  margin-left: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.myne-item__venda-text > p {
  text-align: right;
  font-size: 11px;
  color: rgba(0, 0, 0, 0.6);
  opacity: 0.87;
  white-space: nowrap;
  flex: auto;
  overflow: hidden;
  text-overflow: ellipsis;
}

.myne-item__venda-text > h5 {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.87;
  font-size: 15px;
  font-weight: 600;
  margin-top: 2px;
}
/**/

.myne-modal-drop {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 12px;
  right: 20px;
  width: 210px;
  transition: all 0.3s ease;
  background-color: #fff;
  z-index: 999;
  overflow: hidden;
  justify-content: center;
  border-radius: 8px;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.12);
}

.myne-modal-drop > div {
  width: 100%;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.87);
  padding: 0 18px;
  font-weight: 400;
  font-size: 15.5px;
  height: 35px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.myne-modal-drop > div > svg {
  margin-right: 10px;
}

.myne-modal-drop > div > b {
  margin-left: 4px;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 700;
}

.myne-modal-drop > div:hover {
  background-color: #f7f4fa;
}

.myne-modal__modal-add-item {
  border-radius: 8px;
  background-color: #fff;
  position: relative;
  display: flex;
  width: 264px;
  height: 215px;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.12);
  padding: 24px;
  flex-direction: column;
}

.myne-modal__modal-add-item > header {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.myne-modal__modal-add-item > header > svg {
  width: 24px;
  position: relative;
  top: -2px;
  height: 24px;
}

.myne-modal__modal-add-item > header > svg path {
  fill: var(--preto-87) !important;
  fill-opacity: 1 !important;
}

.myne-modal__modal-add-item > header > p {
  font-size: 16px;
  font-weight: 700;
  color: #000000;
  margin: 0;
  margin-left: 12px;
  opacity: 0.87;
}

.myne-modal__modal-add-item-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease;
  padding-top: 0;
  height: 100%;
}

.myne-modal__modal-add-item-content > p {
  font-size: 14px;
  margin-top: -5px;
  color: rgba(0, 0, 0, 0.87);
}

.myne-modal__modal-add-item-content > div {
  display: flex;
  align-items: center;
  margin-top: auto;
  width: 100%;
  justify-content: flex-end;
}

.myne-modal__modal-add-item-content > div > button {
  font-size: 14px;
  text-transform: uppercase;
  margin-left: 36px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  color: rgba(0, 0, 0, 0.87);
  border: none;
  outline: initial;
  padding: 0;
  height: fit-content;
  width: fit-content;
  height: 45px;
  max-height: 45px;
  min-height: 45px;
  background-color: rgba(0, 0, 0, 0);
}

.myne-modal__modal-add-item-content > div > button:hover {
  opacity: 0.8;
}
.myne-modal__modal-add-item-content > div > button:active {
  transform: scale(0.98);
}
.myne-modal__modal-add-item-content > div > button:nth-child(2):active {
  box-shadow: 3px 2px 25px 0px rgba(51, 51, 51, 0.123);
}

.myne-modal__modal-add-item-content > div > button:nth-child(2) {
  padding: 10px 32px !important;
  background: var(--roxo-myne) !important;
  color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12) !important;
  border-radius: 124px !important;
}

.myne-modal__modal-add-item-content > div > button:nth-child(2):disabled {
  background: rgba(0, 0, 0, 0.06) !important;
  color: rgba(0, 0, 0, 0.36) !important;
  box-shadow: none !important;
  cursor: auto !important;
}
.myne-modal__modal-add-item-content .attentionNo > button:nth-child(1):active {
  box-shadow: 3px 2px 25px 0px rgba(51, 51, 51, 0.123);
}

.myne-modal__modal-add-item-content .attentionNo > button:nth-child(1) {
  padding: 10px 32px !important;
  background: var(--roxo-myne) !important;
  color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12) !important;
  border-radius: 124px !important;
}
.myne-modal__modal-add-item-content > div > button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.myne-modal__modal-add-item-content > div > button > svg {
  margin-right: 10px;
}
.myne-modal__modal-add-item-content .attentionNo > button:nth-child(2):active {
  box-shadow: initial;
}

.myne-modal__modal-add-item-content .attentionNo > button:nth-child(2) {
  padding: 10px 32px !important;
  margin: 0;
  background: #fff0 !important;
  color: rgba(0, 0, 0, 0.87) !important;
  box-shadow: initial !important;
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -ms-border-radius: 0 !important;
  -o-border-radius: 0 !important;
}

/*  */

/* .dashboard-content__grid-vendas__vendas-padding {
  padding-bottom: 100px;
} */
