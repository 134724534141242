.myne-opcoes {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  overflow: hidden;
}

.myne-opcoes-container {
  padding: 24px 36px;
  overflow-y: auto;
}

.myne-opcoes-container > p {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: rgba(0, 0, 0, 0.87);
}

/* ITENS */

.myne-opcoes__itens {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 8px;
}

.myne-opcoes__options {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.myne-opcoes__options > p {
  margin-bottom: 16px;
  line-height: normal;
}

/* MODAL */

.myne-modal__modal-moeda {
  filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.12));
}

.myne-modal__modal-moeda
  .myne-modal__modal-new-sale-content__area-modal-search__itens--item-moeda {
  margin-bottom: 24px;
  width: 312px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.myne-modal__modal-moeda
  .myne-modal__modal-new-sale-content__area-modal-search__itens--item-moeda
  > div {
  display: flex;
  cursor: pointer;
  flex-direction: column;
}

.myne-modal__modal-moeda
  .myne-modal__modal-new-sale-content__area-modal-search__itens--item-moeda
  > div
  > p {
  margin: 0;
  font-weight: 600;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.87;
}

.myne-modal__modal-moeda
  .myne-modal__modal-new-sale-content__area-modal-search__itens--item-moeda
  > div
  > span {
  margin-top: 3px;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  opacity: 0.87;
}

/* ITEM */

.myne-opcoes__component-item-config {
  transition: all 0.3s ease !important;
  animation: initopmore 0.3s ease !important;
}
.myne-opcoes__component-item-categories {
  transition: all 0.4s ease !important;
  animation: initopmore 0.4s ease !important;
}

.myne-opcoes__component-item {
  display: flex;
  transition: all 0.5s ease;
  animation: initopmore 0.5s ease;
  flex-direction: column;
  width: 259px;
  height: 90px;
  background: #ffffff;
  cursor: pointer;
  margin-bottom: 24px;
  padding: 16px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 16px;
  margin-right: 24px;
  padding-right: 10px;
  position: relative;
  justify-content: space-between;
}

.myne-opcoes__component-item > section {
  display: flex;
  align-items: center;
}

.myne-opcoes__component-item > svg {
  position: absolute;
  top: 16px;
  right: 16px;
}

.myne-opcoes__component-item > section > p {
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.87;
}

.myne-opcoes__component-item > section > svg {
  margin-right: 8px;
}

.myne-opcoes__component-item > div {
  display: flex;
  align-items: center;
  max-width: 100%;
}

.myne-opcoes__component-item > div > p {
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  min-width: 143px;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 12px;
  color: rgba(0, 0, 0, 0.87);
}

.myne-opcoes__component-item > div > span {
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.6);
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.myne-opcoes__component-item-plus {
  background: #58cc02;
  border: 1px solid rgba(0, 0, 0, 0.06);
  transition: all 0.9s ease;
  animation: initopmore 0.9s ease;
}
.myne-opcoes__component-item-plus > div > p {
  font-weight: 700;
  color: #ffffff;
}

.myne-opcoes__component-item-catalogo {
  background: #aa00ff;
  border: 1px solid rgba(0, 0, 0, 0.06);
  transition: all 1.3s ease;
  animation: initopmore 1.3s ease;
}
.myne-opcoes__component-item-catalogo > div > p {
  font-weight: 700;
  color: #ffffff;
}

.myne-opcoes__component-item:hover {
  border: 1px solid rgba(0, 0, 0, 0.36);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
}
/* INFO */

.myne-info-page {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.myne-info-page > p {
  font-weight: 400;
  font-size: 22px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.87;
}

.myne-info-page > svg {
  margin: 20px 0px;
}

/* Modal */

.myne-modal__modal-info-card {
  display: flex;
  align-items: flex;
  padding: 16px;
  width: 172px;
  height: 248px;
  background: #f5f5f5;
  border-radius: 8px;
}

.myne-modal__modal-info-card > svg {
  min-width: 20px;
  min-height: 20px;
}

.myne-modal__modal-info-card > p {
  width: 100px;
  height: 216px;
  margin: 0;
  margin-left: 16px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.87);
}

.input-form-whatsapp {
  display: flex;
  align-items: center;
  position: relative;
}

.input-form-whatsapp > p {
  border: none;
  z-index: 1;
  top: -10px;
}

.input-form-whatsapp__digit {
  display: flex;
  align-items: center;
  padding-top: 27px;
  padding-bottom: 25px;
  min-width: 92px;
  margin-right: 12px;
  height: 37px;
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}
.input-form-whatsapp__digit:hover {
  background-color: var(--background_hover);
}
.input-form-whatsapp__digit > div {
  display: flex;
  align-items: center;
}
.input-form-whatsapp__digit > div > img {
  width: 20px;
  height: 20px;
}
.input-form-whatsapp__digit > div > p {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  margin: 0;
  margin-left: 8px;
  color: #000000;
}

.input-form-whatsapp__digit > svg {
  margin-left: auto;
}

.myne-modal__modal-edit-cadastro-content__inputs > section,
.myne-modal__modal-accept-terms__info {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  width: 342px;
  height: 64px;
}

.myne-modal__modal-edit-cadastro-content__inputs > section > p,
.myne-modal__modal-accept-terms__info > p {
  margin: 0;
  margin-left: 15px;
  width: 191px;
  height: 48px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
}

.myne-modal__modal-accept-terms__info > p {
  width: 221px;
  margin-left: 24px;
  height: 48px;
}

/*  */
/*  */

.myne-modal__modal-new-sale-content__area-modal-search__itens--item-moeda
  > section {
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.myne-modal__modal-new-sale-content__area-modal-search__itens--item-moeda
  > section
  > img {
  margin-right: 8px;
}

.myne-modal__modal-new-sale-content__area-modal-search__itens--item-moeda
  > section
  > div {
  display: flex;
  flex-direction: column;
}

.myne-modal__modal-new-sale-content__area-modal-search__itens--item-moeda
  > section
  > div
  > p {
  margin: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.87;
}

.myne-modal__modal-new-sale-content__area-modal-search__itens--item-moeda
  > section
  > div
  > span {
  margin-top: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.6);
  opacity: 0.87;
}

/* TERMS MODAL */

.myne-modal__modal-accept-terms {
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: 100%;
}

.myne-modal__modal-accept-terms__info {
  width: 100%;
  margin: 0;
}

.myne-modal__modal-accept-terms_checks {
  margin-top: 32px;
  margin-bottom: 30px;
}

.myne-modal__modal-accept-terms_checks > section {
  display: flex;
  margin-bottom: 20px;
}

.myne-modal__modal-accept-terms_checks > section:nth-child(4) {
  margin-bottom: 0px;
}

.myne-modal__modal-accept-terms_checks > section > svg {
  margin-right: 12px;
  height: 24px;
}

.myne-modal__modal-accept-terms_checks > section > p {
  width: 527px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.87);
  margin: 0;
}

.myne-modal__modal-accept-terms_checks > section > p > a {
  text-decoration: underline;
  color: var(--azul-myne);
}

.myne-modal__modal-accept-terms_btns {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  height: 56px;
}

.myne-modal__modal-accept-terms_btns > div {
  display: flex;
  align-items: center;
  padding: 16px;
  width: 307px;
  height: 56px;
  background: #faefff;
  cursor: pointer;
  border-radius: 8px;
}

.myne-modal__modal-accept-terms_btns > div:hover {
  opacity: 0.8;
}

.myne-modal__modal-accept-terms_btns > div > p {
  margin: 0;
  margin-left: 16px;
  width: 235px;
  height: 18px;
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.87);
}

.myne-modal__modal-accept-terms_btns > button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 32px;
  width: 225px;
  height: 44px;
  background: #aa00ff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  border-radius: 124px;
  border: none;
  outline: initial;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
}

.myne-modal__modal-accept-terms_btns > button:disabled {
  background: rgba(0, 0, 0, 0.06);
  box-shadow: initial;
  color: rgba(0, 0, 0, 0.36);
  cursor: auto;
}

.myne-modal__modal-accept-terms_btns > button:hover {
  opacity: 0.8;
}
.myne-modal__modal-accept-terms_btns > button:active {
  transform: scale(0.98);
}

/* MODAL SUCESS */

.myne-modal__modal-sucess-publicate {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  justify-content: center;
}

.myne-modal__modal-sucess-publicate > div button {
  padding: 10px 32px !important;
  height: initial !important;
  width: initial !important;
  margin-top: 24px !important;
  font-size: 14px !important;
}

.myne-modal__modal-sucess-publicate > img {
  width: 100px;
  height: 100px;
  border-radius: 134px;
  object-fit: cover;
  transform: scale(1.77);
  z-index: -1;
}

.myne-modal__modal-sucess-publicate > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
}

.myne-modal__modal-sucess-publicate > div > p {
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.76);
  margin: 0;
}

.myne-modal__modal-sucess-publicate > div > span {
  margin: 0;
  margin-top: 12px;
  line-height: 20px;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
}

.myne-modal__modal-sucess-publicate > div > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  width: 328px;
  height: 56px;
  background: #faefff;
  border-radius: 8px;
  margin-top: 24px;
}

.myne-modal__modal-sucess-publicate > div > div > p {
  width: 256px;
  max-width: 256px;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 21px;
  font-weight: 700;
  font-size: 14px;
  margin: 0;
  color: rgba(0, 0, 0, 0.87);
}

.myne-btn-border {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 32px;
  width: 337px;
  height: 44px;
  margin-top: 46px;
  background: rgba(255, 255, 255, 0.87);
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 124px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.87);
  cursor: pointer;
}

.myne-btn-border > svg {
  margin-right: 13px;
}

.myne-btn-border:hover {
  background-color: var(--background_hover);
}

.myne-btn-border:active {
  transform: scale(0.98);
}

.myne-modal__modal-sucess-publicate-sucess-stop {
  transform: scale(1.4) !important;
  transition: all 0.3s ease;
  -webkit-transform: scale(1.4) !important;
  -moz-transform: scale(1.4) !important;
  -ms-transform: scale(1.4) !important;
  -o-transform: scale(1.4) !important;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

/* COPY tooltip */

.myne-modal__modal-sucess-publicate_copy {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  position: relative;
}
.myne-modal__modal-sucess-publicate_copy > div svg {
  cursor: pointer;
}

.myne-modal__modal-sucess-publicate_copy-tooltip {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  padding: 16px;
  min-width: 124px;
  height: 53px;
  background: rgba(0, 0, 0, 0.87);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.161);
  border-radius: 16px;
  bottom: 35px;
  font-weight: 300;
  font-size: 13.5px;
  text-align: center;
  line-height: 21px;
  color: rgba(255, 255, 255, 0.87);
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
}

.myne-modal__modal-sucess-publicate_copy-tooltip svg {
  bottom: -6px;
  position: absolute;
}

/*  */
