MODAL SALE .myne-modal__modal-new-sale {
  width: 884px;
  height: 479px;
}
/* header */
.myne-modal__modal-new-sale .myne-modal__modal-edit-header {
  justify-content: space-around;
}
.myne-modal__modal-new-sale .myne-modal__modal-edit-header__left {
  margin-right: auto;
}

.myne-modal__modal-new-sale__header-filter-area {
  position: absolute;
  margin: 0 auto;
  width: 225px;
  height: 45px;
}

/* footer */
.myne-modal__modal-new-sale_bottom {
  width: 100%;
  display: flex;
  align-items: center;
  border-top: 1px solid #ebeff2;
  height: 69px;
  padding: 12px;
  padding-left: 24px;
}

.myne-modal__modal-new-sale_bottom--left {
  width: 346px;
  margin-bottom: 4px;
}

.myne-modal__modal-new-sale_bottom--center {
  margin-left: 24px;
}

.myne-area__select-date {
  display: flex;
  align-items: center;
  padding: 10px 32px;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 124px;
  position: relative;
}

.myne-area__select-date > div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
}
.myne-area__select-date > div .ant-picker {
  width: 100%;
  padding: 0;
  height: 100%;
}
.myne-area__select-date > div .ant-picker-suffix {
  display: none;
}
.myne-area__select-date > div .ant-picker-clear {
  display: none;
}
.myne-area__select-date > div .ant-picker-input > input {
  cursor: pointer !important;
}

.myne-area__select-date:hover {
  background: var(--background_hover);
}

.myne-area__select-date > p {
  margin: 0;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  margin-left: 10px;
}

.myne-modal__modal-new-sale_bottom--right {
  margin-left: auto;
}
.myne-modal__modal-new-sale_bottom--right-row {
  display: flex;
  align-items: center;
  margin-top: auto;
}
.myne-modal__modal-new-sale_bottom--right-row button {
  margin-left: 12px;
}

.myne-modal__modal-new-sale_bottom--right > .text {
  background: rgba(0, 0, 0, 0) !important;
}

.myne-modal__modal-new-sale_bottom--right > .text-active {
  color: rgba(0, 0, 0, 0.87) !important;
  cursor: pointer;
}

.myne-modal__modal-new-sale_bottom--right > button {
  padding: 10px 32px;
  height: 44px;
  background: rgba(0, 0, 0, 0.06);
  border-radius: 124px;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  color: rgba(0, 0, 0, 0.36);
}

.myne-modal__modal-new-sale_bottom--right > button:hover {
  opacity: 0.8;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}
.myne-modal__modal-new-sale_bottom--right > button:active {
  transform: scale(0.97);
}

.myne-modal__modal-new-sale_bottom--right .active {
  background: var(--roxo-myne);
  color: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  cursor: pointer;
}

/* Content modal */

.myne-modal__modal-new-sale-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.myne-modal__modal-new-sale-content > header {
  display: flex;
  align-items: center;
  z-index: 1;
  width: 100%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
}

.myne-modal__modal-new-sale-content > header > div {
  flex: 1;
  display: flex;
  padding: 12px 24px;
  align-items: center;
  justify-content: center;
}

.myne-modal__modal-new-sale-content > header > div > button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 32px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 124px;
  background-color: #fff;
  cursor: pointer;
  font-family: "Poppins";
  font-weight: 500;
  text-transform: uppercase;
  font-size: 14px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
}
.myne-modal__modal-new-sale-content > header > div > button > svg {
  margin-right: 10px;
}
.myne-modal__modal-new-sale-content > header > div > button:hover {
  background-color: var(--background_hover);
}

.myne-modal__modal-new-sale-content > header > div:nth-child(2) {
  border-right: 1px solid #ebeff2;
  border-left: 1px solid #ebeff2;
}

.myne-modal__modal-new-sale-content > div {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}

.myne-modal__modal-new-sale-content__area {
  flex: 1;
  width: 100%;
  display: flex;
  height: 100%;
  width: 296px;
  flex-direction: column;
  padding-bottom: 68px;
}

.myne-modal__modal-new-sale-content__area
  > .myne-modal__modal-new-sale-content__area-overflow {
  display: flex;
  height: 271px;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
}

.myne-modal__modal-new-sale-content__area:nth-child(2)
  > .myne-modal__modal-new-sale-content__area-overflow {
  border-right: 1px solid #ebeff2;
  border-left: 1px solid #ebeff2;
}

.myne-modal__modal-new-sale-content__area-user {
  margin: 24px;
  display: flex;
  align-items: center;
  padding: 0 12px;
  width: 246px;
  height: 52px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 100px;
}

.myne-modal__modal-new-sale-content__area-user > img {
  width: 36px;
  height: 36px;
  margin: 0 !important;
  margin-right: 8px !important;
}

.myne-modal__modal-new-sale-content__area-user > p {
  margin: 0;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 13.5px;
  margin-right: 10px;
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.87;
}

.myne-modal__modal-new-sale-content__area-user > svg {
  margin-left: auto;
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.myne-modal__modal-new-sale-content__area-user > svg:hover {
  opacity: 0.7;
  transform: scale(1.1);
}

.myne-modal__modal-new-sale-content__area-product-service {
  margin: 8px 12px;
  display: flex;
  flex-direction: column;
  padding: 12px;
  width: 270px;
  max-width: 270px;
  min-width: 270px;
  position: relative;
  height: 94px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 16px;
}

.myne-modal__modal-new-sale-content__area-product-service > div {
  display: flex;
  align-items: center;
}
.myne-modal__modal-new-sale-content__area-product-service > div > img {
  width: 36px;
  height: 36px;
  margin-right: 12px !important;
  border-radius: 5px;
}
.myne-modal__modal-new-sale-content__area-product-service > div > p {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  margin: 0;
  color: rgba(0, 0, 0, 0.87);
  max-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.myne-modal__modal-new-sale-content__area-product-service > footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  margin-top: 8px;
  padding-top: 8px;
}
.myne-modal__modal-new-sale-content__area-product-service > footer > p {
  margin: 0;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.87);
}
.myne-modal__modal-new-sale-content__area-product-service > footer > b {
  margin: 0;
  font-family: "Poppins";
  font-weight: 700;
  font-size: 12px;
  text-align: right;
  color: rgba(0, 0, 0, 0.87);
}

.myne-modal__modal-new-sale-content__area-product-service > section {
  position: absolute;
  right: 12px;
  top: 12px;
  width: fit-content;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.myne-modal__modal-new-sale-content__area-product-service > section > svg {
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.myne-modal__modal-new-sale-content__area-product-service
  > section
  > svg:hover {
  opacity: 0.7;
  transform: scale(1.1);
}

.myne-modal__modal-new-sale-content__area-product-service:nth-child(1) {
  margin-top: 24px;
}

.myne-modal__modal-new-sale-content__area-product-service:nth-last-child(1) {
  margin-bottom: 24px;
}

/*  */

.myne-modal__modal-new-sale-content__area-modal-search {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 364px;
  height: 355px;
  filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.12));
  background: #fff;
  z-index: 999;
  border-radius: 8px;
  padding-bottom: 0;
  /* overflow-y: auto; */
  overflow: hidden;
}

.myne-modal__modal-new-sale-content__area-modal-search__input {
  display: flex;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  padding-bottom: 11px;
  padding-top: 16px;
}

.myne-modal__modal-new-sale-content__area-modal-search-area__seach {
  display: flex;
  flex-direction: column;
  padding: 10px 26px;
}

.myne-modal__modal-new-sale-content__area-modal-search__input > input {
  width: 100%;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  background: none;
  outline: initial;
  border: none;
  margin-right: 8px;
  color: rgba(0, 0, 0, 0.6);
}

.myne-modal__modal-new-sale-content__area-modal-search__input > svg {
  width: 20px;
  height: 20px;
  max-width: 20px;
  max-height: 20px;
  min-width: 20px;
  min-height: 20px;
}

.myne-modal__modal-new-sale-content__area-modal-search__itens {
  overflow-y: auto;
  padding: 12px 0px 24px 24px;
  padding-bottom: 0;
}

.myne-modal__modal-new-sale-content__area-modal-search__itens-user {
  display: flex;
  align-items: center;
  margin: 20px 0;
  margin-top: 0;
  padding: 4px 0;
  border-radius: 8px;
  cursor: pointer;
  width: 312px;
  transition: all 0.3s ease;
}

.myne-modal__modal-new-sale-content__area-modal-search__itens-user:hover {
  /* background: linear-gradient(
    90deg,
    #fff,
    var(--background_hover),
    var(--background_hover)
  ); */
  transform: scale(0.985);
  opacity: 0.8;
}

.myne-modal__modal-new-sale-content__area-modal-search__itens-user:active {
  transform: scale(1.01);
}

.myne-modal__modal-new-sale-content__area-modal-search__itens-user > p {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 13.5px;
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.87;
  margin: 0;
}

.myne-modal__modal-new-sale-content__area-modal-search__itens-user-photo {
  margin-right: 8px !important;
}

.myne-modal__modal-new-sale-content__area-modal-search__itens-user-photo-img {
  width: 36px;
  max-width: 36px;
  max-height: 36px;
  min-width: 36px;
  min-height: 36px;
  background: #f5f5f5;
}

.myne-modal__modal-new-sale-content__area-modal-search__itens-user-photo-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 36px;
  cursor: pointer;
  max-width: 36px;
  max-height: 36px;
  min-width: 36px;
  min-height: 36px;
  border: none;
  height: 36px;
  background: var(--roxo-myne);
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 100px;
}

.myne-modal__modal-new-sale-content__area-modal-search__itens-user
  .myne-item__cadastro {
  background-color: none;
  box-shadow: none;
  border: none;
  border-radius: 0;
  height: 0;
  max-height: 0;
  min-height: 0;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
  margin-bottom: 0px;
  margin: 24px 0;
  width: 100%;
  justify-content: space-between;
  overflow-y: initial;
  overflow-x: initial;
}

.myne-modal__modal-new-sale-content__area-modal-search__itens-user
  .myne-item__cadastro-left
  > section {
  padding: 5px 0;
}

.myne-modal__modal-new-sale-content__area-modal-search__itens-user
  .myne-item__cadastro-left
  > section
  > p {
  font-size: 13.5px;
}
.myne-modal__modal-new-sale-content__area-modal-search__itens-user
  .myne-item__cadastro-right
  > section
  > p {
  font-size: 10px;
}
.myne-modal__modal-new-sale-content__area-modal-search__itens-user
  .myne-item__cadastro-right
  > section
  > h5 {
  font-size: 13.5px;
}
.myne-modal__modal-new-sale-content__area-modal-search__itens-user
  .myne-item__cadastro-left {
  margin-right: auto !important;
  flex: 1 !important;
}
.myne-modal__modal-new-sale-content__area-modal-search__itens-user
  .myne-item__cadastro-left
  > section
  > h5 {
  margin-top: 3px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  max-width: 134px;
}

.myne-modal__modal-new-sale-content__area-modal-search__itens-user
  .myne-item__cadastro-right {
  margin-left: auto !important;
}

.myne-modal__modal-new-sale-content__area-modal-search__itens-user
  .myne-item__cadastro-right
  > section {
  margin-left: 12px;
}

/* Fechamento */

.myne-modal__modal-new-sale-fechamento {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 24px;
}

.myne-modal__modal-new-btn {
  text-transform: uppercase;
}

.myne-modal__modal-new-sale-fechamento:nth-child(1),
.myne-modal__modal-new-btn:nth-child(1) {
  animation: animateflex 0.4s;
}
.myne-modal__modal-new-sale-fechamento:nth-child(2),
.myne-modal__modal-new-btn:nth-child(2) {
  animation: animateflex 0.6s;
}
.myne-modal__modal-new-sale-fechamento:nth-child(3),
.myne-modal__modal-new-btn:nth-child(3) {
  animation: animateflex 0.8s;
}
@keyframes animateflex {
  from {
    transform: translateY(-40px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

.myne-modal__modal-new-sale-fechamento > p {
  font-family: "Poppins";
  font-weight: 700;
  font-size: 13.5px;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 24px;
}

.myne-modal__modal-new-sale-fechamento-parcelamento {
  border-left: 1px solid #ebeff2;
  border-right: 1px solid #ebeff2;
  overflow-y: auto;
}
.css-1a5icme.Mui-checked,
.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
  color: var(--roxo-myne) !important;
}
.css-1a5icme,
.css-vqmohf-MuiButtonBase-root-MuiRadio-root {
  padding: 0 !important;
}

.myne-modal__modal-new-sale-fechamento-parcelas {
  overflow-y: auto;
}

/*  */
.myne-modal__modal-new-sale-fechamento-parcelamento-item {
  border-top: 1px dashed rgba(0, 0, 0, 0.06);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50.73px;
  max-height: 50.73px;
  min-height: 50.73px;
  cursor: pointer;
}
.myne-modal__modal-new-sale-fechamento-parcelamento-item:nth-last-child(1) {
  border-bottom: 1px dashed rgba(0, 0, 0, 0.06);
}
.myne-modal__modal-new-sale-fechamento-parcelamento-item:hover {
  opacity: 0.9;
}
.myne-modal__modal-new-sale-fechamento-parcelamento-item > p:nth-child(1) {
  width: 24px;
  margin-right: 4px;
}
.myne-modal__modal-new-sale-fechamento-parcelamento-item > p:nth-child(2) {
  margin-right: auto;
}
.myne-modal__modal-new-sale-fechamento-parcelamento-item > div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  /* width: 70px; */
  height: 30px;
  background: #dcffc2;
  border-radius: 4px;
  /* text */
  font-family: "Poppins";
  font-weight: 400;
  font-size: 13.5px;
  color: #58cc02;
  /*  */
  margin-left: auto;
  margin-right: 29px;
}
.myne-modal__modal-new-sale-fechamento-parcelamento-item > p {
  font-family: "Poppins";
  margin: 0;
  font-weight: 400;
  font-size: 13.5px;
  color: rgba(0, 0, 0, 0.87);
}

/*  */
.myne-modal__modal-new-sale-fechamento-parcelas
  > .myne-modal__modal-edit-cadastro-content__installments
  > div
  > p {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.87);
  margin-right: 35px;
  text-decoration: underline;
  cursor: pointer;
  margin-left: 0;
  width: 60px;
  height: 18px;
  white-space: nowrap;
}
.myne-modal__modal-new-sale-fechamento-parcelas
  > .myne-modal__modal-edit-cadastro-content__installments
  > div
  > section
  > p,
.myne-modal__modal-new-sale-fechamento-parcelas
  > .myne-modal__modal-edit-cadastro-content__installments
  > div
  > label
  > p {
  margin: 0;
  padding: 0;
  margin-left: 8px;
  margin-right: 8px;
  font-family: "Poppins";
  font-weight: 400;
  cursor: pointer;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.87);
}
.myne-modal__modal-new-sale-fechamento-parcelas
  > .myne-modal__modal-edit-cadastro-content__installments
  > div {
  /* height: 42.73px; */
  height: 59.72px;
  padding: initial;
}
.myne-modal__modal-new-sale-fechamento-parcelas
  > .myne-modal__modal-edit-cadastro-content__installments
  > div
  > div
  > p {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 12px;
  color: var(--roxo-myne);
  cursor: pointer;
  text-decoration: underline;
  height: 18px;
}

.myne-modal__modal-new-sale-fechamento-parcelas
  > .myne-modal__modal-edit-cadastro-content__installments
  > div
  > div {
  margin-right: 5px;
}
.myne-modal__modal-new-sale-fechamento-parcelas
  > .myne-modal__modal-edit-cadastro-content__installments
  > div
  > label {
  flex-direction: column;
}
.myne-modal__modal-new-sale-fechamento-parcelas
  > .myne-modal__modal-edit-cadastro-content__installments
  > div
  > label
  > p {
  margin-bottom: 4px;
  color: var(--preto-87);
}

.myne-modal__modal-new-sale-fechamento-parcelas
  > .myne-modal__modal-edit-cadastro-content__installments
  > div
  > div
  > svg,
.myne-modal__modal-new-sale-fechamento-parcelas
  > .myne-modal__modal-edit-cadastro-content__installments
  > div
  > p
  > svg {
  width: 13px;
  height: 13px;
  margin-left: 5px;
  position: relative;
  top: 0.8px;
}
.myne-modal__modal-new-sale-fechamento-parcelas
  > .myne-modal__modal-edit-cadastro-content__installments
  > div
  > p
  > svg {
  top: 3px;
}
.myne-modal__modal-edit-cadastro-content__installments > div > div {
  position: relative;
  cursor: pointer;
}
.myne-modal__modal-edit-cadastro-content__installments > div > div .ant-picker {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
/*  */

.myne-modal__modal-new-sale-fechamento-valorvenda > section {
  display: flex;
  flex-direction: column;
}

.myne-modal__modal-new-sale-fechamento-valorvenda > section > div {
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.myne-modal__modal-new-sale-fechamento-valorvenda > section > div > p {
  margin: 0;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 13.5px;
  color: rgba(0, 0, 0, 0.87);
}
.myne-modal__modal-new-sale-fechamento-valorvenda > section > div > h4 {
  margin: 0;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
}
.myne-modal__modal-new-sale-fechamento-valorvenda > section > div > h5 {
  margin: 0;
  font-family: "Poppins";
  font-weight: 700;
  font-size: 16px;
  text-align: right;
  color: rgba(0, 0, 0, 0.87);
}

.myne-modal__modal-new-sale-fechamento-valorvenda > section > div > button {
  margin-right: 12px;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  height: 24px;
  background: rgba(255, 255, 255, 0.87);
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 54px;
  font-family: "Poppins";
  text-transform: uppercase;
  font-weight: 400;
  cursor: pointer;
  font-size: 11px;
  color: rgba(0, 0, 0, 0.87);
}

.myne-modal__modal-new-sale-fechamento-valorvenda
  > section
  > div
  > button:hover {
  background-color: var(--background_hover);
}

.myne-modal__modal-new-sale-fechamento-valorvenda > section > section {
  width: 100%;
  margin-bottom: 24px;
  border-top: 1px dashed rgba(0, 0, 0, 0.12);
}

/*  */

.myne-modal__modal-add-product-sale-item {
  width: 390px;
  height: 383px;
}

.myne-modal__modal-add-product-sale-item__container {
  padding: 24px;
  display: flex;
  height: 100%;
  flex-direction: column;
}

.myne-modal__modal-add-product-sale-item__container-area-product {
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: 342px;
  height: 174px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 16px;
}

.myne-modal__modal-add-product-sale-item__container-area-product > article {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.myne-modal__modal-add-product-sale-item__container-area-product > article > p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  margin: 0;
  line-height: 15px;
  color: rgba(0, 0, 0, 0.87);
}

.myne-modal__modal-add-product-sale-item__container-area-product
  > article
  > p
  > span {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 11px;
  color: rgba(0, 0, 0, 0.6);
  margin-right: 8px;
}

.myne-modal__modal-add-product-sale-item__container-area-product
  > article
  > p
  > b {
  font-weight: 700;
}

.myne-modal__modal-add-product-sale-item__container-area-product > div {
  display: flex;
  align-items: center;
  padding: 16px 0;
  margin: 16px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.myne-modal__modal-add-product-sale-item__container-area-product > div > img {
  width: 40px;
  height: 40px;
  border-radius: 5px;
}

.myne-modal__modal-add-product-sale-item__container-area-product > div > p {
  margin: 0;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  max-width: 142px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgba(0, 0, 0, 0.87);
}

.myne-modal__modal-add-product-sale-item__container-area-product
  > div
  > section {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.myne-modal__modal-add-product-sale-item__container-area-product
  > div
  > section
  > input {
  border: 1px solid rgba(0, 0, 0, 0.06);
  width: 47px;
  height: 47px;
  border-radius: 100%;
  text-align: center;
  outline: initial;
  margin: 0 12px;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
}

.myne-modal__modal-add-product-sale-item__container-area-product
  > div
  > section
  > svg {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.myne-modal__modal-add-product-sale-item__container-area-product
  > div
  > section
  > svg:nth-child(1):active {
  transform: scale(0.88);
}

.myne-modal__modal-add-product-sale-item__container-area-product
  > div
  > section
  > svg:nth-child(3):active {
  transform: scale(1.1);
}

.myne-modal__modal-add-product-sale-item__container-area-product > section {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
}

.myne-modal__modal-add-product-sale-item__container-area-product > section > b {
  margin-left: 4px;
}

.myne-modal__modal-add-product-sale-item__container > button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 32px;
  width: 142px;
  cursor: pointer;
  height: 44px;
  background: var(--roxo-myne);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  border-radius: 124px;
  margin-top: auto;
  margin-left: auto;
  border: none;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
}

.myne-modal__modal-add-product-sale-item__container > button:active {
  transform: scale(0.95);
}
.myne-modal__modal-add-product-sale-item__container > button:hover {
  opacity: 0.8;
}

/* EDIT INSTALLMENT */

.myne-modal__modal-edit-value-installment {
  width: 390px;
  height: 521px;
}

.myne-modal__modal-edit-value-installment
  .myne-modal__modal-add-product-sale-item__container {
  padding: 0;
}

.myne-modal__modal-section-edit-value-installment {
  width: 100%;
  padding: 24px;
  max-height: 355px;
  overflow-y: auto;
}

.myne-modal__modal-section-edit-value-installment
  .myne-modal__modal-edit-cadastro-content__inputs {
  width: 100%;
  height: auto;
  padding: 0;
}

.myne-modal__modal-edit-value-installment-area-btns {
  min-height: 92px;
  padding: 24px;
  border-top: 1px solid var(--border-myne);
}

.myne-modal__modal-section-edit-value-installment__input {
  display: flex;
  align-items: center;
  position: relative;
}

.myne-modal__modal-section-edit-value-installment__input > .myne-icon-button {
  position: absolute;
  right: 16px;
  cursor: pointer;
}

.myne-modal__modal-section-edit-value-installment__input
  > .myne-icon-button
  path {
  fill-opacity: 0.87;
}

.myne-modal__modal-section-edit-value-installment__value-total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 34px;
}

.myne-modal__modal-section-edit-value-installment__value-total > section {
  display: flex;
  flex-direction: column;
  height: 39px;
}

.myne-modal__modal-section-edit-value-installment__value-total > section > p {
  font-size: 14px;
  font-weight: 500;
  line-height: 12px;
  /* margin-bottom: 12px; */
}

.myne-modal__modal-section-edit-value-installment__value-total > section > h5 {
  font-weight: 700;
  font-size: 24px;
  margin-top: auto;
}

.myne-modal__modal-section-edit-value-installment__value-total-associar {
  align-items: end;
}

.myne-modal__modal-section-edit-value-installment__value-total-associar * {
  color: var(--red-myne) !important;
}

.myne-modal__modal-section-edit-value-installment__value-total-associar > h5 {
  font-size: 16px !important;
  line-height: 12px;
}

/* MODAL welcome PLUS */

.myne-modal__modal-welcome-plus {
  display: flex;
  width: 611px;
  height: 479px;
  background: #ffffff;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
}

.myne-modal__modal-welcome-plus > svg {
  width: 24px;
  cursor: pointer;
  height: 24px;
  top: 24px;
  right: 24px;
  position: absolute;
}

.myne-modal__modal-welcome-plus > svg:hover {
  opacity: 0.8;
}

.myne-modal__modal-welcome-plus__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  animation: initcontent 0.7s ease;
}

@keyframes initcontent {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.myne-modal__modal-welcome-plus__content > img {
  margin-top: 39px;
  margin-bottom: -20px;
}

.myne-modal__modal-welcome-plus__content > section {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.myne-modal__modal-welcome-plus__content > section > p {
  margin: 0;
  margin-top: 71px;
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
  height: 36px;
}

.myne-modal__modal-welcome-plus__content > section > h5 {
  margin: 0;
  margin-top: 10px;
  width: 287px;
  height: 42px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
}

.myne-modal__modal-welcome-plus__content > section > svg {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: -1;
}

.myne-modal__modal-welcome-plus__content
  .myne-modal__modal-plus__content-vantagens {
  margin-top: 32px;
}

.myne-modal__modal-welcome-plus__content
  .myne-modal__modal-plus__content-vantagens
  * {
  color: rgba(0, 0, 0, 0.87);
  fill: rgba(0, 0, 0, 0.87);
}

/* MODAL PLUS */

.myne-modal__modal-plus {
  display: flex;
  width: 611px;
  height: 479px;
  background: #fff;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
}
.border-plus {
  --border-size: 2px;
  --border-angle: 0turn;

  background-image: conic-gradient(
      from var(--border-angle),
      var(--azul-myne),
      #85d1f5 50%,
      var(--azul-myne)
    ),
    conic-gradient(
      from var(--border-angle),
      transparent 20%,
      var(--azul-myne),
      #fff
    );
  background-size: calc(100% - (var(--border-size) * 2))
      calc(100% - (var(--border-size) * 2)),
    cover;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-animation: bg-spin 4s linear infinite;
  animation: bg-spin 4s linear infinite;
}

@-webkit-keyframes bg-spin {
  to {
    --border-angle: 1turn;
  }
}
@keyframes bg-spin {
  to {
    --border-angle: 1turn;
  }
}

@property --border-angle {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0turn;
}

.myne-modal__modal-plus > svg {
  width: 24px;
  cursor: pointer;
  height: 24px;
  top: 24px;
  right: 24px;
  position: absolute;
}

.myne-modal__modal-plus > svg:hover {
  opacity: 0.8;
}

.myne-modal__modal-plus__content {
  padding: 0 24px;
  padding-top: 72px;
  width: 100%;
  display: flex;
  flex-direction: column;
  animation: initop 0.8s ease;
}

.myne-modal--animation {
  transition: all 0.4s ease;
  animation: initopacity 0.8s ease;
}
.myne-modal--animation-scale {
  transition: all 0.2s ease;
  animation: initscale 0.4s ease;
}
.myne-modal--animation-top {
  transition: all 0.3s ease;
  animation: initop 0.45s ease;
}
.myne-modal--animation-left {
  transition: all 0.4s ease;
  animation: inileft 0.5s ease;
}

@keyframes initscale {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes initopacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes inileft {
  0% {
    opacity: 0;
    transform: translateX(8%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes initop {
  0% {
    opacity: 0;
    transform: translateY(-15%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
@keyframes initopmore {
  0% {
    opacity: 0;
    transform: translateY(-25%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.myne-modal__modal-plus__content-info {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
}

.myne-modal__modal-plus__content-info > article {
  z-index: -1;
  position: absolute;
  top: 24px;
}

.myne-modal__modal-plus__content-info > svg {
  margin-right: 24px;
  width: 172.4px;
}

.myne-modal__modal-plus__content-info > section {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 274px;
}

.myne-modal__modal-plus__content-info > section > h5 {
  font-weight: 700;
  font-size: 24px;
  color: #ffffff;
  margin: 0;
  text-align: center;
  font-weight: 700;
  line-height: 32px;
  width: 274px;
}

.myne-modal__modal-plus__content-info > section > div {
  display: flex;
  align-items: center;
  gap: 8px;
}

.myne-modal__modal-plus__content-info > section > div > p {
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  margin: 0;
}

.myne-modal__modal-plus__content-info > section > div > p > b {
  font-weight: 700;
}

.myne-modal__modal-plus__content-info > section > p {
  margin: 0;
  width: 287px;
  margin-top: 5px;
  font-weight: 400;
  font-size: 11px;
  color: #ffffff;
}

.myne-modal__modal-plus__content-info > section > p > strong {
  font-weight: 600;
  color: #ffffff;
}

.myne-modal__modal-plus__content-vantagens {
  display: flex;
  align-items: center;
  gap: 24px;
  justify-content: center;
  width: 514px;
  margin: 24px auto;
  margin-bottom: 32px;
}

.myne-modal__modal-plus__content-vantagens > section {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.myne-modal__modal-plus__content-vantagens > section:nth-child(1) {
  width: 146px;
}

.myne-modal__modal-plus__content-vantagens > section:nth-child(2) {
  width: 212px;
}

.myne-modal__modal-plus__content-vantagens > section > article {
  display: flex;
  align-items: flex-start;
}
.myne-modal__modal-plus__content-vantagens > section > article > svg {
  margin-right: 12px;
  min-width: 18px;
  min-height: 18px;
}
.myne-modal__modal-plus__content-vantagens > section > article > p {
  color: var(--preto-87);
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  margin: 0;
}

.myne-modal__modal-plus__content-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.myne-modal__modal-plus__content-buttons-plan {
  display: flex;
  padding: 20px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* gap: 16px; */
  height: 140px;
  flex: 1 0 0;
  border-radius: 16px;
  border: 1px solid var(--preto-6);
  background: #fff;
}

.myne-modal__modal-plus__content-buttons-plan > button {
  border: 1px solid var(--roxo-myne);
  box-shadow: none;
  background-color: #fff;
  color: var(--roxo-myne);
  font-weight: 500;
}

.myne-modal__modal-plus__content-buttons-plan > button:hover {
  background-color: var(--roxo-myne-2);
}

.myne-modal__modal-plus__content-buttons-plan > div {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.myne-modal__modal-plus__content-buttons-plan > div > h5 {
  margin: 0;
  color: var(--preto-87);
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
}

.myne-modal__modal-plus__content-buttons-plan > div > h3 {
  color: var(--preto-87);
  text-align: right;
  font-size: 20px;
  font-weight: 700;
  line-height: 18px;
  white-space: nowrap;
  display: flex;
  margin: 0;
}

.myne-modal__modal-plus__content-buttons-plan > div > h3 > p {
  font-size: 12px;
  margin: 0;
  font-weight: 400;
  position: relative;
  top: 2px;
}

.myne-modal__modal-plus__content-buttons-plan > p {
  margin: 0;
  color: var(--preto-87);
  font-size: 10px;
  align-self: stretch;
  font-weight: 500;
  line-height: 18px;
  margin-top: 4px;
  margin-bottom: 16px;
}

.myne-modal__modal-plus__content-buttons-plan-active {
  border: 1px solid var(--roxo-myne);
  background: var(--roxo-myne-2);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.16);
}

.myne-modal__modal-plus__content-buttons-plan-active > button {
  background: var(--roxo-myne);
  color: #fff;
}

.myne-modal__modal-plus__content-buttons-plan-active > button:hover {
  background: var(--roxo-myne);
  opacity: 0.8;
}

.myne-modal__modal-plus__content-buttons-plan-active:hover {
  transform: scale(1.05);
}

/* MODAL IMPORT PRODUCTS */

.myne-modal__modal-edit-cadastro-import-products {
  width: 611px;
  height: 589px;
}

.myne-modal__modal-edit-cadastro-import-products-content {
  padding: 24px;
  display: flex;
  flex-direction: column;
}

.myne-modal__modal-edit-cadastro-import-products-content
  > .myne-modal__modal-new-sale_bottom--right
  button {
  position: absolute;
  right: 24px;
  bottom: 24px;
}

.myne-modal-import-products-content--header {
  display: flex;
  margin-bottom: 12px;
  flex-direction: column;
}

.myne-modal-import-products-content--header_stages {
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  height: 55px;
  width: 100%;
  justify-content: center;
}

.myne-modal-import-products-content--header_stages > svg {
  margin: 0 12px;
}

.myne-modal-import-products-content--header_stages > div {
  width: 257.5px;
  height: 55px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.myne-modal-import-products-content--header_stages > div > article {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
  width: 26px;
  height: 26px;
  background: #fff;
  border: 1px solid #aa00ff;
  border-radius: 100px;
}

.myne-modal-import-products-content--header_stages > div > article > p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
}

.myne-modal-import-products-content--header_stages > div > p {
  margin: 0;
  margin-top: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
}

.myne-modal-import-products-content--header_info {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  width: 563px;
  height: 69px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 8px;
}

.myne-modal-import-products-content--header_info > p {
  font-weight: 400;
  font-size: 12px;
  margin: 0;
  width: 355px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.87);
}

.myne-modal-import-products-content--header_info > svg {
  width: 32px;
  height: 32px;
  max-width: 32px;
  max-height: 32px;
  min-width: 32px;
  min-height: 32px;
  margin-right: 8px;
}

.myne-modal-import-products-content--header_info > a {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  width: 120px;
  height: 37px;
  background: rgba(255, 255, 255, 0.87);
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 124px;
  margin-left: auto;
  outline: initial;
  cursor: pointer;
}

.myne-modal-import-products-content--header_info > a:hover {
  background-color: var(--background_hover);
  border: 1px solid rgba(0, 0, 0, 0.08);
}

.myne-modal-import-products-content--header_info > a:active {
  transform: scale(0.97);
}

.myne-modal-import-products-content--header_info > a p {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
  margin: 0;
  margin-left: 8px;
}

.myne-modal-import-products-content--header_info--download {
}

.myne-modal-import-products-content__area-import-file {
  display: flex;
  flex-direction: column;
}

.myne-modal-import-products-content__area-import-file > section {
  width: 100%;
  height: 192px;
  background: #faefff;
  border: 1px dashed #aa00ff;
  border-radius: 8px;
  margin-bottom: 12px;
  position: relative;
}

.myne-modal-import-products-content__area-import-file > section > div {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.myne-modal-import-products-content__area-import-file > section > div > p {
  margin: 0;
  margin-top: 8px;
  width: 220px;
  height: 42px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
}

.myne-modal-import-products-content__area-import-file > section > div > p > b {
  color: var(--roxo-myne);
  font-weight: 700;
  margin-left: 4px;
  text-decoration: underline;
}

.myne-modal-import-products-content__area-import-file > section > input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.myne-modal-import-products-content__area-import-file > section.drop-file,
.myne-modal-import-products-content__area-import-file > section:active {
  opacity: 0.5;
  transform: scale(0.99);
}

.myne-modal-import-products-content__area-import-file__form-info {
  display: flex;
  align-items: center;
  padding: 10px 16px;
  width: 100%;
  height: 36px;
  background: #f5f5f5;
  border-radius: 8px;
}

.myne-modal-import-products-content__area-import-file__form-info > p {
  height: 15px;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: rgba(0, 0, 0, 0.87);
  margin: 0;
  margin-left: 8px;
}

/*  */

.myne-modal-import-products-content__area-import-file___sucess {
  background: #dcffc2 !important;
  border: 1px dashed #58cc02 !important;
}

.myne-modal-import-products-content__area-import-file___sucess > div > p {
  font-weight: 700 !important;
  width: 238px !important;
  height: 21px !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: rgba(0, 0, 0, 0.87);
}

.myne-modal-import-products-content__area-import-file___sucess > div > section {
  display: flex;
  align-items: center;
  margin-top: 8px;
}
.myne-modal-import-products-content__area-import-file___sucess
  > div
  > section
  > p {
  margin: 0;
  margin-right: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-decoration-line: underline;
  color: rgba(0, 0, 0, 0.87);
}

.myne-modal-import-products-content__area-import-file___sucess
  > div
  > section
  > .icon-area
  svg {
  cursor: pointer;
}

/*  */

.myne-modal-import-products-content__area-import-file___erro {
  background: #ffdbe0 !important;
  border: 1px dashed #fe2543 !important;
}

.myne-modal-import-products-content__area-import-file___erro > div > p {
  width: 229px !important;
  height: 63px !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  text-align: center !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.myne-modal-import-products-content__area-import-file___erro > div > p > b {
  color: rgba(0, 0, 0, 0.87) !important;
  font-weight: 700 !important;
}

.myne-modal-import-products-content__area-import-file___erro > div > section {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.myne-modal-import-products-content__area-import-file___erro
  > div
  > section
  > p {
  margin: 0;
  margin-right: 8px;
  width: 200px;
  height: 36px;
  font-weight: 400;
  text-align: center;
  font-size: 12px;
  line-height: 18px;
  text-decoration-line: underline;
  color: rgba(0, 0, 0, 0.87);
}

/*  */

.myne-modal-import-products-content__area-import-file__form-info-erro,
.myne-modal-import-products-content__area-import-file__form-info-alert {
  display: flex;
  align-items: center;
  padding: 16px;
  height: 56px;
  width: 100%;
  background: #ffdbe0;
  border-radius: 8px;
}

.myne-modal-import-products-content__area-import-file__form-info-alert {
  background: #fff1cb !important;
}

.myne-modal-import-products-content__area-import-file__form-info-erro > p,
.myne-modal-import-products-content__area-import-file__form-info-alert > p {
  width: 499px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.87);
  margin: 0;
  margin-left: 8px;
}

/*  */

.myne-modal-import-products-area-background-overflow {
  position: absolute;
  width: 100%;
  height: 114px;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    360deg,
    #ffffff 34.74%,
    rgba(255, 255, 255, 0) 122.84%
  );
  border-radius: 0 0 8px 8px;
}

.myne-modal-import-products-content__area-import-products-table {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  width: 100%;
  height: 300px;
  overflow-y: auto;
  padding-bottom: 50px;
}

/*  */

.myne-modal-import-products-content__area-import-products-table__titles {
  width: 100%;
  display: flex;
  padding: 12px 0px;
  border-width: 2px 0px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.06);
}

.myne-modal-import-products-content__area-import-products-table__titles
  > section {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.87);
  margin-right: 6px;
}

.myne-modal-import-products-read__area-big {
  width: 200px;
}

.myne-modal-import-products-read__area-small {
  width: 84.75px;
}

.myne-modal-import-products-read__area-small-2 {
  width: 120px;
  max-height: 50px;
  white-space: nowrap;
  align-items: center !important;
  overflow-x: hidden;
  position: relative;
}

.myne-modal-import-products-read__area-small-2 > p {
  position: absolute;
  right: 1px;
  margin: auto;
  background-color: #cbedfd;
  cursor: pointer;
  border-radius: 3px;
  color: var(--roxo-myne);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  font-size: 12px;
  width: 22px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.myne-modal-import-products-read__area-small-2::-webkit-scrollbar {
  width: 0px !important;
  height: 0px !important;
}

.myne-modal-import-products-read__area-small-3 {
  white-space: pre-wrap;
  width: 120px;
  word-break: break-all;
}
.myne-modal-import-products-read__area-small-4 {
  white-space: pre-wrap;
  width: 120px;
}

.myne-modal-import-products-content__area-import-products-table__values {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 20px;
}

.myne-modal-import-products-content__area-import-products-table__values > div {
  display: flex;
  align-items: center;
  width: 100%;
  height: 48px;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.06);
}

.myne-modal-import-products-content__area-import-products-table__values
  > div
  > section {
  display: flex;
  align-items: center;
  margin-right: 6px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.87);
}

.myne-modal-import-products-content__area-import-products-table__values
  > div
  > section
  > svg {
  margin-right: 6px;
}

/* MODAL CONFIGURE ACCOUNT */

.myne-modal__modal-configure-account {
  padding: 24px;
  display: flex;
  width: 100%;
  flex-direction: column;
}

.myne-text-modal {
  color: var(--preto-87);
  font-size: 12px;
  margin: 0;
  font-weight: 700;
  line-height: 24px;
}

.myne-text-info {
  color: var(--preto-87);
  font-size: 20px;
  margin: 0;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: auto !important;
}

.myne-modal__modal-configure-account__plus-true {
  background: var(--roxo-myne-2) !important;
}

.myne-modal__modal-configure-account__plus {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  margin: 12px 0;
  background: var(--background-gray);
}

.myne-modal__modal-configure-account__plus > svg {
  margin-right: 16px;
}

.myne-modal__modal-configure-account__plus > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.myne-modal__modal-configure-account__plus > div > p {
  color: var(--preto-87);
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}
.myne-modal__modal-configure-account__plus > div > h5 {
  margin: 0;
  color: var(--preto-87);
  font-size: 14px;
  font-weight: 700;
}

.myne-modal__modal-configure-account-btns {
  margin-top: 12px;
  display: flex;
  align-items: center;
}

.myne-modal__modal-configure-account-btns button:disabled {
  cursor: not-allowed !important;
  opacity: 0.8 !important;
}

.myne-modal__modal-configure-account-btns button {
  margin-right: 12px;
  border-radius: 124px;
  border: 1px solid var(--preto-6);
  background: var(--branco-87);
  height: 44px;
  padding: 10px 32px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
}

.myne-modal__modal-configure-account-btns button > svg {
  margin-right: 10px;
}
.myne-modal__modal-configure-account-btns > span {
  border-radius: 4px;
  background: var(--preto-6);
  display: flex;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  color: var(--preto-87);
  font-size: 12px;
  font-weight: 400;
}

.myne-modal__modal-configure-account-btns button:hover {
  background: var(--background-gray);
}

.myne-modal__modal-configure-account-btns button > p {
  color: var(--preto-87);
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  text-transform: uppercase;
}

.myne-modal__modal-configure-account-btns button:active {
  transform: scale(0.98, 0.98);
}

.myne-modal__modal-configure-account__plus--area {
  display: flex;
  width: 100%;
  padding: 12px 8px;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px;
  /* border: 1px solid var(--preto-6); */
  background-color: var(--azul-myne);
}

.myne-modal__modal-configure-account__plus--area > section {
  display: flex;
  align-items: center;
  padding: 6px;
  border-radius: 32px 8px 8px 32px;
  background: #fff;
}

.myne-modal__modal-configure-account__plus--area > section > button {
  min-width: 171px;
}

.myne-modal__modal-configure-account__plus--area > section > p {
  margin: 0;
  color: var(--preto-87);
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  margin-left: 12px;
}

.myne-modal__modal-configure-account__plus--area
  > .myne-modal__modal-plus__content-vantagens {
  margin: 0 auto;
  margin-top: 10px;
  width: 100%;
}

.myne-modal__modal-configure-account__plus--area
  > .myne-modal__modal-plus__content-vantagens
  > section
  > article
  > p {
  font-size: 11px;
  line-height: 17px;
  margin: 0;
}
.myne-modal__modal-configure-account__plus--area
  > .myne-modal__modal-plus__content-vantagens
  > section
  > article
  > svg {
  min-width: 16px;
  min-height: 16px;
  max-width: 16px;
  max-height: 16px;
}

.myne-modal__modal-edit-cadastro-content-relogin {
  display: flex;
  justify-content: center;
  align-items: center;
}

.myne-modal__modal-edit-cadastro-content-relogin .login__content {
  margin: 0 auto;
  height: 87% !important;
}

.myne-modal__modal-edit-cadastro-content-relogin
  .login__content-recuperar__senha {
  margin: 0 auto;
  height: 87% !important;
}

.myne-modal__modal-remove-account {
  width: 300px !important;
  height: 265px !important;
}

.myne-modal__modal-remove-account-confirm {
  width: 300px !important;
  height: 202px !important;
}
