.header {
  background-color: #fff;
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0 30px;
  /* justify-content: space-between; */
}

.header__left {
  display: flex;
  align-items: center;
}

.header__left > p {
  font-weight: 500;
  font-size: 15.5px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.87);
}

/**/

.header__center {
  margin-right: 0px;
  /* margin-left: 130px; */
  display: flex;
  z-index: 999;
  position: relative;
  align-items: center;
}

.header__center > div {
  position: absolute;
  top: 55px;
  width: 330px;
  left: 0px;
  height: auto;
  box-shadow: var(--shadow-web);
  background-color: #fff;
  border-radius: 8px;
  z-index: 999999999;
  padding: 12px;

  opacity: 0;
  visibility: hidden;
  transform: scale(0);
}

.header__center > div > p {
  margin: 8px 6px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.87);
}

.header .ant-picker {
  padding: 11px 14px;
  background-color: rgb(248, 250, 252);
  margin: 0px;
  color: rgb(37, 37, 37);
  border: 0;
  border-radius: 12px;
}

.header .ant-picker input {
  color: rgb(37, 37, 37);
}

.header .nice-dates-day:before {
  background-color: var(--roxo-myne);
  z-index: 1;
}

.header .nice-dates-day:after {
  border: 2px solid var(--roxo-myne);
  transform: scale(0.95);
  transition-duration: 150ms;
  transition-property: transform, opacity;
  z-index: 2;
}

.header .nice-dates-week-header_day {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  margin-left: 0.5px;
  height: 18px;
  text-overflow: ellipsis;
}

.header__center-btn_filter {
  width: 72px;
  height: 43px;
  margin-top: 15px;
  border-radius: 5px 5px 0 0;
  color: rgba(0, 0, 0, 0.6);
  outline: initial;
  cursor: pointer;
  text-transform: uppercase;
  background: rgba(255, 255, 255, 0);
  border: none;
  font-size: 15px;
  font-weight: 500;
  transition: all 0.3s ease;
  border-radius: 5px 5px 0 0;
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  -webkit-border-radius: 5px 5px 0 0;
  -moz-border-radius: 5px 5px 0 0;
  -ms-border-radius: 5px 5px 0 0;
  -o-border-radius: 5px 5px 0 0;
}

.header__center-btn_filter:hover {
  background: var(--roxo-myne-2);
}

.header__center-btn {
  width: 82px;
  max-width: 82px;
  min-width: 82px;
  margin-left: 16px;
  height: 35px;
  width: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  font-size: 14.7px;
  border-radius: 35px;
  outline: initial;
  cursor: pointer;
  background: rgba(255, 255, 255, 0);
  border: none;
  border: 1px solid rgba(0, 0, 0, 0.06);
}

.header__center-btn:hover {
  border: 1px solid rgba(0, 0, 0, 0.171);
  background: rgb(252, 251, 253);
}

/**/

.header__right {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.header__right .MuiIconButton-root {
  margin-right: -12px;
  width: 42px;
  height: 42px;
}

/**/

@media (max-width: 952px) {
  .header {
    padding: 0 10px;
  }
  .header__center {
    margin-right: 0px;
    margin-left: 20px;
  }
  .header__left > p {
    font-size: 13px;
  }
  .header__center-btn {
    width: 67px;
    max-width: 67px;
    min-width: 67px;
    height: 32px;
    width: 67px;
    font-size: 13.5px;
    border-radius: 25px;
  }
  .myne-btn__seje-plus {
    height: 40px;
    width: 140px;
  }
  .myne-btn__seje-plus > p {
    font-size: 13px;
    margin-left: 30px;
  }
}
