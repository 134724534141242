.myne-modal__modal-edit-cadastro-content__customize-receipt {
  display: flex;
  padding: 24px;
  width: 100%;
  gap: 24px;
}

.myne-modal__modal-content__customize-receipt--upload {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
}

.myne-modal__modal-content__customize-receipt--upload__label {
  width: 216px;
  height: 122px;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  border-radius: 8px;
  background: var(--preto-6);
  cursor: pointer;
  overflow: hidden;
  border: 1px dashed var(--preto-6);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.myne-modal__modal-content__customize-receipt--upload__label:hover,
.myne-modal__modal-edit-cadastro-content__right-subimit__photo:hover {
  background-color: #fff;
  box-shadow: var(--shadow-button);
}

.myne-modal__modal-content__customize-receipt--upload__label:active,
.myne-modal__modal-edit-cadastro-content__right-subimit__photo:active {
  transform: scale(0.97);
  -webkit-transform: scale(0.97);
  -moz-transform: scale(0.97);
  -ms-transform: scale(0.97);
  -o-transform: scale(0.97);
}

.myne-modal__modal-content__customize-receipt--upload__label > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.myne-modal__modal-content__customize-receipt--upload__label > div {
  display: flex;
  width: 100%;
  height: 100%;
  gap: 10px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.myne-modal__modal-content__customize-receipt--upload > button {
  height: 37px;
  width: 134px;
  padding: 0 24px;
  margin: 0;
}

.myne-modal__modal-content__customize-receipt--upload__label > div > p {
  text-align: center;
}

.myne-modal__modal-edit-cadastro-content__customize-receipt
  .myne-modal__modal-edit-cadastro-content__inputs {
  padding: 0;
  width: 100%;
  flex: 1;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -800px 0;
  }
  100% {
    background-position: 800px 0;
  }
}

.myne-loading-upload-image {
  position: absolute;
  width: 100%;
  bottom: 0;
  animation: placeHolderShimmer linear infinite forwards 2s;
  background-color: #85d1f5;
  background: linear-gradient(
    to right,
    #85d2f566 8%,
    #85d2f5a8 18%,
    #85d2f566 33%
  );
  background-size: 800px 104px;
  z-index: 2;
  -webkit-animation: placeHolderShimmer linear infinite forwards 2s;
}

/* RECEIPT */

.myne-recibo-custom-card--companie {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  gap: 16px;
  background-color: var(--background-gray-2);
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.myne-recibo-custom-card--companie > img {
  width: 156px !important;
  height: 88px !important;
}

.myne-recibo-custom-card--companie__image {
  border-radius: 8px;
  width: 126px;
  height: 72px;
  border: 1px solid var(--preto-6);
  object-fit: cover;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.myne-recibo-custom-card--companie > section {
  display: flex;
  flex-direction: column;
}

.myne-recibo-custom-card--companie > section > h2 {
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
}

.myne-recibo-custom-card--companie > section > p {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.myne-recibo-custom-card--companie > section > span {
  font-size: 10px;
  font-weight: 400;
  margin-top: 5px;
  line-height: 18px;
}

.myne-recibo--footer-companie {
  height: 104px !important;
  max-height: 104px !important;
  min-height: 104px !important;
  padding: 16px 56px !important;
  align-items: end !important;
}

.myne-recibo--footer-companie--info {
  display: flex;
  align-items: start;
  width: 70%;
  gap: 16px;
}

.myne-recibo--footer-companie--info > p {
  font-size: 14px;
  font-weight: 400;
}

.myne-recibo--button {
  width: 190px !important;
  padding: 0 20px !important;
  margin: 0 4px !important;
  font-weight: 500 !important;
  background-color: var(--roxo-myne) !important;
  text-transform: none;
  color: #fff !important;
}

.myne-recibo--button > svg {
  width: 16px;
  height: 16px;
  margin-right: 10px !important;
}

.myne-recibo--button > svg path {
  fill: #fff;
  fill-opacity: 1;
}

.myne-recibo--buttons {
  display: flex;
  right: 55px;
  top: 10px;
  position: fixed;
}
