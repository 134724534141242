/* SALE DETAILS */

.myne-modal__modal-edit-cadastro-content__info-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 12px;
}

.myne-modal__modal-edit-cadastro-content__info-top
  > .myne-item__venda-status
  > div {
  padding: 3px 15px !important;
  font-family: "Poppins" !important;
  font-weight: 400 !important;
  font-size: 13.5px !important;
  border-radius: 20px !important;
}

.myne-modal__modal-edit-cadastro-content__info-top > p {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  opacity: 0.87;
}

.myne-modal__modal-edit-cadastro-content__installments {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: 1px dashed rgba(0, 0, 0, 0.06);
}

.myne-modal__modal-edit-cadastro-content__installments > div {
  display: flex;
  width: 100%;
  align-items: center;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.06);
  padding: 12px 0;
}

.myne-modal__modal-edit-cadastro-content__installments > div > span {
  font-family: "Poppins";
  font-weight: 400;
  width: 14px;
  height: 18px;
  font-size: 13.5px;
  color: rgba(0, 0, 0, 0.36);
}

.myne-modal__modal-edit-cadastro-content__installments > div > p {
  margin: 0;
  padding: 0;
  min-width: 64px;
  height: 18px;
  font-family: "Poppins";
  font-weight: 400;
  margin-right: 24px;
  font-size: 13.5px;
  color: rgba(0, 0, 0, 0.87);
  text-decoration: underline;
  cursor: pointer;
}

.myne-modal__modal-edit-cadastro-content__installments > div > div {
  display: flex;
  align-items: center;
}

.myne-modal__modal-edit-cadastro-content__installments > div > div > p {
  margin: 0;
  padding: 0;
  height: 18px;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 13.5px;
  color: var(--roxo-myne);
}

.myne-modal__modal-edit-cadastro-content__installments > div > p > svg {
  margin-left: 8px !important;
}

.myne-modal__modal-edit-cadastro-content__installments > div > p > svg,
.myne-modal__modal-edit-cadastro-content__installments > div > div > svg {
  width: 17px;
  height: 17px;
  margin-left: 12px;
  position: relative;
  top: 1px;
  color: var(--roxo-myne);
}

.myne-modal__modal-edit-cadastro-content__installments > div > section,
.myne-modal__modal-edit-cadastro-content__installments > div > label {
  display: flex;
  margin-left: auto;
  align-items: center;
}

.myne-modal__modal-edit-cadastro-content__installments > div > section > p,
.myne-modal__modal-edit-cadastro-content__installments > div > label > p {
  margin: 0;
  padding: 0;
  margin-right: 12px;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 13.5px;
  color: rgba(0, 0, 0, 0.87);
}

.myne-modal__modal-edit-cadastro-content__itens {
  display: flex;
  flex-direction: column;
}

.myne-modal__modal-edit-cadastro-content__itens > p {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 13.5px;
  margin-top: 24px;
  margin-bottom: 12px;
  color: rgba(0, 0, 0, 0.6);
}

.myne-modal__modal-edit-cadastro-content__itens-client {
  display: flex;
  align-items: center;
}

.myne-modal__modal-edit-cadastro-content__itens-client > p {
  margin-left: 8px;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 13.5px;
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.87;
}

.myne-modal__modal-edit-cadastro-content__itens-array {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.myne-modal__modal-edit-cadastro-content__itens-card {
  width: 100%;
  display: flex;
  align-items: center;
  width: 342px;
  height: 60px;
  border: 1px dashed rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  margin-bottom: 8px;
  padding: 12px;
}

.myne-modal__modal-edit-cadastro-content__itens-card:nth-last-child(1) {
  margin-bottom: 0px;
}

.myne-modal__modal-edit-cadastro-content__itens-card > div {
  width: 36px;
  height: 36px;
  max-width: 36px;
  max-height: 36px;
  min-width: 36px;
  min-height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f5f5;
  border-radius: 5px;
  margin-right: 8px;
}

.myne-modal__modal-edit-cadastro-content__itens-card > div > img {
  width: 100%;
  border-radius: 5px;
  object-fit: cover;
  height: 100%;
  object-fit: cover;
}

.myne-modal__modal-edit-cadastro-content__itens-card > section {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.myne-modal__modal-edit-cadastro-content__itens-card > section > p {
  margin: 0;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 13.5px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.6);
}

.myne-modal__modal-edit-cadastro-content__itens-card > section > section {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 13.5px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  align-items: center;
}

.myne-modal__modal-edit-cadastro-content__itens-card > section > section > b {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 13.5px;
  text-align: right;
  margin-left: auto;
  color: rgba(0, 0, 0, 0.87);
}

/*  */

.myne-modal__modal-edit-header__left > p strong {
  color: rgba(0, 0, 0, 0.87);
  font-weight: 700;
  text-transform: lowercase;
}

.myne-modal__modal-edit-cadastro-content__right-subimit__infos {
  display: flex;
  flex-direction: column;
}

.myne-modal__modal-edit-cadastro-content__right-subimit__infos-values {
  display: flex;
  flex-direction: column;
}

.myne-modal__modal-edit-cadastro-content__right-subimit__infos-values > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
}

.myne-modal__modal-edit-cadastro-content__right-subimit__infos-values > div p {
  margin: 0;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.87);
}
.myne-modal__modal-edit-cadastro-content__right-subimit__infos-values > div h5 {
  margin: 0;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: right;
  color: #fe2543;
}

.myne-modal__modal-edit-cadastro-content__right-subimit__infos-values
  > div:nth-child(2) {
  border-bottom: 1px dashed rgba(0, 0, 0, 0.06);
}

.myne-modal__modal-edit-cadastro-content__right-subimit__infos-values
  > section {
  display: flex;
  padding: 8px 0;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.myne-modal__modal-edit-cadastro-content__right-subimit__infos-values
  > section
  p {
  margin: 0;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.87);
}

.myne-modal__modal-edit-cadastro-content__right-subimit__infos-values
  > section
  h5 {
  margin: 0;
  font-family: "Poppins";
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  color: rgba(0, 0, 0, 0.87);
}

.myne-modal__modal-edit-cadastro-content__right-subimit__infos-description {
  display: flex;
  margin-top: 24px;
}

.myne-modal__modal-edit-cadastro-content__right-subimit__infos-description
  > svg {
  width: 18px;
  height: 18px;
  max-width: 18px;
  max-height: 18px;
  min-width: 18px;
  min-height: 18px;
}

.myne-modal__modal-edit-cadastro-content__right-subimit__infos-description > p {
  font-family: "Poppins";
  font-weight: 400;
  margin-left: 12px;
  font-size: 12px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.8);
}

/* RECIBO */

.myne-recibo {
  width: 100%;
  height: 100%;
  background: #ffffff;
  position: fixed;
  z-index: 99999999;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
}

.myne-recibo > svg {
  position: fixed;
  top: 15px;
  right: 17px;
  width: 28px;
  height: 28px;
  cursor: pointer;
}
.myne-recibo > svg:hover {
  transform: scale(1.1);
  opacity: 0.7;
}
.myne-recibo-content__btns {
  position: fixed;
  right: 15px;
  top: 15px;
}

.myne-recibo-content__btns > div {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  font-family: "Poppins";
  font-weight: 400;
  cursor: pointer;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
}
.myne-recibo-content__btns > div:hover {
  opacity: 0.7;
}
.myne-recibo-content__btns > div:active {
  transform: scale(0.98);
}
.myne-recibo-content__btns > div > b {
  margin-left: 4px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.87);
}
.myne-recibo-content__btns > div > svg {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}

.myne-recibo-content {
  width: 595px;
  min-height: 842px;
  margin: auto;
  display: flex;
  flex-direction: column;
  background: #ffffff;
}

.myne-recibo-content > footer {
  /* position: absolute;
  bottom: 0; */
  width: 595px;
  height: 57px;
  max-height: 57px;
  min-height: 57px;
  background: rgba(0, 0, 0, 0.06);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 57px;
  margin-top: auto;
}

.myne-recibo-content > footer > p {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 12px;
  margin: 0;
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.87;
}

.myne-recibo-content > footer > p > b {
  font-weight: 700;
}

.myne-recibo-content > footer > img {
  width: 106px;
  height: 21px;
}

/* Main recibo */

.myne-recibo-content__main {
  padding: 57px;
  display: flex;
  flex-direction: column;
}

.myne-recibo-content__main > header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.myne-recibo-content__main > header > div {
  display: flex;
  align-items: center;
}

.myne-recibo-content__main > header > div > svg {
  width: 24px;
  height: 24px;
  margin-right: 12px;
}

.myne-recibo-content__main > header > div > p {
  margin: 0;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
}

.myne-recibo-content__main > header > div > p > b {
  color: rgba(0, 0, 0, 0.87);
  font-weight: 700;
}

.myne-recibo-content__main > header > p {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.87);
}

.myne-recibo-content__main--info {
  display: flex;
  width: 100%;
  margin: 32px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
  gap: 20px;
  width: 481px;
  height: 82px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 16px;
}

.myne-recibo-content__main--info > section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  width: 134px;
  height: 42px;
}

.myne-recibo-content__main--info > section > p {
  width: 134px;
  height: 16px;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.6);
  margin: 0;
}

.myne-recibo-content__main--info > section > h5 {
  width: 142px;
  height: 18px;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.87;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 142px;
}

.myne-recibo-content__main--itens {
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.myne-recibo-content__main--table {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 16px;
}

.myne-recibo-content__main--table-titles {
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 8px;
  width: 481px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.36);
}

.myne-recibo-content__main--table-titles > p {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 12px;
  margin: 0;
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.87;
}

.myne-recibo-content__main--table-titles > h5 {
  margin: 0;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.87;
}

.myne-recibo-content__main--table-itens {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.myne-recibo-content__main--table-itens-item {
  display: flex;
  align-items: center;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.myne-recibo-content__main--table-itens-item > p {
  height: 21px;
  /* display: flex; */
  /* align-items: center; */
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  margin: 0;
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.87;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.myne-recibo-content__main--table-itens-item > h5 {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 12px;
  margin: 0;
  color: rgba(0, 0, 0, 0.87);
  padding-top: 2px;
  opacity: 0.87;
}

.myne-recibo-content__main--table-itens-item > b {
  font-family: "Poppins";
  font-weight: 700;
  font-size: 14px;
  margin: 0;
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.87;
}

.myne-recibo-content__main--info_parcelamento {
  display: flex;
  flex-direction: column;
}

.myne-recibo-content__main--info_parcelamento > p {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  opacity: 0.87;
}
