/* Switch ON Catalog */

.myne-modal__modal-edit-cadastro-content__show-catalogo {
  width: 100%;
  display: flex;
  justify-content: center;
  /* margin-top: 4px; */
  margin-top: 32px;
  align-items: center;
}
.myne-modal__modal-edit-cadastro-content__show-catalogo-big {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 4px;
  /* margin-top: 32px; */
  align-items: center;
}

.myne-modal__modal-edit-cadastro-content__show-catalogo > label {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--roxo-myne-2);
  padding: 8px 16px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.myne-modal__modal-edit-cadastro-content__show-catalogo > label:active {
  transform: scale(0.98);
  -webkit-transform: scale(0.98);
  -moz-transform: scale(0.98);
  -ms-transform: scale(0.98);
  -o-transform: scale(0.98);
}

.myne-modal__modal-edit-cadastro-content__show-catalogo > label > p {
  font-size: 12px;
}
.myne-modal__modal-edit-cadastro-content__show-catalogo-big > label {
  padding: 16px;
}
.myne-modal__modal-edit-cadastro-content__show-catalogo-big > label > p {
  font-size: 14px;
}

.myne-modal__modal-edit-cadastro-content__show-catalogo > label .switch {
  margin-left: 16px;
  min-width: 24px;
}

/* SelectChangeLang */

.myne-modal__custom-select {
  position: relative;
}

.myne-modal__custom-select-active {
  width: 72px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  padding: 12px 8px;
  border-radius: 124px;
  margin-left: 24px;
  border: 1px solid var(--border-myne);
  -webkit-border-radius: 124px;
  -moz-border-radius: 124px;
  -ms-border-radius: 124px;
  -o-border-radius: 124px;
}

.myne-modal__custom-select-active:hover {
  border: 1px solid rgba(0, 0, 0, 0.171);
}

.myne-modal__custom-select-active > svg {
  width: 20px;
  height: 20px;
}

.myne-modal__custom-select-active > svg:nth-child(2) {
  padding: 5px;
}

.myne-modal__custom-select-modal {
  position: absolute;
  top: 1px;
  margin-top: 36px;
  padding: 10px 16px;
  gap: 10px;
  box-shadow: var(--shadow-web);
  width: 167px;
  height: 112px;
  border-radius: 8px;
  right: 0;
  background-color: #fff;
  z-index: 999;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.myne-modal__custom-select-modal > div {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #fff;
  cursor: pointer;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.myne-modal__custom-select-modal > div:hover {
  transform: scale(0.97);
  -webkit-transform: scale(0.97);
  -moz-transform: scale(0.97);
  -ms-transform: scale(0.97);
  -o-transform: scale(0.97);
}

.myne-modal__custom-select-modal > div > p {
  font-size: 14px;
}
