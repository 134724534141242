.myne-sidebar {
  width: 255px;
  max-width: 255px;
  min-width: 255px;
  height: 100%;
  z-index: 1;
  background: #ffffff;
  display: flex;
  overflow: hidden;
  justify-content: space-around;
  flex-direction: column;
  transition: all 0.3s ease;
  box-shadow: 0 0 30px 10px rgba(133, 131, 131, 0.103);
}

/* .sidebar__overflow {
  display: flex;
  overflow-y: scroll;
  flex-direction: column;
  width: 100%;
}

.sidebar__overflow::-webkit-scrollbar {
  width: 9px;
}
.sidebar__overflow::-webkit-scrollbar-track {
  border-radius: 0px;
  background-color: rgb(255, 255, 255);
}
.sidebar__overflow::-webkit-scrollbar-thumb {
  background: var(--border-myne);
} */

.sidebar__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 62px;
  height: 240px;
  max-height: 240px;
  min-height: 240px;
  position: relative;
}

.sidebar__header-logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar__header-logo > img {
  cursor: pointer;
}

.myne-sidebar-icon-menu-open {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.myne-sidebar-icon-menu-open > img {
  width: 20px;
  height: 20px;
  position: fixed;
  top: 19.5px;
  transition: all 0.3s ease;
}

.myne-sidebar-icon-menu-open > img:hover {
  opacity: 0.7;
}

/**/
.sidebar__buttons {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.sidebar__bottom {
  background-color: rgb(255, 255, 255);
  height: 85px;
  max-height: 85px;
  min-height: 85px;
  display: flex;
  align-items: center;
  padding: 0 30px;
  justify-content: space-between;
}

.sidebar__bottom > p {
  color: #000000;
  font-size: 10px;
}

/* SUGESTOES */

.sidebar__buttons {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: scroll;
  height: 100%;

}
.sidebar__buttons::-webkit-scrollbar {
  width: 6px;
  border-radius: 5px;
}
.sidebar__buttons::-webkit-scrollbar-track {
  border-radius: 0px;
  background-color: rgb(255, 255, 255);
}
.sidebar__buttons::-webkit-scrollbar-thumb {
  background: linear-gradient(var(--roxo-myne), var(--roxo-myne));
}

/* .myne-sidebar {
  width: 280px;
  height: 100%;
  background: #ffffff;
  display: flex;
  overflow-y: scroll;
  flex-direction: column;
} 
 .myne-sidebar::-webkit-scrollbar {
  width: 9px;
}
.myne-sidebar::-webkit-scrollbar-track {
  border-radius: 0px;
  background-color: rgb(255, 255, 255);
}
.myne-sidebar::-webkit-scrollbar-thumb {
  background: var(--border-myne);
} */
